[data-page="imprint"] {
  //background: linear-gradient(transparent 0, transparent 500px, #000 1000px), url('/img/partners_background_small.jpg') 10% top / 300% auto no-repeat;
  /*background: #fff url('/img/LLOweb_muc_1920x1113_cut.jpg') left 50px no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  //background-size: cover;
  @media @mq2col {
      background: #fff url('/img/LLOweb_muc_1920x1113_cut.jpg') left 50px no-repeat;
      -webkit-background-size: cover;
      background-size: cover;
      background-attachment: fixed;
  }*/
}
