html {
    font-size: 100%;
    position: relative;
    min-height: 100%;
    /*overflow-y: scroll;*/
    &.skrollr {
      height: 100%;
    }
}

body {
label {
padding-left: 0 !important;
font-size: 14px;
    font-weight: 300;
        padding: 0;
            text-transform: uppercase;
}
img {
  max-width: 100%;
}
  .btn:not(.btn-ci-dynamic) {
    background: #222;
    color: #ccc;
    border: 0;
    min-width: 136px;
    width: auto;
    height: auto;
    &.btn-outline {
      border: 1px solid #fff;
    }
    &:hover {
      background: #191919;
    }
    &.btn-primary {
      background: #fff;
      color: #2b2b2b;
      &:hover {
        background: #ddd;
      }
    }
  }
  ul {
    list-style-type: square;
  }
  #bgVideo {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
  }
  .html-mobile-background {
      position: fixed;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 125%; /* To compensate for mobile browser address bar space */
      background-repeat: no-repeat;
      background-size: cover;
  }
  .partner-details-box {
    margin-bottom: 30px;
    .dark {
      background: rgba(0,0,0,0.75);
    }
    &.full-height {
      height: 300px;
      @media(min-width: 992px) {
        position: absolute;
        left: 15px;
        right: 15px;
        top: 0;
        bottom: 0;
        height: auto;
      }
    }
    .partner-details-box-complete {
      border-radius: 6px;
      border-top-left-radius: 27px;
      border-bottom-right-radius: 27px;
      -webkit-border-radius: 6px;
      -webkit-border-top-left-radius: 27px;
      -webkit-border-bottom-right-radius: 27px;
      padding: 30px;
      position: relative;
      overflow: hidden;
    }
    .partner-details-box-top {
      border-radius: 0;
      border-top-left-radius: 27px;
      border-top-right-radius: 6px;
      padding: 30px;
      position: relative;
    }
    .partner-details-box-bottom {
      border-radius: 0;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 27px;
      padding: 30px;
      position: relative;
    }
    h1 {
      font-size: 60px;
      font-weight: 200;
      margin-top: 0;
    }
    h2 {
      font-size: 22px;
      text-transform: uppercase;
      font-weight: 400;
      letter-spacing: 4.5px;
      margin-top: 0;
      margin-bottom: 21px;
    }
    &.partner-details-teaser {
      h1 {
        margin-top: 10.5px;
        margin-bottom: 0;
        padding-right: 100px;
      }
      h2 {
        margin-top: 0;
        margin-bottom: 0;
        padding-right: 100px;
      }
      h4 {
        font-size: 24px;
        margin-bottom: 21px;
      }
      ul {
        padding-left: 20px;
      }
      .partner-details-icon {
        position: absolute;
        right: 30px;
        top: 30px;
        font-size: 90px;
      }
      .partner-details-box-middle img {
        display: block;
      }
    }
    &.partner-details-contact {
      table * {
        font-size: 14px;
        vertical-align: top;
      }
      .row > div:first-child {
        margin-bottom: 30px;
      }
    }
  }
  background: #fff;
    padding: 0; /* bottom = footer height */
    @media @mq1col {
        padding: 0;
    }
    @media (max-width: 991px) {
      background-position-y: 0 !important;
    }
    font-size: 18px;
    font-weight: 100;
    /*line-height: 1.5;*/
    color: @text-color-bright;

    .top-space {
      padding-top: 200px;
    }
    &.is--nav {
      @media(min-width: 992px) {
        padding-top: 100px;
        &.top-space {
          padding-top: 300px;
        }
      }
    }

    &:before {
        /**
         * These values will not show up in content, but can be
         * queried by JavaScript to know which breakpoint is active.
         * Add or remove as many breakpoints as you like.
         */
        content: "phone";
        display: none;

        @media @mq2col {
            content: "tablet";
        }
        @media @mq3col {
            content: "desktop";
        }
    }
}

a:hover, a:active, a:focus, button:hover, button:active, button:focus, input:focus, select:focus, textarea:focus {
  outline: 0;
  box-shadow: none;
  border: 0 none;
}
a:focus, a:hover {
    color: inherit;
}

.copytext {
  a {
    text-decoration: underline;
  }
}

* {
    font-family: @font-family;
    user-select: none;
    font-weight: 300;
}

.flex-row-md {
  display: block;
  @media(min-width: 992px) {
    display: flex;
  }
}

button {
  border: 0;
}

input, textarea, select{
    user-select: text;
}

// Paragraphs
//=========
p + p {
    margin-top: 1rem;
}

// Headings
//=========
h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: 1.75rem;
}

h3 {
    font-size: 1.5rem;
}

h4 {
    font-size: 1rem;
}

h1, h2, h3, h4, h5, h6 {
    [class^="icon-"], .text-label, .number-label {
        margin-right: 2px;
    }
}

hr {
    border-width:1px;
    margin:2rem 0;
}

a {
    color: #fff;
}

main {
    position: relative;
    z-index: 1;
}

#skrollr-body {
  //min-height: 1200px;
}

.btn.btn-more {
  font-size: 14px;
  background: #ddd !important;
  border: 0;
  color: #2b2b2b !important;
  padding: 2px 10px;
  &:hover {
    background: #fff;
  }
}

#top-btn {
  position: fixed;
  display: none;
  width: 50px;
  height: 50px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
  background: rgba(0,0,0,.6);
  color: #ccc;
  bottom: 15px;
  right: 15px;
  z-index: 99999;
  text-align: center;
  line-height: 46px;
  font-size: 26px;
  transition: opacity .3s;
  &:hover {
    color: #fff;
  }
}

.border-bottom-light {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.numbered-headline {
    font-size: 1.5rem;
    font-weight: @font-regular;
    color: @text-color-bright;
    margin: 0 0 1.5rem;
}

.page-headline {
    font-size: 3.4rem;
    line-height: 1.3em;
    margin-top: 160px;
    margin-bottom: 80px;
    color: @text-color-bright;
    text-transform: uppercase;
    text-align: center;


    // Phone ONLY!
    //=================================================
    @media @mq1col {
        font-size: 2rem;
        margin-top: 120px;
        margin-bottom: 30px;
    }
}

.font-light {
    font-weight: 300;
}

.font-normal {
    font-weight: 400;
}

.font-medium {
    font-weight: 500;
}

.font-bold {
    font-weight: 700;
}

// Icons
//=================================================
.icon {
    margin-right: 0.5rem;
}

// Textdecorations
// ================================================
.underlined {
    text-decoration: underline;
}

.text-small {
    font-size: 14px;
    font-weight: 300;
}

// Hide stuff
// ===============================================

.hidden-mobile {
    @media @mq1col {
        display: none;
    }
    @media @mq2col {
        display: block;
    }
}

.hidden-tablet {
    @media @mq2col {
        display: none;
    }
    @media @mq3col {
        display: initial;
    }
}

.hidden-desktop {
    @media @mq3col {
        display: none;
    }
}

.mobile-only {
    @media @mq2col {
        display: none !important;
    }
}

.desktop-only {
    display: none;
    @media @mq3col {
        display: initial;
    }
}

// this has to be last
.hidden {
    display: none ;
}
.hidden.important {
    display: none !important;
}



// selection
::selection {
    background: transparent;
}

.vspace {
  height: 42px;
}

.transparent-form {
  input, select, textarea, input:focus, select:focus, textarea:focus {
    border-radius: 0;
    background: transparent;
    border: 0;
    border-bottom: 1px solid #fff;
    padding: 6px 12px;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
    font-weight: 300;
    font-size: 15px;
  }
  .input-group-addon, .input-group-btn .btn, .input-group-btn .btn:hover, .input-group-btn .btn:focus {
    border-radius: 0;
    background: transparent;
    border: 0;
    border-bottom: 1px solid #fff;
    color: #fff;
    font-size: 15px;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
.btn-standalone {
  margin-bottom: 21px;
}
.btn-ci {
  position: relative;
  border: 1px solid #2b2b2b;
  background: transparent;
  color: #2b2b2b;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 250px;
  height: 60px;
  line-height: 60px;
  padding: 0;
  text-transform: uppercase;
  border-radius: 4px;
  border-top-left-radius: 18px;
  border-bottom-right-radius: 18px;
  text-align: left;
  padding-left: 72px;
  font-size: 17px;
  &:hover {
    border: 0;
    background: #2b2b2b;
    color: #fff;
  }
  .llo-icon {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -16px;
    font-size: 32px;
    width: 72px;
    text-align: center;
  }
}

.btn-ci-dynamic {
  border: 0;
  background: rgba(0,0,0,.75);
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 4px;
  border-top-left-radius: 18px;
  border-bottom-right-radius: 18px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 300;
  padding: 8px 18px;
  &:hover, &:active, &:focus, &.active:focus, &:active:focus {
    border: 0;
    background: #060606;
    color: #fff;
    outline: 0;
  }
  &.light {
    background: rgba(255,255,255,.75);
    color: #2b2b2b;
    &:hover, &:active, &:focus, &.active:focus, &:active:focus {
      background: #ffffff !important;
      color: #2b2b2b !important;
    }
  }
}

.panel-ci {
  position: relative;
  border: 0;
  border-radius: 4px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 18px;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent;
  //background-color: rgba(0,0,0,0.75);
  color: #fff;
  .panel-heading {
    position: relative;
    padding: 0 15px 0 67.5px;
    //border-bottom: 1px solid #ccc;
    border-top-left-radius: 25px;
    border-top-right-radius: 4px;
    height: 52.5px;
    line-height: 52.5px;
    text-align: left;
    background-color: rgba(0, 0, 0, 0.75);
    margin-bottom: 2px;
    overflow: hidden;
    .icon {
      position: absolute;
      left: 0;
      top: 0;
      width: 52.5px;
      height: 52.5px;
      background: #fff;
      border-top-left-radius: 18px;
      color: #2b2b2b;
      text-align: center;
      font-size: 36px;
      .fa, .lloicon {
        position: absolute;
        left: 0;
        width: 52.5px;
        height: 36px;
        top: 8.25px;
      }
    }
    /*-webkit-transition: opacity .3s linear;
    transition: opacity .3s linear;*/
    @media(max-width: 1199px) {
      font-size: 17px;
    }
  }
  .panel-body {
    font-size: 15px;
    text-align: left;
    padding-bottom: 45px;
    background-color: rgba(0, 0, 0, 0.75);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 18px;
    img {
      max-width: 100%;
    }
    b {
      font-weight: 400;
    }
    /*-webkit-transition: opacity .3s linear;
    transition: opacity .3s linear;*/
  }
  .panel-overlay {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 4px;
    border-top-left-radius: 18px;
    border-bottom-right-radius: 18px;
    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 56px;
      height: 56px;
      margin-top: -28px;
      margin-left: -28px;
      font-size: 56px;
      .fa, .lloicon {
        display: block;
      }
    }
    /*-webkit-transition: opacity .3s linear;
    transition: opacity .3s linear;*/
  }
  .readmore {
    position: absolute;
    bottom: 10px;
    right: 15px;
    font-size: 14px;
  }
  @media (min-width: 1200px) {
    .hover-hide:hover & {
      border-top-left-radius: 18px;
      .panel-heading, .panel-body {
        opacity: 0;
      }
      .panel-overlay {
        opacity: 1;
      }
    }
  }
  &.panel-ci-light {
    color: #000;
    a {
      color: #000;
    }
    .panel-heading {
      background-color: rgba(255, 255, 255, 0.75);
      padding: 0 15px 0 130px;
      .icon {
        background: #000;
        color: #fff;
        font-size: 16px;
        line-height: 1.1;
        width: 105px;
        padding-top: 10px;
      }
    }
    .panel-body {
      background-color: rgba(255, 255, 255, 0.75);
    }
    .panel-overlay {
      background-color: rgba(255, 255, 255, 0.75);
      .icon {
      }
    }
  }
  &.panel-ci-blog {
    .panel-overlay {
      .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 72px;
        height: 72px;
        margin-top: -72px;
        margin-left: -36px;
        font-size: 72px;
      }
      .text {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        text-align: center;
        margin-top: 10px;
        font-size: 32px;
      }
    }
  }
}

.section {
  position: relative;
  padding: 147px 0;
  overflow: visible;
  &.section-tight {
    padding: 0;
  }
  &.section-default {
    background: #fff;
    &.section-transparent {
      background-color: transparent;
    }
    color: #2b2b2b;
    a {
      color: #2b2b2b;
    }
    .btn {
      color: #fff;
    }
    .btn-ci {
      border: 1px solid #2b2b2b;
      background: transparent;
      color: #2b2b2b;
      &:hover {
        background: #2b2b2b;
        color: #fff;
      }
    }
    &.section-marker-top:before {
      position: absolute;
      left: 50%;
      top: -17px;
      margin-left: -40px;
      height: 34px;
      background: #fff;
      content: '\6c';
      z-index: 10;
      font-size: 56px;
      color: #2b2b2b;
      padding: 6px;
      border-top-left-radius: 12px;
      border-bottom-right-radius: 12px;
      border-top-right-radius: 3px;
      font-family: "llo-logo" !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-variant: normal !important;
      text-transform: none !important;
      speak: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    &.section-marker-bottom:after {
      position: absolute;
      left: 50%;
      bottom: -17px;
      margin-left: -40px;
      height: 34px;
      background: #fff;
      content: '\6c';
      z-index: 10;
      font-size: 56px;
      color: #2b2b2b;
      padding: 6px;
      border-top-left-radius: 12px;
      border-bottom-right-radius: 12px;
      border-top-right-radius: 3px;
      font-family: "llo-logo" !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-variant: normal !important;
      text-transform: none !important;
      speak: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
  &.section-alternative {
    background: transparent;
    color: #fff;
  }
}

.logo-line {
  position: relative;
  text-align: center;
  font-size: 55px;
  line-height: 1;
  height: 55px;
  margin-bottom: 42px;
  color: #b6b6b6;
  &:before {
    position: absolute;
    left: 0;
    right: 50%;
    top: 50%;
    height: 1px;
    margin-right: 60px;
    background: #b6b6b6;
    content: '';
  }
  &:after {
    position: absolute;
    right: 0;
    left: 50%;
    top: 50%;
    height: 1px;
    margin-left: 60px;
    background: #b6b6b6;
    content: '';
  }
  &.tight {
    margin-bottom: 0;
  }
}

hr.logo-line {
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0;
  padding: 0;
  height: 63px;
  background: transparent;
  position: relative;
  &:before {
    position: absolute;
    left: 50%;
    top: 20px;
    width: 120px;
    height: 100px;
    margin-top: -50px;
    margin-left: -60px;
    background: #fff;
    color: #ccc;
    font-size: 60px;
    content: '\6c';
    height: 60px;
    text-align: center;
    font-family: "llo-logo" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  &.dark {
    border-top: 1px solid #2b2b2b;
    &:before {
      background: transparent;
      color: #2b2b2b;
    }
  }
  &.trans {
    &:before {
      background: transparent;
    }
  }
}

.background-layer {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #fff;
}

.headline {
  margin-bottom: 42px;
  h1 {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 4.5px;
    margin-bottom: 0;
    @media (min-width: 768px) {
      font-size: 26px;
    }
  }
  h2 {
    margin-top: 5px;
    font-size: 36px;
    font-weight: 100;
    @media (min-width: 768px) {
      font-size: 64px;
    }
  }
}

.vertical-wide {
  .headline {
    margin-bottom: 126px;
  }
  p {
    margin-bottom: 42px;
  }
}

.partner-teaser-items {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  .partner-teaser-item {
    position: relative;
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    float: left;
    /*width: 33.33333333%;*/
    margin: 0 0 1px 1px;
    &>div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .partner-teaser-item-partner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      -webkit-background-size: cover;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      }
    }
    .partner-label {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 90px;
      width: 100%;
      padding: 15px;
      background: #000;
      color: #fff;
      .small {
        /*font-style: italic;*/
        font-size: 17px;
        line-height: 21px;
        .cat-icon {
          float: left;
          margin-right: 10px;
          font-size: 20px;
          height: 21px;
          @media (max-width: 767px) {
            font-size: 17px;
          }
          @media (max-width: 580px) {
            font-size: 15px;
          }
        }
      }
      h3 {
        margin-top: 5px;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 300;
        line-height: 18px;
        @media (max-width: 1199px) {
          font-size: 18px;
        }
        @media (max-width: 767px) {
          font-size: 17px;
        }
        @media (max-width: 580px) {
          font-size: 15px;
        }
      }
  }
}
.llo-icon {
  display: inline-block;
  font: normal normal normal 14px/1 "llo-logo";
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.tp-dottedoverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: auto;
  height: auto;
}
.address-line {
  position: relative;
  padding-left: 30px;
  font-size: 15px;
  line-height: 25px;
  margin-top: 10.5px;
  .address-line-icon {
    position: absolute;
    left: 0;
    top: 0;
    line-height: 25px;
    &.llo-icon {
      top: 3px;
    }
    &.fa-home {
      font-size: 19px;
    }
  }
}
.outline-form {
  .form-control {
    background: transparent;
    border: 1px solid #fff;
    color: #fff;
    &:focus {
      background: transparent;
      padding: 6px 12px;
    }
  }
  select.form-control, select.form-control:focus {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+DQo8c3ZnDQogICB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iDQogICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIg0KICAgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIg0KICAgeG1sbnM6c3ZnPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyINCiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyINCiAgIGlkPSJzdmcyIg0KICAgeG1sOnNwYWNlPSJwcmVzZXJ2ZSINCiAgIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDYgMyINCiAgIHZpZXdCb3g9IjAgMCA2IDMiDQogICBoZWlnaHQ9IjNweCINCiAgIHdpZHRoPSIyNHB4Ig0KICAgeT0iMHB4Ig0KICAgeD0iMTJweCINCiAgIHZlcnNpb249IjEuMSI+PG1ldGFkYXRhDQogICAgIGlkPSJtZXRhZGF0YTEwIj48cmRmOlJERj48Y2M6V29yaw0KICAgICAgICAgcmRmOmFib3V0PSIiPjxkYzpmb3JtYXQ+aW1hZ2Uvc3ZnK3htbDwvZGM6Zm9ybWF0PjxkYzp0eXBlDQogICAgICAgICAgIHJkZjpyZXNvdXJjZT0iaHR0cDovL3B1cmwub3JnL2RjL2RjbWl0eXBlL1N0aWxsSW1hZ2UiIC8+PGRjOnRpdGxlPjwvZGM6dGl0bGU+PC9jYzpXb3JrPjwvcmRmOlJERj48L21ldGFkYXRhPjxkZWZzDQogICAgIGlkPSJkZWZzOCIgLz48cG9seWdvbg0KICAgICBzdHlsZT0iZmlsbDojZmZmZmZmO2ZpbGwtb3BhY2l0eToxIg0KICAgICBpZD0icG9seWdvbjQiDQogICAgIHBvaW50cz0iNS45OTIsMCAyLjk5MiwzIC0wLjAwOCwwICIgLz48L3N2Zz4=);
    background-position: 100% center;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}
.panel-group {
  .panel-dark {
    border: 0;
    background: rgba(0,0,0,.75);
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 4px;
    border-top-left-radius: 18px;
    border-bottom-right-radius: 18px;
    color: #fff;
    .panel-heading {
      padding: 0;
      .panel-title {
        a {
          display: block;
          font-size: 18px;
          font-weight: 400;
          padding: 15px 30px 15px 30px;
          * {
            font-weight: inherit;
          }
          &:hover, &:active, &:focus {
            text-decoration: none;
          }
          .toggle-up, .toggle-down {
            margin: 0 0 15px 15px;
          }
          .toggle-up {
            display: none;
          }
          &[aria-expanded="true"] {
            .toggle-up {
              display: inline-block;
            }
            .toggle-down {
              display: none;
            }
          }
        }
      }
      &+.panel-collapse {
        &>.panel-body {
          border-top: 0;
          padding: 15px 30px;
          *:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    &+.panel {
      margin-top: 20px;
    }
  }
}
.share-widget {
  position: absolute;
  right: 0;
  bottom: 0;
  .share-widget-share {
    position: absolute;
    right: 0;
    bottom: 0;
    background: #ccc;
    color: #fff;
    border-radius: 0;
    border-bottom-right-radius: 18px;
    width: 50px;
    line-height: 40px;
    text-align: center;
  }
  .share-widget-networks {
    position: absolute;
    display: none;
    right: 0;
    bottom: 0;
    position: relative;
    width: 101px;
    height: 81px;
    .share-widget-network {
      position: absolute;
      display: block;
      background: #ccc;
      color: #2b2b2b;
      width: 50px;
      font-size: 22px;
      line-height: 32px;
      text-align: center;
      padding: 7px 0 1px;
      &:hover {
        color: #000;
      }
      &.facebook {
        left: 0;
        top: 0;
        border-top-left-radius: 18px;
      }
      &.twitter {
        right: 0;
        top: 0;
        border-top-right-radius: 4px;
      }
      /*&.google-plus {
        left: 0;
        bottom: 0;
        border-bottom-left-radius: 4px;
      }*/
      &.email {
        /*right: 0;
        border-bottom-right-radius: 18px;*/
        left: 0;
        bottom: 0;
        border-bottom-left-radius: 18px;
      }
      &.whatsapp {
        display: none;
      }
    }
    @media(max-width: 1199px) {
      /*width: 152px;*/
      .share-widget-network {
        /*&.twitter {
          right: 51px;
          top: 0;
          border-top-right-radius: 0;
        }
        &.email {
          right: 0;
          top: 0;
          bottom: auto;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 0;
        }
        &.whatsapp {
          display: block;
          right: 51px;
          bottom: 0;
          padding-top: 2px;
          line-height: 37px;
        }*/
        &.whatsapp {
          display: block;
          right: 0;
          bottom: 0;
          padding-top: 2px;
          line-height: 37px;
          border-bottom-right-radius: 0;
        }
      }
    }
  }
  &:hover, &.active {
    .share-widget-share {
      display: none;
    }
    .share-widget-networks {
      display: block;
    }
    /*@media(max-width: 1199px) {*/
    @media(min-width: 1200px) {
      .share-widget-share {
        display: block;
      }
    }
  }
}
.light {
  .share-widget {
    .share-widget-share {
      background: #2b2b2b;
      color: #ccc;
    }
    .share-widget-networks {
      .share-widget-network {
        background: #2b2b2b;
        color: #ccc;
        &:hover {
          color: #fff;
        }
      }
    }
  }
}
.form-group.error .error-label {
  display: block !important;
}
.payment-icon {
  display: inline-block;
  padding: 5px 10px;
  background: #fff;
}
.btn-outline {
  border: 1px solid #fff;
}
.partner-details-box {
  margin-bottom: 30px;
  .dark {
    background: rgba(0,0,0,0.75);
  }
  .light {
    background: rgba(255,255,255,0.75);
    color: #2b2b2b;
    a {
      color: #2b2b2b;
    }
  }
  &.full-height {
    height: 300px;
    @media(min-width: 992px) {
      position: absolute;
      left: 15px;
      right: 15px;
      top: 0;
      bottom: 0;
      height: auto;
    }
  }
  .partner-details-box-complete {
    border-radius: 6px;
    border-top-left-radius: 27px;
    border-bottom-right-radius: 27px;
    -webkit-border-radius: 6px;
    -webkit-border-top-left-radius: 27px;
    -webkit-border-bottom-right-radius: 27px;
    padding: 30px;
    position: relative;
    overflow: hidden;
    &.mheight {
      min-height: 400px;
    }
  }
  .partner-details-box-top {
    border-radius: 0;
    border-top-left-radius: 27px;
    border-top-right-radius: 6px;
    padding: 30px;
    position: relative;
  }
  .partner-details-box-bottom {
    border-radius: 0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 27px;
    padding: 30px;
    position: relative;
  }
  h1 {
    font-size: 60px;
    font-weight: 200;
    margin-top: 0;
  }
  h2 {
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 4.5px;
    margin-top: 0;
    margin-bottom: 21px;
  }
  &.partner-details-teaser {
    h1 {
      margin-top: 10.5px;
      margin-bottom: 0;
      padding-right: 100px;
      @media(max-width: 767px) {
        font-size: 24px;
        padding-right: 48px;
      }
    }
    h2 {
      margin-top: 0;
      margin-bottom: 0;
      padding-right: 100px;
    }
    h4 {
      font-size: 24px;
      margin-bottom: 21px;
    }
    ul {
      padding-left: 20px;
    }
    .partner-details-icon {
      position: absolute;
      right: 30px;
      top: 30px;
      font-size: 60px;
      @media(max-width: 767px) {
        font-size: 48px;
      }
    }
    .partner-details-box-middle img {
      display: block;
    }
  }
  &.partner-details-contact {
    .contact-details, .contact-details * {
      font-size: 15px;
      vertical-align: top;
    }
    .row > div:first-child {
      margin-bottom: 30px;
    }
  }
}
.modal-message {
  position: fixed;
  top: 120px;
  right: 20px;
  width: 300px;
  background: #000;
  z-index: 1100;
  -webkit-border-radius: 6px;
  -webkit-border-top-left-radius: 27px;
  -webkit-border-bottom-right-radius: 27px;
  border-radius: 6px;
  border-top-left-radius: 27px;
  border-bottom-right-radius: 27px;
  padding: 20px;
  font-size: 15px;
  .modal-close {
    position: absolute;
    display: inline-block;
    right: 10px;
    top: 10px;
  }
  button {
    background: #000;
    padding: 0;
    font-size: 17px;
    font-weight: 300;
  }
}
@media(max-width: 991px) {
  input[type='text'],
  input[type='number'],
  textarea {
    font-size: 16px;
  }
}
.form-group.checkbox label:before {
    margin-right: 2px;
    top: 1px;
    height: 12px;
    width: 12px;
}
.product-sales-banner {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
    color: white;
    background: #d01b30;
    font-weight: bold;
    padding: 1px 5px;
  }
