[data-page="preopening"] {
  min-height: 100%;
  background: url('/img/preopening_background.jpg') center no-repeat;
  background-size: cover;

  .countdown {
    position: fixed;
    right: 0;
    top: 0;
    padding: 4rem;
    text-align: right;

    span {
      font-size: 2rem;
      color: #fff;
      text-transform: uppercase;
      &.icon {
        padding-right: 5px;
      }
    }
    @media @mq1col {
      width: 100%;
      padding:  0 20px 0 0;
      top: 34px;
      span {
        font-size: 1.5rem;
      }
    }
    @media @mq2col {
      width: 400px;
    }
    @media @mq3col {
      width: 400px;
    }
  }
  .newsletter {
    position: fixed;
    right: 0;
    bottom: 0;
    padding: 4rem;

    @media @mq1col {
      width: 100%;
      padding: 30px 10px;
    }
    @media @mq2col {
      width: 400px;
    }
    @media @mq3col {
      width: 400px;
    }
    input {
      display: block;
      width: 100%;
      appearance: none;
      background-color: #fff;
      height: 46px;
      border-top-left-radius: @border-radius;
      border-bottom-right-radius: @border-radius;
      border: 1px solid #000;
      font-size: 16px;
      &[placeholder] {
        text-transform: uppercase;
        padding: 0 1rem 0 1rem;
      }
    }
    label {
      width: 100%;
      margin-bottom: 20px;
      float: left;
      p {
        font-size: 1rem;
        font-weight: bold;
        color: #fff;
      }
    }
  }
}