[data-page="shop"] {
  .product-item {
    display: block;
    border-radius: 4px;
    border-top-left-radius: 18px;
    border-bottom-right-radius: 18px;
    margin-top: 30px;
    color: #fff;
    text-decoration: none;
    &:hover {
      box-shadow: 0 0 20px rgba(255, 255, 255, 0.75);
    }
    .product-item-image {
      position: relative;
      border-radius: 0;
      border-top-right-radius: 4px;
      border-top-left-radius: 18px;
      background: rgba(0,0,0,.75);
      img {
        border-radius: 0;
        border-top-right-radius: 4px;
        border-top-left-radius: 18px;
        max-width: 100%;
      }
      .product-sales-banner {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 18px;
        color: white;
        background: #d01b30;
        font-weight: bold;
        padding: 1px 5px;
      }
    }
    .product-item-content {
      position: relative;
      border-radius: 0;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 18px;
      background: rgba(0, 0, 0, 0.75);
      padding: 20px;
      .product-item-content-wrapper {
        .product-item-title {
          font-weight: 200;
          margin-top: 5px;
          font-size: 28px;
          height: 60px;
          overflow: hidden;
          @media(min-width: 992px) {
            font-size: 23px;
            height: 50px;
          }
          @media(min-width: 1200px) {
            font-size: 28px;
            height: 60px;
          }
        }
        .product-item-price {
          font-weight: 200;
          font-size: 20px;
        }
        .product-item-old-price {
          font-weight: 300;
          font-size: 20px;
          margin: 0;
          position: absolute;
          bottom: 52px;
          font-size: 17px;
          color: #d01b30;
          text-decoration: line-through;
        }
      }
      .product-item-btn {
        display: block;
        position: absolute;
        right: 15px;
        bottom: 10px;
        border: 0;
        background: transparent;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  #main-image-wrapper {
    margin-bottom: 30px;
    position: relative;
    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }
  }
  #thumbnail-images-wrapper {
    .row {
      &>div {
        /*@media(min-width: 768px) {
          height: 95px;
        }
        @media(min-width: 992px) {
          height: 131.656px;
        }
        @media(min-width: 1200px) {
          height: 99.984px;
        }*/
        margin-bottom: 30px;
        a {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 100%;
        }
      }
    }
  }
  .partner-details-box {
    margin-bottom: 30px;
    .dark {
      background: rgba(0,0,0,0.75);
    }
    &.full-height {
      height: 300px;
      @media(min-width: 992px) {
        position: absolute;
        left: 15px;
        right: 15px;
        top: 0;
        bottom: 0;
        height: auto;
      }
    }
    .partner-details-box-complete {
      border-radius: 6px;
      border-top-left-radius: 27px;
      border-bottom-right-radius: 27px;
      -webkit-border-radius: 6px;
      -webkit-border-top-left-radius: 27px;
      -webkit-border-bottom-right-radius: 27px;
      padding: 30px;
      position: relative;
      overflow: hidden;
    }
    .partner-details-box-top {
      border-radius: 0;
      border-top-left-radius: 27px;
      border-top-right-radius: 6px;
      padding: 30px;
      position: relative;
    }
    .partner-details-box-bottom {
      border-radius: 0;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 27px;
      padding: 30px;
      position: relative;
    }
    h1 {
      font-size: 60px;
      font-weight: 200;
      margin-top: 0;
    }
    h2 {
      font-size: 22px;
      text-transform: uppercase;
      font-weight: 400;
      letter-spacing: 4.5px;
      margin-top: 0;
      margin-bottom: 21px;
    }
    &.partner-details-teaser {
      h1 {
        margin-top: 10.5px;
        margin-bottom: 0;
        padding-right: 100px;
      }
      h2 {
        margin-top: 0;
        margin-bottom: 0;
        padding-right: 100px;
      }
      h4 {
        font-size: 24px;
        margin-bottom: 21px;
      }
      ul {
        padding-left: 20px;
      }
      .partner-details-icon {
        position: absolute;
        right: 30px;
        top: 30px;
        font-size: 90px;
      }
      .partner-details-box-middle img {
        display: block;
      }
    }
    &.partner-details-contact {
      table * {
        font-size: 14px;
        vertical-align: top;
      }
      .row > div:first-child {
        margin-bottom: 30px;
      }
    }
  }
  .product-details {
    .product-title {
      margin-top: 0;
      font-size: 40px;
      font-weight: 300;
    }
    .cost-line {
      position: relative;
    }
    .product-old-cost {
      font-weight: 400;
      font-size: 20px;
      margin: 0;
      position: absolute;
      bottom: 52px;
      right: 0;
      font-size: 24px;
      color: #d01b30;
      text-decoration: line-through;
    }
    .product-cost {
      font-size: 40px;
      font-weight: 400;    
    }
  }
  h3 {
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 4.5px;
    margin-top: 0;
    margin-bottom: 21px;
  }
  label {
    font-size: 14px;
    font-weight: 300;
    padding: 0;
    text-transform: uppercase;
  }
  .btn:not(.btn-ci-dynamic) {
    background: #222;
    color: #ccc;
    border: 0;
    min-width: 136px;
    width: auto;
    height: auto;
    &:hover {
      background: #191919;
    }
    &.btn-primary {
      background: #fff;
      color: #2b2b2b;
      &:hover {
        background: #ddd;
      }
    }
  }
  .cart-box {
    font-size: 18px;
    td {
      padding: 10px 0;
    }
  }
}
