[data-page="404"] {
    min-height: 100%;

    background: linear-gradient(transparent 0, #000 1600px), url('/img/LLOweb_muc_1920x1113_cut.jpg') top no-repeat;
    background-size: cover;
    @media @mq2col {
        background: url('/img/LLOweb_muc_1920x1113_cut.jpg') top no-repeat #000;
        background-size: cover;
        background-attachment: fixed;
    }

    .center-text {
        width: 100%;
        text-align: center;
        position: fixed;
        top: 50%;
        transform: translateY(-50%);
        h1 {
            color: #eee;
            text-transform: uppercase;
            font-weight: 500;
        }
    }
}
