[data-page="regions"] {
  .region-item {
    display: block;
    border-radius: 4px;
    border-top-left-radius: 18px;
    border-bottom-right-radius: 18px;
    margin-top: 30px;
    color: #fff;
    text-decoration: none;
    &:hover {
      box-shadow: 0 0 20px rgba(255, 255, 255, 0.75);
    }
    .region-item-image {
      position: relative;
      border-radius: 0;
      border-top-right-radius: 4px;
      border-top-left-radius: 18px;
      background: rgba(0,0,0,.75);
      img {
        border-radius: 0;
        border-top-right-radius: 4px;
        border-top-left-radius: 18px;
        max-width: 100%;
      }
    }
    .region-item-content {
      position: relative;
      border-radius: 0;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 18px;
      background: rgba(0, 0, 0, 0.75);
      padding: 20px;
      .region-item-content-wrapper {
        .region-item-title {
          font-weight: 200;
          margin-top: 5px;
          font-size: 28px;
          height: 60px;
          overflow: hidden;
          @media(min-width: 992px) {
            font-size: 23px;
            height: 50px;
          }
          @media(min-width: 1200px) {
            font-size: 28px;
            height: 60px;
          }
        }
      }
      .region-item-btn {
        display: block;
        position: absolute;
        right: 15px;
        bottom: 10px;
        border: 0;
        background: transparent;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}
