body > header {
    position: relative;
    z-index: 1050;
    width: 100%;
    left: 0;
    top: 0;

    .logo {
        position: absolute;
        left: 35px;
        top: 35px;

        //@media @mq1col {
        //    overflow: hidden;
        //    height: 25px;
        //    width: 65px;
        //}

        img {
            display: block;
            width: 165px;
            height: auto;
            @media @mq1col {
                left: -20px;
                top: -20px;
                position: absolute;
            }
            @media @mq2col {
                width: 213px;
                height: 57px;
            }
        }
    }

    .toggle-nav {
        z-index: 2;
        width: auto;
        height: auto;
        background-color: transparent;
        position: fixed;

        top: 40px;
        right: 17px;
        @media @mq2col {
            top: 40px;
            right: 17px;
        }
        color: #fff;
        font-size: 20px;
        line-height: 20px;

        .icon {
            font-size: 21px;
            margin: 0;
            padding: 0;
/*            color: #fff;/*@navHamburgerColor;*/
            text-shadow: none;
            float: right;
            margin-left: 10px;
            position: relative;
            line-height: 17px;
            /*@media @mq2col {
                text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.75);
            }*/
        }
        &.active {
          display: none;
        }
    }

    .sticky-header-background {
        z-index: 1;
        position: fixed;
        right: 0;
        top: 12px;
        height: 38px;
        width: 53px;
        /*background-color: #000;*/

        @media @mq2col {
            top: 31px;
        }
    }

    .navbar {
      border: 0;
      border-radius: 0;
      min-height: 50px;
      height: 100px;
      transition: height 200ms;
      .navbar-brand {
        position: relative;
        padding: 23px 15px;
        .logo-horizontal {
          display: none;
        }
        .logo-ci {
          display: inline-block;
          position: relative;
          white-space: nowrap;
          .lloicon-llo-logo {
            position: relative;
            display: block;
            font-size: 66px;
            margin: -19.5px 0 0;
            left: 76px;
            line-height: 27px;
            & ~ .llo-text-bottom {
              position: relative;
              display: inline-block;
              white-space: nowrap;
              margin-top: 6px;
              //top: -7px;
              line-height: 17px;
              text-transform: uppercase;
              font-size: 23px;
            }
          }
        }
      }
      .nav-container {
        position: relative;
      }
      .toggle-nav {
        position: relative;
        /*top: auto;/*34px;*/
        /*right: auto;/*35px;*/
        top: 25px;
        right: 17px;
        margin-top: 15px;
        padding: 0;
        transition: top 200ms;
        &>span {
          display: inline-block;
        }
      }
      .navbar-nav {
        position: absolute;
        top: 0;
        right: 15px;
//        left: 450px;
        padding-top: 25px;
        padding-bottom: 25px;
        transition: all 200ms;
        &>li>a  {
          text-transform: uppercase;
          font-size: 20px;
          line-height: 20px;
        }
        &>li:last-child>a {
          padding-right: 0;
        }
      }
      &.navbar-default {
        background: #fff;
        color: #b0b0b0;
        .navbar-brand {
          color: #2b2b2b;
        }
        .toggle-nav {
          color: #777;
          margin-right: 6px;
          &.active {
            display: block;
            color: #2b2b2b;
          }
        }
        &.transparent {
          background: transparent;
          .navbar-brand, .navbar-nav {
            display: none;
          }
          .toggle-nav {
            color: #fff;
          }
          &.hidden-on-transparent {
            display: none;
          }
        }
      }
      &.narrow {
        height: 50px;
        .navbar-brand {
          padding: 17px 15px;
          .logo-horizontal {
            display: block;
            @media(max-width: 479px) {
              width: 230px;
            }
          }
          .logo-vertical {
            display: none;
          }
          .logo-ci {
            .lloicon-llo-logo {
              left: 0;
              font-size: 40px;
              line-height: 23px;
              float: left;
              & ~ .llo-text-bottom {
                display: inline-block;
                float: right;
                font-size: 20px;
                line-height: 20px;
                margin-left: 17px;
                margin-top: -9px;
              }
            }
          }
        }
        .toggle-nav {
          top: 0;
        }
        .navbar-nav {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
}
