[data-page="partners"] {

    //background: linear-gradient(transparent 0, transparent 500px, #000 1000px), url('/img/partners_background_small.jpg') 10% top / 300% auto no-repeat;
    /*background: #fff url('/img/LLOweb_muc_1920x1113_cut.jpg') left 50px no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
    //background-size: cover;
    @media @mq2col {
        background: #fff url('/img/LLOweb_muc_1920x1113_cut.jpg') left 50px no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
        background-attachment: fixed;
    }*/

    .partner-item {
      display: block;
      border-radius: 4px;
      border-top-left-radius: 18px;
      border-bottom-right-radius: 18px;
      margin-top: 30px;
      color: #fff;
      text-decoration: none;
      @media(min-width: 992px) {
        display: flex;
      }
      &.active, &:hover {
        box-shadow: 0 0 20px rgba(255,255,255,0.75);
      }
      .partner-item-image-col {
        border-radius: 0;
        border-top-right-radius: 4px;
        border-top-left-radius: 18px;
        background-size: cover;
        @media(min-width: 992px) {
          width: 50%;
          height: 236px;
          flex-grow: 1;
          border-top-right-radius: 0;
          border-bottom-left-radius: 6px;
        }
        @media(min-width: 1200px) {
          height: 286px;
        }
        img {
          border-radius: 0;
          border-top-right-radius: 4px;
          border-top-left-radius: 18px;
          max-width: 100%;
        }
      }
      .partner-item-content-col {
        position: relative;
        border-radius: 0;
        border-bottom-right-radius: 18px;
        border-bottom-left-radius: 4px;
        background: rgba(0, 0, 0, 0.75);
        @media(min-width: 992px) {
          width: 50%;
          height: 236px;
          flex-grow: 1;
          border-bottom-left-radius: 0;
          border-top-right-radius: 6px;
        }
        @media(min-width: 1200px) {
          height: 286px;
        }
      padding: 20px;
        .partner-item-content-wrapper {
          position: relative;
          .partner-item-icon {
            position: absolute;
            right: 0px;
            top: 0px;
            font-size: 44px;
            @media(min-width: 992px) {
              font-size: 36px;
            }
            @media(min-width: 1200px) {
              font-size: 44px;
            }
          }
          .partner-item-category {
            font-size: 15px;
            font-weight: 500;
            @media(min-width: 992px) {
              font-size: 12.5px;
            }
            @media(min-width: 1200px) {
              font-size: 15px;
            }
          }
          .partner-item-title {
            font-weight: 200;
            margin-top: 5px;
            padding-right: 60px;
            font-size: 28px;
            @media(min-width: 992px) {
              font-size: 23px;
            }
            @media(min-width: 1200px) {
              font-size: 28px;
            }
        }
          .partner-item-subtitle {
            margin-top: 31.5px;
            font-size: 18px;
            @media(min-width: 992px) {
              font-size: 15px;
              margin-top: 10px;
            }
            @media(min-width: 1200px) {
              font-size: 18px;
              margin-top: 31.5px;
            }
          }
          .partner-item-description {
            margin-top: 21px;
            font-size: 14px;
            ul {
              padding-left: 20px;
            }
            @media(min-width: 992px) {
              margin-top: 10.5px;
              font-size: 11.5px;
            }
            @media(min-width: 1200px) {
              margin-top: 21px;
              font-size: 14px;
            }
          }
        }
        .partner-item-btn {
          display: block;
          position: absolute;
          right: 15px;
          bottom: 10px;
          border: 0;
          background: transparent;
          //color: #2b2b2b;
          //border-bottom-right-radius: 27px;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
    .partners-filter-wrapper {
      //margin-bottom: 30px;
      .partner-filter-header {
        border-radius: 0;
        border-top-left-radius: 18px;
        border-top-right-radius: 4px;
        #partners-filter-button {
          position: relative;
          width: 200px;
          text-align: left;
          span {
            line-height: 25px;
            &.llo-icon {
              line-height: 1;
              margin-top: 3px;
              margin-right: 12px;
            }
            &.llo-left {
              width: 30px;
              border-right: 1px solid #666;
              margin-right: 17px;
            }
          }
          .icon-active {
            display: none;
            position: absolute;
            left: 0;
            top: 0;
            padding: 8px 0 8px 18px;
            background: #fff;
            color: #000;
            height: 41px;
            border-top-left-radius: 17px;
            border-bottom-left-radius: 3px;
          }
          .filters-inactive-icon {
            display: inline-block;
            margin-right: 8px;
          }
          .filters-icon-up, .filters-active-icon {
            display: none;
          }
          &.filters-active {
            .icon-active {
              display: block;
            }
            .filters-inactive-icon {
              display: none;
            }
            .filters-active-icon {
              display: inline-block;
              margin-right: 8px;
            }
          }
        }
        .filter-close-button {
          margin-right: 18px;
          line-height: 41px;
          display: none;
          cursor: pointer;
        }
      }
      &.active {
        .partner-filter-header {
          background: rgba(0,0,0,.75);
          #partners-filter-button {
            background: #060606;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            .filters-icon-up {
              display: inline-block;
            }
            .filters-icon-down {
              display: none;
            }
          }
          .filter-close-button {
            display: block;
          }
        }
      }
    }



/*    background: #fff url('/img/LLOnew_Muc_sw_dark.png') left 100px no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    //background-size: cover;
    @media @mq2col {
        background: #fff url('/img/LLOnew_Muc_sw_dark.png') left 100px no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
        background-attachment: scroll;
    }*/
    .wrapper {
        overflow: visible;
        @media @mq2col {
            width: 730px;
        }
        @media @mq3col {
            width: 940px;
        }
    }

    .page-headline {
        font-weight: 500;
    }

    .btn.cta {
        height: 42px;
        line-height: 35px;
        position: absolute;
        right: 30px;
        bottom: 30px;
        background-color: rgba(100, 100, 100, .5);
        border: 2px solid @gray;
        color: #fff;

        &:hover {
            border: 2px solid #fff;
            color: #fff;
        }
    }

.partners-filter-placeholder {
  //display: none;
  height: 41px;
  //margin-bottom: 41px;
  /*&.is--visible {
    display: block;
  }*/
}

#partners-filter-wrapper-section {
  &.is--fixed {
    position: fixed;
    left: 0px;
    top: 50px;
    z-index: 10;
    //height: 41px;
    //background: rgba(0, 0, 0, 0.75);
    width: 100%;
    .partners-filter-wrapper .partner-filter-header {
      background: rgba(0, 0, 0, 0.75);
      border-radius: 0;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 18px;
      #partners-filter-button {
        background: transparent;
        font-size: 15px;
        padding: 4px 18px;
        line-height: 25px;
        .icon-active {
          height: 33px;
          border-top-left-radius: 0;
          padding: 4px 3px 12px 18px;
        }
        .filters-icon-down, .filters-icon-up {
          /*line-height: 18px;*/
        }
        &.filters-active {
          width: 182px;
        }
      }
      .filter-close-button {
        line-height: 29px;
      }
    }
    .partners-filter-wrapper.active .partner-filter-header {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

  /*#partners-filter-wrapper-section {
    position: fixed;
    left: 0;
    width: 100%;
    top: 496px;
    z-index: 100;
  }*/

    .partners-filter-wrapper {
        z-index: 50;

        .filter-close-button {
            /*position: absolute;
            top: 20px;
            right: 17px;*/
        }

        #partners-filter-button {
            &.active {
                .filter-icon-right {
                    transform: rotate(-180deg);
                }
            }
            .filter-icon-right {
                transition: transform 0.3s ease;
                transform: rotate(0deg);
            }
        }

        #close-partners-details {
            .icon {
                position: relative;
                top: 2px;
                margin-right: 20px;
            }
            padding-left: 40px;
            padding-right: 40px;
        }
        .partners-top-button {
            height: 46px;
            line-height: 46px;
            font-size: 17px;
            position: static;
            margin-bottom: 10px;
            background-color: rgba(0, 0, 0, 0.75);
            transition: background-color 0.3s ease;
            .icon {
                display: inline-block;
            }

            &.active {
                background-color: rgba(0, 0, 0, 1);

                .filter-icon-right {
                    transform: rotate(180deg);
                }
                height: 50px;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
                padding-bottom: 5px;
                margin-bottom: 6px;
            }

            .filter-icon-left {
                margin-right: 25px;
                opacity: 0.3;
                &.active {
                    transition: all 2s ease;
                    animation-name: is_active;
                    animation-duration: 3s;
                    //animation-direction: alternate;
                    animation-iteration-count: infinite;
                }
            }
            .filter-icon-right {
                margin-left: 25px;
            }
        }

        .filter-expanded {
            h2 {
              font-weight: 400;
              font-size: 20px;
              text-transform: uppercase;
              margin-bottom: 20px;
              margin-top: 0;
              @media(max-width: 479px) {
                font-size: 17px;
              }
              @media(min-width: 992px) {
                margin-top: 20px;
              }
            }
            .border-wrapper {
              padding-left: 20px;
              border-left: 1px solid #fff;
              @media(max-width: 479px) {
                padding-left: 0;
                border-left: 0;
              }
            }
            //width: 100%;
            //border-top-right-radius: @border-radius;
            //overflow: hidden;
            //z-index: 5;
            //position: absolute;
            //top: 50px;
            //position:relative;
            display: none;
            //top:-6px;
            //left: 0;
            //margin-bottom:@gutter;
            //max-height: 0;
            //opacity: 0;
            //transition: max-height 0.3s ease, opacity 0.3s ease;

            &.active {
                display: block;
                //opacity: 1;
                //max-height: none;
                /*@media @mq2col {
                    max-height:587px;
                }

                @media @mq3col {
                    max-height: 533px;
                }
                box-shadow: 0px 17px 55px -8px rgba(0,0,0,0.9);*/
            }

            .top-row {
                background-color: #000;
                padding: 10px 20px 5px 30px;

                .form-group {
                    margin-bottom: 0;

                    label {
                        text-transform: uppercase;
                        font-weight: 500;
                        font-size: 16px;
                    }

                }

                .icon {
                    font-size: 24px;
                    cursor: pointer;
                }
            }

            .select-heading {
                @media (max-width: 767px) {

                    /*h2 {
                        font-size: 20px;
                        text-transform: uppercase;
                        font-weight: 500;
                        margin: 0;
                    }

                    color: #fff;
                    width: 262px;
                    position: relative;
                    left: 50%;
                    transform: translateX(-50%);

                    background-color: #fff;
                    background-image: url(../img/select-dropdown-arrow-small.png);
                    background-position: right;
                    background-repeat: no-repeat;
                    padding: 5px 50px 5px 25px;

                    border-top-left-radius: @border-radius;
                    border-bottom-right-radius: @border-radius;
                    border: 1px solid #000;

                    margin-bottom: 15px;
                    cursor: pointer;*/
                }
            }

            .category-select {
                background-color: rgba(0,0,0,0.75);
                padding: 20px 20px 0;
                @media(max-width: 479px) {
                  padding-top: 15px;
                }

                h2 {
                  margin-bottom: 5px;
                }

                .form-fields {
                  input[type="checkbox"]:checked + label::before, input[type="checkbox"]:not(:checked) + label::before {
                    content: '';
                    display: none;
                  }
                    //padding: 0;

                    // icons
                    /*.beauty {
                        label:after {
                            content: '\e914';
                        }
                    }

                    .sports {
                        label:after {
                            content: '\e91f';
                        }
                    }

                    .shopping {
                        label:after {
                            content: '\e91d';
                        }
                    }

                    .nightlife {
                        label:after {
                            content: '\e917';
                        }
                    }

                    .gastro {
                        label:after {
                            content: '\e915';
                        }
                    }

                    .special {
                        label:after {
                            content: '\e91e';
                        }
                    }

                    .culture_entertainment,
                    .culture {
                        label:after {
                            content: '\e916';
                        }
                    }*/

                    @media @mq1col {
                        .form-group {
                            //color: #fff;
                            //padding: 0 20px;
                            //margin: 0;
                            label {
                              /*position: relative;
                              text-transform: uppercase;
                              font-size: 16px;
                              line-height: 18px;
                              height: 50px;
                              padding-left: 60px;
                              padding-top: 5px;*/

                                /*.filter-icon {
                                  position: absolute;
                                  display: block;
                                  left: 0;
                                  top: 0;
                                  font-size: 30px;
                                  line-height: 50px;
                                  width: 50px;
                                  text-align: center;
                                  border: 1px solid #fff;
                                  border-radius: 4px;
                                    //font-weight: 500;
                                    span {
                                      line-height: 48px;
                                          display: block;
                                          height: 48px;
                                    }
                                  span:before {
                                    line-height: 48px;
                                  }
                                }*/
                                //&:before {
                                //    height: 20px;
                                //    width: 20px;
                                //    border: 1px solid #000;
                                //    margin-right: 10px;
                                //}
// SEZ
                                /*&:before {
                                    position: absolute;
                                    top: -2px;
                                    left: 32px;
                                    font-family: 'llo' !important;
                                    float: left;
                                    content: '';
                                    width: 28px;
                                    height: 28px;
                                    //background-color: #b2b2b2;
                                    border-radius: @border-radius;
                                    border: 1px solid #fff;
                                }
                                &:after {
                                    font-family: 'llo' !important;
                                    color: #fff;
                                    font-weight: 500;
                                    //content: '';
                                    line-height: 57px;
                                    position: absolute;
                                    font-size: 20px;
                                    top: -16px;
                                    left: 37px;
                                    clear: both;
                                }*/
                            }
                            /*input[type="checkbox"] {
                                &+ label{
                                    padding-left: 20px;
                                }
                              &:not(:checked) {
                                & + label::before {
                                  background-color: transparent;
                                }
                              }
                              &:checked {
                                & + label span {
                                  font-weight: 700;
                                }
                                & + label::before {
                                  background-color: #fff;
                                }
                              }
                            }*/
                        }
                    }
                    //@media @mq2col {
                        .form-group {
                            color: #fff;
                            //padding: 0 20px;
                            width: auto;
                            //min-width: 180px;
                            height: 55px;
                            margin: 20px 0 0;
                            @media(max-width: 479px) {
                              height: 35px;
                              margin-top: 10px;
                            }
                            label {
                                //width: 90px;
                                position: relative;
                                text-transform: uppercase;
                                font-size: 16px;
                                line-height: 18px;
                                height: 50px;
                                padding-left: 60px !important;
                                padding-top: 5px;
                                //float: right;
                                .filter-icon {
                                  position: absolute;
                                  display: block;
                                  left: 0;
                                  top: 0;
                                  font-size: 30px;
                                  line-height: 50px;
                                  width: 50px;
                                  text-align: center;
                                  border: 1px solid #fff;
                                  border-radius: 4px;
                                  @media(max-width: 479px) {
                                    font-size: 18px;
                                    width: 30px;
                                    line-height: 30px;
                                  }
                                    /*font-weight: 500;
                                    position: absolute;
                                    top: 50%;
                                    transform: translateY(-50%);*/
                                    span {
                                      line-height: 48px;
                                          display: block;
                                          height: 48px;
                                          @media(max-width: 479px) {
                                            height: 28px;
                                            line-height: 28px;
                                          }
                                    }
                                    span:before {
                                      line-height: 48px;
                                      @media(max-width: 479px) {
                                        line-height: 28px;
                                      }
                                    }
                                }
                                /*&:before {
                                    position: absolute;
                                    top: 7px;
                                    left: -47px;
                                    font-family: 'llo' !important;
                                    float: left;
                                    content: '';
                                    width: 40px;
                                    height: 40px;
                                    //background-color: #b2b2b2;
                                    border-radius: @border-radius;
                                    border: 1px solid #fff;
                                }
                                &:after {
                                    font-family: 'llo' !important;
                                    color: #fff;
                                    font-weight: 500;
                                    //content: '';
                                    line-height: 57px;
                                    position: absolute;
                                    font-size: 25px;
                                    top: -1px;
                                    left: -40px;
                                    clear: both;
                                }*/
                            }
                            input[type="checkbox"] {
                                &:not(:checked) {
                                    & + label .filter-icon {
                                        //background-color: transparent;
                                    }
                                }
                                &:checked {
                                    & + label .filter-icon {
                                        background: #fff;
                                        color: #2b2b2b;
                                    }
/*                                    & + label::before {
                                        background-color: #fff;
                                    }*/
                                }
                            }
                        }


                    //}
                    @media @mq3col {
                        .form-group {
                            //width: 220px;
                            //margin-bottom: 20px;
                            label {
                                //width: 130px;
                                /*&:before {
                                    top: 3px;
                                    left: -60px;
                                    width: 50px;
                                    height: 50px;
                                }
                                &:after {
                                    font-size: 35px;
                                    top: 0;
                                    left: -52px;
                                }*/
                            }
                        }
                    }
                }
                @media(max-width: 767px) {
                  .form-fields {
                    .row  {
                      margin: 0;
                      display: flex;
                      justify-content: space-between;
                      .col-xs-3 {
                        padding: 0;
                        width: 14%;
                        text-align: center;
                      }
                    }
                  }
                }
            }

            .details-select {
                background-color: rgba(0,0,0,0.75);
                padding: 0 20px;
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 18px;

                .form-fields {

                    .form-group {
                        color: #fff;
                        display: inline-block;
                        margin: 5px 0 0;
                        height:25px;
                        @media(max-width: 479px) {
                          display: block;
                          height: 19px;
                        }
                        label {
                            font-weight: 300;
                            text-transform: uppercase;
                            font-size: 80%;
                            @media(max-width: 479px) {
                              font-size: 65%;
                              margin-bottom: 0;
                              display: block;
                            }
                            &:before {
                                margin-right: 2px;
                                //border: 1px solid #000;
                                top: 1px;
                                height: 12px;
                                width: 12px;
                                @media(max-width: 479px) {
                                  width: 10px;
                                  float: left;
                                  margin-top: 3px;
                                }
                            }
                        }
                        /*@media @mq1col {
                            padding-left: 20px;
                            label {
                                font-weight: 300;
                                font-size: 15px;
                                &:before {
                                    margin-right: 10px;
                                    height: 20px;
                                    width: 20px;
                                }
                            }
                        }
                        @media @mq2col {
                            //padding-left: 0;
                            //width: 50%;
                            label {
                                &:before {
                                    height: 12px;
                                    width: 12px;
                                }
                            }
                        }*/
                        @media @mq3col {
                            //width: 25%;
                        }
                    }

                    h2 {
                      margin-bottom: 15px;
                      @media(max-width: 479px) {
                        margin-bottom: 5px;
                        font-size: 17px;
                      }
                        /*color: #fff;
                        text-transform: uppercase;
                        font-size: 20px;
                        font-weight: 700;
                        position: relative;
                        @media @mq1col {
                            margin: 0;
                        }
                        @media @mq2col {
                            margin-bottom: 20px;
                        }

                        @media @mq2col {
                            padding-left: 20px;
                            &:before {
                                content: '';
                                position: absolute;
                                left: 0;
                                top: -2px;
                                width: 2px;
                                height: 25px;
                                background-color: #000;
                            }
                        }*/
                    }

                    .days, .time, .direction, .fulltext {
                      padding-top: 20px;
                      @media(max-width: 479px) {
                        padding-top: 10px;
                      }
                      @media(min-width: 992px) {
                        padding-top: 20px;
                      }
                        @media @mq2col {
                            //padding-left: 30px;
                        }
                        .form-group {
                            //display: none;
                        }
                    }

                    .days {
                        /*@media @mq2col {
                            width: 220px;
                        }
                        @media @mq3col {
                            width: 290px;
                        }*/

                        .form-group {
                            @media @mq1col {
                                //width: 50%;
                                //padding-left: 45px;
                            }
                        }

                    }

                    .time {
                        /*@media @mq2col {
                            width: 280px;
                        }
                        @media @mq3col {
                            width: 365px;
                        }*/

                        .form-group {
                            //width: auto;
                            @media @mq1col {
                                //width: 100%;
                                //padding-left: 50px;
                            }
                            @media @mq2col {
                                //width: 50%;
                            }
                            @media @mq3col {
                                //width: auto;
                                //min-width: 100px;
                                //margin-right: 7px;
                            }
                        }
                    }
                    .direction {
                        @media @mq3col {
                            //width: 315px;
                        }
                        .form-group {
                            //width: auto;
                            @media @mq1col {
                                //width: 100%;
                                //padding-left: 50px;
                            }
                            @media @mq2col {
                                //width: 50%;
                            }
                            @media @mq3col {
                                //width: auto;
                                //min-width: 60px;
                            }
                        }
                    }
                }
                .button-bottom {
                  padding: 60px 20px 20px;
                  @media(max-width: 479px) {
                    padding-top: 20px;
                  }
                  .btn {
                    background: #222;
                    color: #ccc;
                    border: 1px solid #fff;
                    min-width: 136px;
                    @media(max-width: 479px) {
                      min-width: 0;
                    }
                    width: auto;
                    height: auto;
                    &:hover {
                      background: #191919;
                    }
                    &.btn-primary {
                      background: #fff;
                      color: #2b2b2b;
                      &:hover {
                        background: #ddd;
                      }
                    }
                  }
                }
                .btn {
                    @media @mq1col {
                        //margin-right: 10px;
                        //margin-left: 0;
                    }

                    //float: right;
                    //margin-left: 10px;
                    //@media @mq2col {
                    //    float: right;
                    //}
                }
                @media @mq2col {
                    .button-bottom {
                        //padding-right: 10px;
                        button {
                            //position: relative;
                            //right: 10px;
                        }
                    }
                }
                @media @mq3col {
                    .button-bottom button {
                        //position: relative;
                        //top: -10px;
                    }
                }
            }
        }

    }

    .no-results{
      margin-top: 30px;
        display: none;
    }

    .partners-tile {
        display: block;
        position: relative;
        width: 100%;
        background-clip: padding-box;
        overflow: hidden;
        border-radius: @border-radius;
        margin-bottom: 20px;
        background: right center no-repeat, #000;
        cursor: pointer;
        text-shadow: 2px 2px 4px #000;

        @media @mq1col {
            //height: 415px;
            background-position: center bottom;
            background-size: 100% auto;
        }
        @media @mq2col {
            height: 370px;
        }
        @media @mq3col {
            height: 370px;

        }

        .gradient {
            height: 100%;
            @media @mq1col {
                padding: 10px 20px 170px;
            }
            @media @mq2col {
                padding: 45px 30px 35px 30px;
                background: linear-gradient(90deg, black, black 120px, transparent 45%) center center no-repeat;
                &.darken {
                    background: linear-gradient(90deg, black, black 120px, rgba(0, 0, 0, 0.3)) center center no-repeat;
                }
            }
            @media @mq3col {
                padding: 55px 30px 35px 30px;
                background: linear-gradient(90deg, black, black 220px, transparent 45%) center center no-repeat;
                &.darken {
                    background: linear-gradient(90deg, black, black 220px, rgba(0, 0, 0, 0.3)) center center no-repeat;
                }
            }
        }

        .icon {
            font-size: 35px;
        }

        .text-label {
            font-size: 16px;
            line-height: 16px;
            font-weight: 500;
            border: 1px solid #fff;
            padding: 4px 10px 5px;
            top: -8px;
            margin-right: 10px;
        }

        .category {
            position: relative;
            font-size: 18px;
            line-height: 18px;
            font-weight: 500;
            font-style: italic;
            top: -6px;
        }

        .partners-heading {
            font-size: 30px;
            text-transform: uppercase;
            font-weight: 500;
            margin-bottom: 10px;
            @media @mq1col {
                line-height: 100%;
            }
        }
        .partners-subheading {
            font-size: 15px;
            text-transform: uppercase;
            font-weight: 500;
            margin-bottom: 20px;
        }

        .partners-description {
            font-size: 15px;

        }
    }

    // Carousel
    .slick-slide {
        margin: 0 30px;
    }
    .slick-list {
        margin: 0 -30px;
    }

    .partners-tile-pagination {
        position: relative;
        height: 40px;
        margin-top: 20px;

        .icon {
            font-size: 25px;
            line-height: 25px;
            cursor: pointer;
            color: #4e4e4e;

            &:hover {
                color: #fff;
            }
        }

        .pagination-left {
            position: absolute;
            padding-left: 10px;
            left: 0;
            top: 13px;
            bottom: inherit;
        }

        .pagination-right {
            position: absolute;
            right: 0;
            top: 13px;
            bottom: inherit;
        }

        .pagination-item-wrapper {
            position: absolute;
            width: auto;
            height: 30px;
            left: 50%;
            transform: translateX(-50%);
        }
        .pagination-item {
            display: inline-block;
            text-align: center;
            font-size: 1rem;
            line-height: 24px;
            width: 25px;
            height: 25px;
            background-color: #505050;
            color: #858585;
            cursor: pointer;
            border-radius: 50%;
            position: relative;
            top: 2px;
            margin: 0 3px;

            &:hover {
                color: #fff;
            }

            &.active {
                background-color: #fff;
                color: #333;
            }
            &.hidden {
                display: none;
            }
        }
    }
}
@keyframes is_active{
    0% {opacity: 1}
    50% {opacity: 0.3}
    100% {opacity: 1}
}
