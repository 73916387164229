[data-page="blog"] {
  & > header .toggle-nav {
    color: #2b2b2b;
  }
/*  background: #fff url('/img/LLOweb_muc_1920x1113_cut_white.jpg') left 50px no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  //background-size: cover;
  @media @mq2col {
      background: #fff url('/img/LLOweb_muc_1920x1113_cut_white.jpg') left 50px no-repeat;
      -webkit-background-size: cover;
      background-size: cover;
      background-attachment: fixed;
  }*/
  .headline {
    color: #2b2b2b;
  }
& > header .navbar.navbar-default {
  background: #000;
  .navbar-nav>li>a, .toggle-nav, .navbar-brand {
    color: #fff;
  }
  .toggle-nav.active, .navbar-nav>li>a:hover {
    color: #eee;
  }
}

.panel-body.copytext img {
  height: 207px;
  @media(max-width: 1199px) {
    height: 167px;
  }
  @media(max-width: 991px) {
    height: 272px;
  }
  @media(max-width: 767px) {
    height: auto;
  }
}

.blog-section p {
  margin: 0;
}

    .partner-details-box {
      margin-bottom: 30px;
      .light {
        background: rgba(255,255,255,0.75);
        color: #2b2b2b;
        a {
          color: #2b2b2b;
        }
      }
      &.full-height {
        height: 300px;
        @media(min-width: 992px) {
          position: absolute;
          left: 15px;
          right: 15px;
          top: 0;
          bottom: 0;
          height: auto;
        }
      }
      .partner-details-box-complete {
        border-radius: 6px;
        border-top-left-radius: 27px;
        border-bottom-right-radius: 27px;
        -webkit-border-radius: 6px;
        -webkit-border-top-left-radius: 27px;
        -webkit-border-bottom-right-radius: 27px;
        padding: 30px;
        position: relative;
        overflow: hidden;
      }
      .partner-details-box-top {
        border-radius: 0;
        border-top-left-radius: 27px;
        border-top-right-radius: 6px;
        padding: 30px;
        position: relative;
      }
      .partner-details-box-bottom {
        border-radius: 0;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 27px;
        padding: 30px;
        position: relative;
      }
      h1 {
        font-size: 60px;
        font-weight: 200;
        margin-top: 0;
      }
      h2 {
        font-size: 22px;
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: 4.5px;
        margin-top: 0;
        margin-bottom: 21px;
      }
      &.partner-details-teaser {
        h1 {
          margin-top: 10.5px;
          margin-bottom: 0;
          //padding-right: 100px;
          @media(max-width: 767px) {
            font-size: 24px;
            padding-right: 48px;
          }
        }
        h2 {
          margin-top: 0;
          margin-bottom: 0;
          //padding-right: 100px;
        }
        h4 {
          font-size: 24px;
          margin-bottom: 21px;
        }
        ul {
          padding-left: 20px;
        }
        .partner-details-icon {
          position: absolute;
          right: 30px;
          top: 30px;
          font-size: 90px;
        }
        .partner-details-box-middle img {
          display: block;
        }
        .partner-details-box-bottom {
          border-bottom-right-radius: 32px;
        }
      }
      &.partner-details-contact {
        table * {
          font-size: 14px;
          vertical-align: top;
        }
        .row > div:first-child {
          margin-bottom: 30px;
        }
      }
    }

    //background: linear-gradient(transparent 0, transparent 500px, #000 1000px), url('/img/partners_background_small.jpg') 10% top / 300% auto no-repeat;
/*    background: url('/img/partners_background_small.jpg') 10% top no-repeat #000;
    background-size: 100% auto;
    background-attachment: fixed;
    @media @mq2col {
        background: url('/img/partners_background.jpg') 10% top no-repeat #000;
        background-size: 100% auto;
        background-attachment: fixed;
    }*/
    .blog-header{
        border-radius: 10px;
        margin-bottom: 20px;

        @media @mq1col {
            width: 300px;
            height: 118px;
        }
        @media @mq2col {
            width: 620px;
            height: 244px;
        }
        @media @mq3col {
            width: 940px;
            height: 370px;
        }

    }
    .blog-image{
        border-radius: 10px;
        float: left;
        margin: 5px 12px 7px 0;
        width: 300px;
    }
    .blog-related{
        width: 250px;
        height: 99px;
        margin-bottom: 7px;
    }
    h2{
        margin-bottom: 20px;
    }
    .related-item{
        .more{

        }
    }
    @media(min-width: 992px) {
      .image-left {
        float: left;
        margin-right: 10px;
      }
      .image-right {
        float: right;
        margin-left: 10px;
      }
    }
}
.teaser.related{
    position: relative;
    .slick-slider{
        height: 600px;
        background-color: white;
    }
    .slick-dots{
        top:auto;
        bottom: 0;
    }
    .slick-arrow{
        bottom: 25px;
    }
    h2 {
        height: 5rem;
        padding: 0 25px;
        background-color: #000;
        color: @text-color-bright;
        font-size: 1.25rem;
        line-height: 5rem;
        margin-bottom: 0;
    }

    h3 {
        position: relative;
        padding-right: .5rem;
        padding-left: 0;
        margin-bottom: 5px;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.25;
        text-transform: uppercase;
    }

    a {
        display: block;
        position: relative;
        padding: 15px 25px 10px;
        background-color: #fff;
        color: @text-color-dark;
    }
    li+li a{

        &:before {
            content: '';
            position: absolute;
            left: 25px;
            top:0;
            width: ~'calc(100% - 25px * 2)';
            height: 1px;
            background-color: rgba(0, 0, 0, .1);
        }
    }
    .more{
        font-size: .9375rem;
        &:after {
            content: "\f0da";
            font-family: 'llo' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;

            /* Better Font Rendering =========== */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;

            position: relative;
            top: 1px;
            left: 4px;
        }
    }
}
