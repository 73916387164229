[data-page="howto"] {
    //background: url('/img/faq_background.jpg') center top no-repeat #000;
    //background-size: cover;
    //background-attachment: fixed;

    //background: linear-gradient(transparent 0, transparent 500px, #000 1000px), url('/img/LLOnew_Muc_sw_dark.png') 15% top / 400% auto no-repeat;
    /*background: #fff url('/img/LLOweb_muc_1920x1113_cut.jpg') left 50px no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
    //background-size: cover;
    @media @mq2col {
        background: #fff url('/img/LLOweb_muc_1920x1113_cut.jpg') left 50px no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
        background-attachment: fixed;
    }*/

    color: #fff;

    h3 {
      font-size: 40px;
      font-weight: 300;
    }

    .learn-more {
        display:inline-block;
        margin-top: 10px;

        .icon {
            position: relative;
            top: 1px;
            margin-right: 5px;
        }

    }


    .slider {
        margin-bottom: 70px;
    }

    .numbered-headline{
        .icon-Life-less-karte:before {
            font-size: 3rem;
            position: relative;
            top: 13px;
        }

        .icon-Partner-auswaehlen:before {
            font-size: 2.4rem;
            position: relative;
            top: 13px;
        }

        .icon-vorteile:before {
            font-size: 2.2rem;
            position: relative;
            top: 5px;
        }
    }
}
