[data-page="form_template"] {
    background-color: #222;
}

form {
    width: 100%;
}
.error-label {
    position: relative;
    padding-top: 5px;
    color: @error-red;
    font-size: smaller;
    line-height: 1em;
    width: 100%;
    // word-break: break-all;
    display: block;
}
.form-group {
    //margin-bottom: 10px;
    //float: left;
    position: relative;
    width: 100%;

    &.error {

        input, select, textarea {
            //background: linear-gradient(90deg, @error-red 10px, #fff 10px);
            //padding-left: 2rem;
            //padding-right: 0;
            //border: 1px solid #000;

        }
    }
    .error-label {
        display: none;
    }
    &.error{
        .error-label {
            display: inline;
        }
    }

    label {
        .extra-text {
            font-size: 14px;
            font-weight: 300;
        }
    }

    span.locked {
        // field is locked, so it's not an input field
        display:block;
        color:@text-color-bright;
        background-color:@gray;
        background-clip: padding-box;
        border-top-left-radius: @border-radius;
        border-bottom-right-radius: @border-radius;
        border: 1px solid #000;
        padding: 0 1rem 0 1rem;
        line-height: 38px;
        font-size: 16px;
    }
}

.combined-errors {
    .error-label {
        position: relative;
        padding-top: 5px;
        color: @error-red;
        font-size: smaller;
        line-height: 1em;
        width: 100%;
        // word-break: break-all;
        display: block;
    }
}

.description-text {
    //font-size: 14px;
    margin-top: 15px;
}

input, select, textarea {
    width: 100%;
    display: block;
    appearance: none;
    background-color: #fff;
    &::selection {
        background: @gray;
        color: @text-color-bright;
    }

    height: 38px;
    line-height: 38px;
    font-size: 16px;
    font-weight: @font-medium;

    background-clip: padding-box;
    border-top-left-radius: @border-radius;
    border-bottom-right-radius: @border-radius;
    border: 1px solid #000;

    padding: 0 1rem 0 1rem;


    position: relative;
    &:focus {
        background: linear-gradient(90deg, #949494 10px, #fff 10px);
        padding-left: 1rem;
        padding-right: 1rem;
        border: 1px solid #000;
    }
}

select {
    background-color: #fff;
    background-image: url(../img/select-dropdown-arrow-small.png);
    background-position: right;
    background-repeat: no-repeat;
    padding-right: 50px;

    &:focus {
        background: url(../img/select-dropdown-arrow-small.png) no-repeat right, linear-gradient(90deg, #949494 10px, #fff 10px);
        padding-left: 1rem;
        padding-right: 42px;
        border: 1px solid #000;
    }

    @media @mq3col {
        background-image: url(../img/select-dropdown-arrow.png);
        padding-right: 65px;

        &:focus {
            background: url(../img/select-dropdown-arrow.png) no-repeat right, linear-gradient(90deg, #949494 10px, #fff 10px);
            padding-left: 1rem;
            padding-right: 42px;
            border: 1px solid #000;
        }
    }

    option {
        background-color: #fff;
        font-weight: 500;
        color: #000;
    }

    &.has-placeholder {
        color: @gray;
        option {
            //color: #000;
        }
    }
}

textarea {
    height: auto;
    line-height: 1.2rem;
    padding: 1rem;
    border-bottom-right-radius: inherit;
    text-transform: none;
}

input[type="radio"] {
    display: none;
}

input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    border-radius: 0;
    font-size: 1em;

    width: auto;
    float: left;
    margin-right: .75em;
    background: transparent;

    &:checked, &:not(:checked) {
        background: transparent;
        position: relative;
        display: none;
        margin: 0;
        padding: 0;

        & + label::before {
          display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  content: "\f096";
  color: #000;
/*/            content: ' ';
            display: inline-block;
            width: 17px;
            height: 17px;
            position: relative;
            top: 4px;
            border: 2px solid #fff;
            background: #000;
            background-size: cover;*/
            margin-right: 1em;
        }
    }

    &:checked {
        & + label::before {
                content: "\f14a";
            /*box-sizing: border-box;
            content: '';
            display: inline-block;
            width: 17px;
            height: 17px;
            position: relative;
            top: 4px;
            border: 2px solid #fff;
            margin-right: 1em;
            background: #000 url(../img/checkbox-white.svg) center center no-repeat;
            background-size: cover;*/
        }
    }

    & + label {
        cursor: pointer;
        margin-left: 0;
        padding-left: 0;
        text-transform: none;
    }
}

.inverted {
    input[type="checkbox"] {
        &:not(:checked) {
            //background: #fff;

            & + label::before {
              color: #fff;
                /*border: 2px solid #000;
                background: #fff;
                margin-right: 1em;*/
            }
        }
        &:checked {
            & + label::before {
              color: #fff;
                /*background: #fff url(../img/checkbox-black.svg) center center no-repeat;
                background-size: cover;*/
            }
        }
    }
}

// IE10 fix
select::-ms-expand {
    display: none;
}

label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    p {
        font-size: 1rem;
        font-weight: bold;
        color: #fff;
    }
}

.row {
    label {
        padding-left: 20px;
    }
}
