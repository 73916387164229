[data-page="user"] {
  .table {
    font-size: 15px;
    &>thead {
      &>tr {
        &>th {
          border-bottom: 1px solid rgba(255,255,255,.75);
        }
      }
    }
    &>tbody, &>thead, &>tfoot {
      &>tr {
        &>th, &>td {
          border-top: 1px solid rgba(255,255,255,.25);
        }
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  #cards-navigation, #orders-navigation {
    .pagination {
      display: block;
      text-align: center;
      & > li {
        display: inline-block;
        margin: 0 5px;
        & > a, & > span {
          border-radius: 6px;
          background: rgba(0, 0, 0, 0.75);
          border: 0;
          font-weight: 500;
          padding: 0;
          width: 34px;
          height: 28px;
          line-height: 28px;
          color: #fff;
        }
        &.active {
          & > a, & > a:focus, & > a:hover, & > span, & > span:focus, & > span:hover {
            background: #fff;
            color: #000;
          }
        }
        &.disabled > a, &.disabled > span {
          color: #666;
          visibility: hidden;
        }
      }
    }
  }
}
