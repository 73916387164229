[data-page="kontakt"] {

  /*background: #fff url('/img/LLOweb_muc_1920x1113_cut.jpg') left 50px no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  //background-size: cover;
  @media @mq2col {
      background: #fff url('/img/LLOweb_muc_1920x1113_cut.jpg') left 50px no-repeat;
      -webkit-background-size: cover;
      background-size: cover;
      background-attachment: fixed;
  }*/
.partner-details-box {
  margin-bottom: 30px;
  .dark {
    background: rgba(0,0,0,0.75);
  }
  &.full-height {
    height: 300px;
    @media(min-width: 992px) {
      position: absolute;
      left: 15px;
      right: 15px;
      top: 0;
      bottom: 0;
      height: auto;
    }
  }
  .partner-details-box-complete {
    border-radius: 6px;
    border-top-left-radius: 27px;
    border-bottom-right-radius: 27px;
    -webkit-border-radius: 6px;
    -webkit-border-top-left-radius: 27px;
    -webkit-border-bottom-right-radius: 27px;
    padding: 30px;
    position: relative;
    overflow: hidden;
  }
  .partner-details-box-top {
    border-radius: 0;
    border-top-left-radius: 27px;
    border-top-right-radius: 6px;
    padding: 30px;
    position: relative;
  }
  .partner-details-box-bottom {
    border-radius: 0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 27px;
    padding: 30px;
    position: relative;
  }
  h1 {
    font-size: 60px;
    font-weight: 200;
    margin-top: 0;
  }
  h2 {
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 4.5px;
    margin-top: 0;
    margin-bottom: 21px;
  }
  &.partner-details-teaser {
    h1 {
      margin-top: 10.5px;
      margin-bottom: 0;
      padding-right: 100px;
    }
    h2 {
      margin-top: 0;
      margin-bottom: 0;
      padding-right: 100px;
    }
    h4 {
      font-size: 24px;
      margin-bottom: 21px;
    }
    ul {
      padding-left: 20px;
    }
    .partner-details-icon {
      position: absolute;
      right: 30px;
      top: 30px;
      font-size: 90px;
    }
    .partner-details-box-middle img {
      display: block;
    }
  }
  &.partner-details-contact {
    table * {
      font-size: 14px;
      vertical-align: top;
    }
    .row > div:first-child {
      margin-bottom: 30px;
    }
  }
}
.btn {
  background: #222;
  color: #ccc;
  border: 0;
  min-width: 136px;
  width: auto;
  height: auto;
  &:hover {
    background: #191919;
  }
  &.btn-primary {
    background: #fff;
    color: #2b2b2b;
    &:hover {
      background: #ddd;
    }
  }
}
label {
  font-size: 14px;
  font-weight: 300;
  padding: 0;
  text-transform: uppercase;
}
}
