[data-page="order"] {
  .cart-box {
    font-size: 18px;
    td {
      padding: 10px 0;
    }
  }
  .llo-card-wrapper img {
    max-width: 100%;
  }
  /*h1 {
    font-size: 48px;
    &,* {
      font-weight: 200;
    }
  }*/
      .product-sales-banner {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 18px;
        color: white;
        background: #d01b30;
        font-weight: bold;
        padding: 1px 5px;
      }
  h3 {
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 4.5px;
    margin-top: 0;
    margin-bottom: 21px;
  }
  .card-name-wrapper {
    line-height: 1;
  }
  .card-name {
    font-size: 40px;
  }
  .cost-line {
    position: relative;
  }
  .card-cost {
    font-size: 40px;
    font-weight: 400;
  }
        .card-old-cost {
          font-weight: 400;
          font-size: 20px;
          margin: 0;
          position: absolute;
          bottom: 52px;
          right: 0;
          font-size: 24px;
          color: #d01b30;
          text-decoration: line-through;
        }
  .card-details-wrapper {
    &, * {
      //font-size: 16px;
    }
  }
  .partner-details-box {
    margin-bottom: 30px;
    .dark {
      background: rgba(0,0,0,0.75);
    }
    &.full-height {
      height: 300px;
      @media(min-width: 992px) {
        position: absolute;
        left: 15px;
        right: 15px;
        top: 0;
        bottom: 0;
        height: auto;
      }
    }
    .partner-details-box-complete {
      border-radius: 6px;
      border-top-left-radius: 27px;
      border-bottom-right-radius: 27px;
      -webkit-border-radius: 6px;
      -webkit-border-top-left-radius: 27px;
      -webkit-border-bottom-right-radius: 27px;
      padding: 30px;
      position: relative;
      overflow: hidden;
    }
    .partner-details-box-top {
      border-radius: 0;
      border-top-left-radius: 27px;
      border-top-right-radius: 6px;
      padding: 30px;
      position: relative;
    }
    .partner-details-box-bottom {
      border-radius: 0;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 27px;
      padding: 30px;
      position: relative;
    }
    h1 {
      font-size: 60px;
      font-weight: 200;
      margin-top: 0;
    }
    h2 {
      font-size: 22px;
      text-transform: uppercase;
      font-weight: 400;
      letter-spacing: 4.5px;
      margin-top: 0;
      margin-bottom: 21px;
    }
    &.partner-details-teaser {
      h1 {
        margin-top: 10.5px;
        margin-bottom: 0;
        padding-right: 100px;
      }
      h2 {
        margin-top: 0;
        margin-bottom: 0;
        padding-right: 100px;
      }
      h4 {
        font-size: 24px;
        margin-bottom: 21px;
      }
      ul {
        padding-left: 20px;
      }
      .partner-details-icon {
        position: absolute;
        right: 30px;
        top: 30px;
        font-size: 90px;
      }
      .partner-details-box-middle img {
        display: block;
      }
    }
    &.partner-details-contact {
      table * {
        font-size: 14px;
        vertical-align: top;
      }
      .row > div:first-child {
        margin-bottom: 30px;
      }
    }
  }
  label {
      font-size: 14px;
      font-weight: 300;
      padding: 0;
      text-transform: uppercase;
  }
  select option {
    background-color: #000;
    color: #fff;
  }
  .btn:not(.btn-ci-dynamic) {
    background: #222;
    color: #ccc;
    border: 0;
    min-width: 136px;
    width: auto;
    height: auto;
    &.btn-outline {
      border: 1px solid #fff;
    }
    &:hover {
      background: #191919;
    }
    &.btn-primary {
      background: #fff;
      color: #2b2b2b;
      &:hover {
        background: #ddd;
      }
    }
  }
    section {
        overflow: visible;
    }

    //background: linear-gradient(transparent 0, transparent 500px, #000 1000px), url('/img/partners_background_small.jpg') 10% top / 300% auto no-repeat;
    /*background: #fff url('/img/LLOweb_muc_1920x1113_cut.jpg') left 50px no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
    //background-size: cover;
    @media @mq2col {
        background: #fff url('/img/LLOweb_muc_1920x1113_cut.jpg') left 50px no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
        background-attachment: fixed;
    }*/

    .entry-form {
        .sticky-card-alert {
            z-index: 100;
            position: fixed;
            top: 50%;
            left: 0;
            //height: 50px;
            width: 100vw;
            background-color: #fff;
            overflow: hidden;
            box-shadow: 0px 17px 55px -8px rgba(0, 0, 0, 0.9);

            opacity: 0;
            transform: translateX(100%);
            transition: transform .3s, opacity .3s ease-out;

            &.active {
                opacity: 1;
                transform: translateX(0%);
            }

            img {
                height: 50px;
                width: auto;
                padding: 10px 15px;
                float: left;
            }
            .description {
                //height: 50px;
                width: 60%;
                float: left;
                font-size: 14px;
                line-height: 24px;
                display: inline-block;
                padding: 6px 0;
                span {
                    color: #000;
                }
                .card-name {
                    font-weight: 600;
                }
                .card-details {

                }
            }
            .close-icon {
                float: right;
                width: 28px;
                height: 28px;
                background-color: #000;
                position: relative;
                top: 11px;
                right: 11px;
                text-align: center;
                .icon {
                    width: 100%;
                    margin: 0;
                    font-size: 25px;
                    line-height: 25px;
                    font-weight: 300;
                }
            }
        }

        .form-group label {
            padding: 0;
        }
        p {
            //font-size: 14px;
        }
        .description-text{
            //font-size: 16px;
        }
    }

    @media @mq2col {
        .main-left-column {
            //padding-right: 20px;
        }

        .main-right-column {
            //padding-left: 20px;
        }
    }

    @media @mq3col {
        .main-left-column {
            //padding-right: 35px;
        }

        .main-right-column {
            //padding-left: 35px;
        }
    }

    .referred-container{
        margin-top: 10px;
        &:hover {
            cursor: pointer;
        }
    }

    .referred-dropdown-form {
        //max-height: 174px;
        overflow: hidden;
        opacity: 1;
        transition: max-height, opacity .3s ease;

        &.hidden-animated {
            max-height: 0;
            opacity: 0;
        }

        .form-group {
            margin-bottom: 10px;
        }
    }

    .referred-dropdown-header {
        p {
            margin: 0;
        }
        .icon-right {
            //transition: transform .3s ease;
            display: inline-block;

            &.active {
                //transform: rotateZ(-180deg);
            }
        }
    }

    .llo-card-wrapper {
        position: relative;
        width: 100%;

        .llo-card-ribbon {
            position: relative;
        }

        @media @mq1col {
            .llo-card {
                width: 300px;
            }

            .llo-card-ribbon {
                width: 316px;
                left: -15px;
                top: -15px;
            }

        }

        @media @mq2col {
            .llo-card {
                width: 300px;
            }

            .llo-card-ribbon {
                width: 317px;
                left: -15px;
                top: -15px;
            }

        }

        @media @mq3col {
            .llo-card {
                width: 401px;
            }

            .llo-card-ribbon {
                width: 423px;
                left: -20px;
                top: -20px;
            }
        }
    }

    .back-to-card-choice > div {
        padding: 0 0 30px 0;
    }

    .numbered-headline {
        margin-bottom: 0.2rem;

        .number-label {
            position: relative;
            top: -4px;
        }
    }

    .registration {

        @media @mq2col {

            .login {
                padding: 0;
            }

            .left-column {
                padding-right: 40px;
                position: relative;
                height: auto;

                &.separation-border:after {
                    content: '';
                    position: absolute;
                    right: 0;

                    //top = padding of column
                    top: 10px;
                    width: 1px;
                    height: 80%;
                    background-color: #fff;
                }
            }

            .right-column {
                padding-left: 40px;
                .row {
                    div {
                        min-height: 116px;
                        //overflow: hidden;
                        //text-overflow: ellipsis;
                    }
                    + .row {
                        height: auto;
                    }
                }
            }
        }
    }

    .order-step.active {

        .step-body {
            height: auto;
        }
        .edit-step{
            display: none !important;
        }
    }

    .order-step {
        opacity: 1;
        /*border-top: 1px solid #fff;
        padding: 20px 0;*/

        .summary {
            padding: 0;
            display: none;
            @media @mq2col {
                padding-left: 51px;
            }
            &.checked {
                display: block;
            }
        }
        &:first-child {
            border-top: none;
        }
        &.blur {
            opacity: 0.5;
        }
    }

    .step-header {
        padding: 0;
        position: relative;

        .numbered-headline {
            height: 40px;
            div {
                float: left;
            }
        }

        div.headline {
            padding-left: 11px;
            position: relative;
            top: -13px;

            h2 {
                max-width: 240px;
                @media @mq2col {
                    max-width: 500px;
                }
            }

            &.checked {
                h2 {
                    position: relative;
                    &:after {
                        content: '\e90d';
                        font-family: 'llo';
                        font-size: 30px;
                        position: absolute;
                        right: -40px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }

        .extra-text {
            font-size: 16px;
            font-weight: 300;
            line-height: 18px;
            display: block;
            float: none;
            position: relative;
            top: -1px;
            @media @mq2col {
                padding-left: 51px;
            }
        }

        &:hover {
            cursor: pointer;
        }
        .edit-step {
            text-align: right;
            position: absolute;
            bottom: 0;
            right: 0;
            display: none;
            @media @mq2col {
                right: 55px;
            }
            &.checked {
                display: block;
            }
        }
    }

    .step-body {
        clear: both;
        overflow: hidden;
        height: 0;
        width: 100%;
        @media @mq2col {
            //padding: 0 45px;
        }
        &.login {
            padding: 0;
        }
        .prev-step {
            text-transform: uppercase;
        }
    }

    .order-form-nav-buttons {
        position: relative;
        margin-top: 1rem;
        /*&:before {
            content: '';
            width: calc(~"100% - 20px");
            transform: translateX(10px);
            height: 1px;
            background-color: #fff;
            position: absolute;
            top: 0;
            left: 0;
        }*/
    }
    .mw1{
        width: 100%;
        display: inline-block;
        margin-top: 10px;

        @media @mq2col {
            width: 120px;
            margin-top: 0;
        }

        & + span {
            font-size: 12px;
            display: inline-block;

            @media @mq2col {
                font-size: 1em;
            }
        }
    }
    [data-immediately-hint] {
        display: none;
        &.show{
            display: inline;
        }
    }

    .registration.order-step.active #response-user{
        display: none;
    }
    .advanced-form {
      .order-step {
        margin-top: 40px;
        &:first-child {
          margin-top: 0;
        }
      }
    }
    .no-top {
      border-top: 0;
    }
    .form-group.checkbox label {
      padding-left: 1px;
      &:before {
        width: 12px;
        color: #fff;
        margin-right: 0.5rem;
      }
    }
    #cc-info, #bankaccount-info {
      input { background: #000; }
    }
}
