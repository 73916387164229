.teaser {
    border-radius: @border-radius;
    overflow: hidden;
}

// SLICK
//=======

// numbered indicators

.slick-dots {
    height: 71px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 100%;
    margin: 0;
    border-bottom-left-radius: @border-radius;
    border-bottom-right-radius: @border-radius;
    width: 220px;
    text-align: center;

    li {
        cursor: pointer;
        display: inline-block;
        border-radius: 1rem;
        background-color: #b2b2b2;
        text-align: center;
        position: relative;
        top: 50%;
        transform: translateY(-50%);

        width: 25px;
        height: 25px;
        line-height: 24px;
        margin-right: 10px;

        &.hidden{
            display: none;
        }

        &+li{
        }
        button {
            color: #fff;
            font-size: 1rem;
        }

        &.slick-active {
            background-color: #000;
        }
    }
}

// Next/prev arrows
.slick-arrow {
    position: absolute;
    bottom: -45px;
    font-family: 'llo';
    font-size: 20px;
    color: #000;

    &:hover {
        color: #949494;
    }

    &.slick-prev {
        left: 20px;
        &:before {
            content: '\f053';
        }
    }

    &.slick-next {
        right: 20px;
        &:before {
            content: '\f054';
        }
    }
}

// fullscreen vertical slider for Desktop only

.fullscreen-slider-wrapper {

    .slider-nav, .slider-scroll-down {
        display: none;
    }

    .slider-fullscreen-vertical {
        .slide {
            margin-bottom: 70px;
        }
    }

    @media @mq3col {

        /*.page-headline {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
        }*/
        .slider-nav {
            display: block;
            position: fixed;
            right: 50px;
            top: 45%;
            z-index: 9;

            li {
                text-align: right;
                margin: 10px 0;

                a {
                    font-size: .875rem;
                    color: #7e7e7e;

                    span {
                        opacity: 0;
                        -webkit-transition: opacity .2s ease;
                        transition: opacity .2s ease;
                    }

                    &:after {
                        display: inline-block;
                        content: '';
                        width: 11px;
                        height: 11px;
                        margin-left: 25px;
                        background-color: #fff;
                        border-radius: 10px;
                        border: 3px solid #000;
                        -webkit-transition: box-shadow .2s ease;
                        transition: box-shadow .2s ease;
                    }
                }
            }

            li.active {
                a {
                    color: #fff;
                    text-shadow: 1px 1px #000;

                    &:after {
                        box-shadow: 0 0 3px #fff;
                        -webkit-transition: box-shadow .2s ease;
                        transition: box-shadow .2s ease;
                    }
                }
            }

            &:hover {
                li {
                    a {
                        span {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        .slider-scroll-down {
            display: block;
            position: fixed;
            width: 100px;
            height: 25px;
            bottom: 25px;
            left: 50%;
            margin-left: -50px;
            color: #fff;
            text-align: center;
            line-height: 25px;
            font-size: 1.5rem;
        }

        .slider-fullscreen-vertical {

            .slide {
                min-height: 100vh;
                margin-bottom: 0;
            }

            .slide:first-child {
                padding-top: 0;
            }
        }
    }
}