table.orders {
  th {
    font-weight:bold;
    padding-right:0.5em;
    text-align:left;
  }
  tr.separator {
    td, th {
      padding-top:0.5em;
    }
  }
}