[data-page="index"] {
  .html-mobile-background {
    display: none;
  }
  background: #000;
  height: 100% !important;
  .background-layer {
    background: #000;
  }
/*
  background: url('../img/background-tile.jpg') center top repeat #000;
*/
  /*main {
    background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOTIwIDEwODAiPjxkZWZzPjxyYWRpYWxHcmFkaWVudCBpZD0ibWFzay1ncmFkaWVudCI+PHN0b3Agb2Zmc2V0PSIuNSIgc3RvcC1jb2xvcj0iI2ZmZiIgc3RvcC1vcGFjaXR5PSIxIiAvPjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iI2ZmZiIgc3RvcC1vcGFjaXR5PSIwIiAvPjwvcmFkaWFsR3JhZGllbnQ+PHJhZGlhbEdyYWRpZW50IGlkPSJmaWxsIj48c3RvcCBvZmZzZXQ9IjAiIHN0b3AtY29sb3I9IiM1NjU2NTYiIC8+PHN0b3Agb2Zmc2V0PSIuNSIgc3RvcC1jb2xvcj0iIzJhMmEyYSIgLz48L3JhZGlhbEdyYWRpZW50PjxtYXNrIGlkPSJtYXNrIiBtYXNrQ29udGVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCI+PHJlY3Qgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNtYXNrLWdyYWRpZW50KSIgLz48L21hc2s+PC9kZWZzPjxyZWN0IHdpZHRoPSIxOTIwIiBoZWlnaHQ9IjEwODAiIGZpbGw9InVybCgjZmlsbCkiIG1hc2s9InVybCgjbWFzaykiIC8+PC9zdmc+') center -150px no-repeat;
    background-size: 2880px auto;
  }*/

  /*h1, .subtitle {
    text-align: center;
    color: @text-color-bright;
  }*/

  .partner-details-box {
    margin-bottom: 30px;
    .dark {
      background: rgba(0,0,0,0.75);
      color: #fff;
    }
    &.full-height {
      height: 300px;
      @media(min-width: 992px) {
        position: absolute;
        left: 15px;
        right: 15px;
        top: 0;
        bottom: 0;
        height: auto;
      }
    }
    .partner-details-box-complete {
      border-radius: 6px;
      border-top-left-radius: 27px;
      border-bottom-right-radius: 27px;
      -webkit-border-radius: 6px;
      -webkit-border-top-left-radius: 27px;
      -webkit-border-bottom-right-radius: 27px;
      padding: 30px;
      position: relative;
      overflow: hidden;
    }
    .partner-details-box-top {
      border-radius: 0;
      border-top-left-radius: 27px;
      border-top-right-radius: 6px;
      padding: 30px;
      position: relative;
    }
    .partner-details-box-bottom {
      border-radius: 0;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 27px;
      padding: 30px;
      position: relative;
    }
    h1 {
      font-size: 60px;
      font-weight: 200;
      margin-top: 0;
    }
    h2 {
      font-size: 22px;
      text-transform: uppercase;
      font-weight: 400;
      letter-spacing: 4.5px;
      margin-top: 0;
      margin-bottom: 21px;
    }
  }

  main {
    height: 100%;
  }

  #top-section {
    position: relative;
    #logo-index-top {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 650px;
      height: 180px;
      margin-left: -325px;
      margin-top: -170px;
      @media (max-width: 767px) {
        width: 80%;
        height: auto;
        left: 10%;
        margin-left: 0;
        top: auto;
        bottom: 50%;
        margin-top: -40px;
      }
      @media (max-height: 480px) {
        width: auto;
        max-width: 80%;
        height: 30%;
        top: 30%;
        left: 50%;
        margin-left: 0;
        transform: translate3d(-50%,0,0);
      }
    }
    #start-index-top {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 160px;
      height: 160px;
      margin-left: -80px;
      margin-top: 208px;
      text-align: center;
      color: white;
      animation: bounce 2s infinite;
      cursor: pointer;
      @media (max-width: 767px) {
        top: 50%;
          margin-top: 80px;
      }
      @media (max-height: 480px) {
        top: 30%;
          margin-top: 100px;
      }
      span {
        position: absolute;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  margin-top: -12px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  box-sizing: border-box;
      }
    }
    .btn-index {
      height: 42px;
      line-height: 42px;
      padding: 0;
      background: transparent;
      border: 1px solid #fff;
      @media (max-width: 767px) {
/*        top: auto;
        bottom: 10%;*/
        margin-top: 120px;

      }
      &:hover {
        background: #fff;
        color: #2b2b2b;
      }
    }
    @-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
    /*@keyframes bounce {
      0%, 20%, 50%, 80%, 100% {
        transform:translateY(0);
      }
      30% {
        transform:translateY(0);
      }
      50% {
        transform:translateY(-40%);
      }
      60% {
        transform:translateY(0);
      }
      75% {
        transform:translateY(-15%);
      }
      95% {
        transform:translateY(0);
      }
    }*/
  }

  #bottom-section {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    .headline {
      padding-top: 50%;
      @media (max-width: 767px) {
        padding-top: 21px;
      }
      /*position: absolute;
          top: 30%;
          width: 100%;
          /*      margin-top: 315px;*/
    }
    #newsletter-signup-form {
      padding-bottom: 100vh;
      .input-group-addon, input, .input-group-btn .btn, .input-group-btn .btn:hover, .input-group-btn .btn:focus {
        border-bottom: 0;
      }
      .input-group {
        border-bottom: 1px solid #fff;
      }
    }
  }

  #index-image {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000 url('/img/LLO_Lady.jpg') center center no-repeat;
    z-index: 101;
  }
  #section-4 {
    position: relative;
    height: 240px;
    box-sizing: border-box;
    background-position: 50% 50%;
    background-image: url('/img/LLO_Lady.jpg');
    background-size: auto 100%;
    @media(min-width: 480px) {
      height: 160px;
    }
    @media(min-width: 768px) {
      height: 400px;
    }
    @media(min-width: 1024px) {
      height: 300px;
    }
    @media(min-width: 1199px) {
      background-size: 100% auto;
      height: 25vh;
    }
    &.bg-transparent {
      background: transparent;
    }
  }
  .bg-network {
    background: #fff url(/img/LLObg_white_1920x1080.jpg) top left no-repeat;
    background-size: cover;
    /*background: #fff url(/img/back_1113_white.jpg) top left no-repeat;
    background-size: cover;
    @media(max-height: 1024px) and (max-width: 1766px) {
      background-image: url(/img/back_1024_white.jpg);
    }
    @media(max-height: 768px) and (max-width: 1325px) {
      background-image: url(/img/back_768_white.jpg);
    }*/
  }
  .background-fixed {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url('/img/LLO_Lady.jpg');
    background-size: 100% auto;
    background-position: 0 0;
    background-repeat: no-repeat;
    z-index: 101;
    &.bg-transparent {
      display: none;
    }
    &.bg-top {
      background-position: 0 0;
    }
    &.bg-bottom {
      background-position: 0 bottom;
    }
  }

  #index-video {
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: -100px;
  }
  #index-video-media {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  #index-video-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    &.desktop {
      background-position: 0 0;
      background-image: url(/img/back_1113.jpg);
      @media(max-height: 1024px) and (max-width: 1766px) {
        background-image: url(/img/back_1024.jpg);
      }
      @media(max-height: 768px) and (max-width: 1325px) {
        background-image: url(/img/back_768.jpg);
      }
    }
    &.mobile {
      background-image: url(/img/LLOgif.gif);
      background-position: center;
    }
  }
  /*.index-window {
    height: 50%;
  }*/

  .welcome {
    margin-top:36px;
    margin-bottom: 11px;
    line-height: 1em;

  }

  .subtitle {
    font-size: 1.125rem;
    max-width: 768px;
    margin: 0 auto;
  }

  .steps {
    width: 100%;
    height: 481px;
    padding-top: 384px;
    background: url('../img/steps-background.jpg') center no-repeat;
    background-size: cover;

    > div {
      width: @content-width-phone;
      height: 75px;
      margin: 0 auto;
      background-color: #fff;
      border-left: 25px solid #000;
      border-radius: 10px;
      overflow: hidden;
      position: relative;

      &:before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 37.5px 0 37.5px 35px;
        border-color: transparent transparent transparent #000000;
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    ol {
      padding: 5px 250px 5px 60px;
      counter-reset: steps;

      &:after {
        content: '';
        display: block;
        clear: both;
      }
    }

    li {
      width: auto;
      padding: 0 25px 0 35px;
      float: left;
      position: relative;
      counter-increment: steps;

      color: @text-color-dark;
      font-size: .625rem;
      text-transform: uppercase;

      &:before {
        content: counter(steps);
        padding: 0 10px;
        background-color: #000;
        border-radius: 5px 0 5px 0;
        text-align: center;
        color: #fff;
        position: absolute;
        left: 0;
        top: 35px;
      }

      span {
        content: '';
        height: 35px;
        display: block;
        font-size: 30px;
        line-height: 30px;
        text-align: center;

        &.icon-Life-less-karte {
          font-size: 40px;
        }
      }
    }

    a {
      position: absolute;
      right: 25px;
      top: 50%;
      transform: translateY(-50%);
    }

    // Phone ONLY!
    //=================================================
    @media @mq1col {

      ol {
        display: none;
      }

      a {
        right: 35px;
      }
    }

    // Tablet
    //=================================================
    @media @mq2col {
      height: 604px;
      padding-top: 498px;

      > div {
        width: 730px; // !!
      }

      ol {
        padding: 5px 190px 5px 56px;
      }

      li {
        width: 2/7*100%;

        &:first-child {
          width: 3/7*100%;
        }
      }
    }

    // Desktop and up
    //=================================================
    @media @mq3col {
      height: 604px;
      padding-top: 498px;

      > div {
        width: @content-width-desktop;
      }

      ol {
        padding: 5px 250px 5px 70px;
      }

      li {
        padding: 0 35px 0 35px;

        width: auto;
      }

      li:first-child {
        width: 1/3*100%;
      }

    }
  }

  .teaser-list {
    margin-top: 35px;
    margin-left: -(@gutter / 2);
    margin-right: -(@gutter / 2);

    &:after {
      content: '';
      display: block;
      clear: both;
    }

    > li {
      width: @content-width-phone;
      height: 320px;
      margin: (@gutter / 2);
      background-color: #fff;
      overflow: hidden;
      float: left;
      color: @text-color-dark;

      .text:nth-child(2n) {
        position: absolute;
        top: 50%;
        height: 50%;
        background-color: lightgrey;
      }
    }

    //> li:nth-child(n+6) {
    //  height: 380px;
    //}

    > li.events {
      width: @content-width-phone;
      height: (320px * 2) + @gutter;
    }

    > li.orders {
      width: @content-width-phone;
      height: auto;
      min-height:360px;
    }

    @media @mq2col {
      margin-bottom: 150px;
    }
  }
  .arrow-down {
  position: fixed;
  bottom: 0;
  left: 50%;
  margin-left:-20px;
  width: 40px;
  height: 40px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0yOTMuNzUxLDQ1NS44NjhjLTIwLjE4MSwyMC4xNzktNTMuMTY1LDE5LjkxMy03My42NzMtMC41OTVsMCwwYy0yMC41MDgtMjAuNTA4LTIwLjc3My01My40OTMtMC41OTQtNzMuNjcyICBsMTg5Ljk5OS0xOTBjMjAuMTc4LTIwLjE3OCw1My4xNjQtMTkuOTEzLDczLjY3MiwwLjU5NWwwLDBjMjAuNTA4LDIwLjUwOSwyMC43NzIsNTMuNDkyLDAuNTk1LDczLjY3MUwyOTMuNzUxLDQ1NS44Njh6Ii8+DQo8cGF0aCBmaWxsPSIjRkZGRkZGIiBkPSJNMjIwLjI0OSw0NTUuODY4YzIwLjE4LDIwLjE3OSw1My4xNjQsMTkuOTEzLDczLjY3Mi0wLjU5NWwwLDBjMjAuNTA5LTIwLjUwOCwyMC43NzQtNTMuNDkzLDAuNTk2LTczLjY3MiAgbC0xOTAtMTkwYy0yMC4xNzgtMjAuMTc4LTUzLjE2NC0xOS45MTMtNzMuNjcxLDAuNTk1bDAsMGMtMjAuNTA4LDIwLjUwOS0yMC43NzIsNTMuNDkyLTAuNTk1LDczLjY3MUwyMjAuMjQ5LDQ1NS44Njh6Ii8+DQo8L3N2Zz4=);
  background-size: contain;
}

}
.main-nav {
  background: rgba(0,0,0,.75);
}
