[data-page="newsletter"] {

    /*background: url('/img/LLOweb_muc_1920x1113_cut.jpg') 10% top no-repeat #000;
    background-size: 100% auto;
    background-attachment: fixed;
    @media @mq2col {
        background: url('/img/LLOweb_muc_1920x1113_cut.jpg') 10% top no-repeat #000;
        background-size: 100% auto;
        background-attachment: fixed;
    }*/
}
