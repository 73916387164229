.teaser {
  display: block;
  border-radius: @border-radius;

  h2, h3 {
    text-transform: uppercase;
  }

  h2 {
    font-size: 1.4rem;
    line-height: 1.25;
    margin-bottom: 5px;
  }

  h3 {
    font-size: .75rem;
    font-weight: @font-medium;
    margin-bottom: 20px;
  }

  p {
    font-size: .75rem;
  }
  &.partner {
    opacity: 1;
    transition: opacity .3s ease-in, height .2s ease-in;
    //cursor: pointer;


    &.hidden {
      opacity:0;
    }
    p {
      max-height: 3em;
      overflow: hidden;
    }
  }

  a {
    color: inherit;
    font-size: .75rem;

    &.more-link {
      padding-right: 0.875rem;
      margin-top: 5px;
      display: inline-block;
      position: relative;

      &:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: .325rem 0 .325rem .325rem;
        border-color: transparent transparent transparent #000;
        position: absolute;
        right: 0;
        top: .2375rem;
      }
    }
  }
  span{
    &.more-link {
      padding-right: 0.875rem;
      margin-top: 5px;
      display: inline-block;
      position: relative;

      &:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: .325rem 0 .325rem .325rem;
        border-color: transparent transparent transparent #000;
        position: absolute;
        right: 0;
        top: .2375rem;
      }
    }

  }

  > img {
    position: absolute;
    bottom: 0;
  }

  .text {
    padding: @teaser-padding;
    height: 160px;

    &.has-company-logo {
      @company-logo-size: 90px;
      @company-logo-padding: 5px;

      padding-right: (@company-logo-size + @teaser-padding + @company-logo-padding);

      .company-logo {
        display: block;
        width: @company-logo-size;
        height: @company-logo-size;
        position: absolute;
        top: @teaser-padding;
        right: @teaser-padding;
        border: 1px solid #000;
        border-radius: 10px;
        background: #fff;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
.order-teasers{
  margin-left: 0;
  margin-bottom: 80px;
  @media @mq3col {
    margin-left: 6px;
  }
  &:after{
    content: '';
    display: block;
    clear: both;
  }
}
.order-teaser{

  float: left;
  background: white;
  border-radius: 10px;
  width: 300px;
  margin: 6px;
  padding: 20px;
  color: black;
  @media @mq1col {
    margin: 6px 0 ;
  }

  // Tablet
  //=================================================
  @media @mq2col {
    margin: 5px;
  }
}

/*
.teaser-list {
  padding: 35px 0;

  h1, h2, h3, h4 {
    text-transform: uppercase;
  }

  h3 {
    margin-bottom: 5px;
    line-height: 1.25;
  }

  > ul {
    margin-top: 35px;
    margin-left: -(@gutter / 2);
    margin-right: -(@gutter / 2);

    &:after {
      content: '';
      display: block;
      clear: both;
    }

    > li {
      width: @content-width-phone;
      height: 320px;
      margin: (@gutter / 2);
      background-color: #fff;
      border-radius: @border-radius;
      display: block;
      overflow: hidden;
      float: left;

      > img {
        position: absolute;
        bottom: 0;
      }
    }

    > li:nth-child(n+6) {
      height: 380px;
    }

    > li.events {
      width: @content-width-phone;
      height: (320px * 2) + @gutter;
    }
  }

  .text {
    padding: @teaser-padding;

    > a {
      padding-right: 0.875rem;
      margin-top: 5px;
      display: inline-block;
      position: relative;

      &:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: .325rem 0 .325rem .325rem;
        border-color: transparent transparent transparent #000;
        position: absolute;
        right: 0;
        top: .2375rem;
      }
    }

    p, a {
      font-size: .75rem;
      font-weight: normal;
    }

    p + p {
      margin-top: 1rem;
    }

    .float-right {
      float: right;
    }

    &:nth-child(2n) {
      position: absolute;
      top: 50%;
      height: 50%;
      background-color: lightgrey;
    }

    &.has-company-logo {
      @company-logo-size: 90px;
      @company-logo-padding: 5px;

      padding-right: (@company-logo-size + @teaser-padding + @company-logo-padding);

      .company-logo {
        display: block;
        width: @company-logo-size;
        height: @company-logo-size;
        padding: @company-logo-padding;
        position: absolute;
        top: @teaser-padding;
        right: @teaser-padding;
        border: 1px solid #000;
        border-radius: 10px;
        background: #fff;

        line-height: (@company-logo-size - (@company-logo-padding * 2) - 2px);
        text-align: center;

        > img {
          vertical-align: baseline;
          max-height: 100%;; //~'calc(100% - 2px)';
          max-width: 100%;
        }
      }
    }
  }

  // Phone ONLY!
  //=================================================
  @media @mq1col {

  }

  // Tablet
  //=================================================
  @media @mq2col {

  }

  // Desktop and up
  //=================================================
  @media @mq3col {

  }
}*/
