.text-label {
  display: inline-block;
  position: relative;
  padding: 2px 8px 1px;
  top: -3px;

  background-color: @text-color-dark;

  color: @text-color-bright;
  font-family: @font-family !important;
  font-size: .75rem;
  font-weight: @font-regular;
  line-height: inherit;
  text-transform: uppercase;
  vertical-align: middle;

  .contrast & {
    border: 1px solid @text-color-bright;
  }
}

.number-label {
  display: inline-block;
  width: 37px;
  height: 23px;

  background-color: @text-color-bright;
  border-top-left-radius: @border-radius;
  border-bottom-right-radius: @border-radius;
  border: 1px solid @text-color-bright;
  color: @text-color-dark;
  font-weight: @font-medium;
  line-height: 23px;
  font-size: 18px;
  text-align: center;

  .contrast & {
    color: @text-color-bright;
    background-color: @text-color-dark;
    border: 1px solid @text-color-dark;
  }
}