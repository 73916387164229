.partner-events-table {
  &>tbody>tr>td, &>tbody>tr>th, &>tfoot>tr>td, &>tfoot>tr>th, &>thead>tr>td, &>thead>tr>th {
    border-top: 0;
    border-bottom: 1px solid #b6b6b6;
  }
  &>tbody>tr.no-border>td, &>tbody>tr.no-border>th, &>tfoot>tr.no-border>td, &>tfoot>tr.no-border>th, &>thead>tr.no-border>td, &>thead>tr.no-border>th,
  &>tbody>tr>td.no-border, &>tbody>tr>th.no-border, &>tfoot>tr>td.no-border, &>tfoot>tr>th.no-border, &>thead>tr>td.no-border, &>thead>tr>th.no-border {
    border-bottom: 0;
  }
  &>tbody>tr>td.lh, &>tbody>tr>th.lh, &>tfoot>tr>td.lh, &>tfoot>tr>th.lh, &>thead>tr>td.lh, &>thead>tr>th.lh {
    line-height: 25px;
  }
  .event-date, .event-time, .event-title {
    font-size: 15px;
    font-weight: 400;
    margin: 0;
    line-height: 1.42857143;
    @media(max-width: 479px) {
      font-size: 14px;
    }
  }
  .event-day, .event-partner {
    font-size: 15px;
    font-weight: 400;
  }
  .event-description {
    font-size: 15px;
    padding-top: 10px;
  }
  @media(max-width: 991px) {
    margin-top: 40px;
  }
  @media(min-width: 768px) {
    tr:hover {
      background: rgba(255, 255, 255, 0.05);
    }
  }
  tr.highlight:hover, tr.highlight {
    background: #fff;
    color: #2b2b2b;
  }
}
.event-calendar {
  font-size: 15px;
.bootstrap-datetimepicker-widget  {
  table thead tr {
    border-bottom: 1px solid #b6b6b6;
    &:first-child th:hover {
      color: #2b2b2b;
    }
  }
  table td span:hover {
    color: #2b2b2b;
  }
  table td.day,  {
    /*background: #ddd;*/
    color: #2b2b2b;
    position: relative;
    z-index: 0;
    &:after {
      content: " ";
      position: absolute;
      left: 1px;
      top: 1px;
      right: 1px;
      bottom: 1px;
      background: #ddd;
      border-radius: 5px;
      z-index: -1;
    }
    &:hover {
      background: transparent;
    }

    &.disabled {
      color: #fff;
      /*background: transparent;*/
      &.new, &.old {
        color: #666;
      }
      &:after {
        background: transparent;
      }
    }
    &.today {
      &:before {
        border-bottom-color: #2b2b2b;
      }
      &.disabled:before {
        border-bottom-color: #fff;
      }
    }
  }
  table td span.active {
    background: #ddd;
    color: #2b2b2b;
  }
}
}
.events, .teaser.events {

  li {
    >p {

      padding: 15px 25px;
    }
  }

  h2 {
    height: 5rem;
    padding: 0 25px;
    background-color: #000;
    color: @text-color-bright;
    font-size: 1.25rem;
    line-height: 5rem;

    border-radius: 10px 10px 0 0;

    [class^="icon-"] {
      position: relative;
      top: -3px;
      font-size: 2em;
      vertical-align: middle;
    }
  }

  h3 {
    position: relative;
    padding-right: .5rem;
    padding-left: 2.5rem;
    margin-bottom: 5px;
    font-size: .9375rem;
    font-weight: 400;
    line-height: 1.25;
    text-transform: uppercase;
    height: 36px;

    [class^="icon-"] {
      font-size: 2rem;
      position: absolute;
      left: 0;
    }
  }

  a {
    display: block;
    position: relative;
    padding: 15px 25px;
    background-color: #fff;
    color: @text-color-dark;

    &:before {
      content: '';
      position: absolute;
      left: 25px;
      bottom: 0;
      width: ~'calc(100% - 25px * 2)';
      height: 1px;
      background-color: rgba(0, 0, 0, .1);
    }

    &:after {
      content: "\f0da";
      font-family: 'llo' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;

      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      position: absolute;
      left: ~'calc(100% - 25px)';
      top: 50%;
      margin-top: -.7rem;
      font-size: 1.5rem;
    }
  }

  .location, .time {
    font-size: .75rem;
  }
  .location {
    max-height: 1.3rem;
    overflow: hidden;
    display: block;
  }
  li.open .location{
    max-height: none;
  }

  .date {
    margin-bottom: 15px;
    color: #404e56;
    font-size: 0.9375rem;
    font-weight: 200;
  }

  &[data-height="2"]{
    .slick-slide{
      height: 258px;
      overflow-y: auto;
    }

    li a:after{
      content: normal;
    }
  }
}
.teaser.events{
  overflow: initial;
}
#events-pagination {
  .pagination {
    display: block;
      text-align: center;
      &>li {
        display: inline-block;
        margin: 0 5px;
        &>a, &>span {
          border-radius: 6px;
          background: rgba(0,0,0,0.75);
          border: 0;
          font-weight: 500;
          padding: 0;
          width: 34px;
          height: 28px;
          line-height: 28px;
          color: #fff;
        }
        &.disabled {
          &>a, &>span {
            color: #666;
            visibility: hidden;
          }
        }
        &.active {
          &>a, &>a:focus, &>a:hover, &>span, &>span:focus, &>span:hover {
            background: #fff;
            color: #000;
          }
        }
      }
  }
}
