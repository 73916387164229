[data-page="faq"] {

  //background: linear-gradient(transparent 0, transparent 500px, #000 1000px), url('/img/LLOnew_Muc_sw_dark.png') 15% top / 400% auto no-repeat;
  /*background: #fff url('/img/LLOweb_muc_1920x1113_cut.jpg') left 50px no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  //background-size: cover;
  @media @mq2col {
      background: #fff url('/img/LLOweb_muc_1920x1113_cut.jpg') left 50px no-repeat;
      -webkit-background-size: cover;
      background-size: cover;
      background-attachment: fixed;
  }*/
  select option {
    background-color: #000;
  }

    .separator {
        margin: 20px 0;
    }

    .form-group {
        margin-bottom: 0;
    }

    .left-column, .right-column {
        padding-bottom: 0;
    }

    .left-column {

        @media @mq2col {
            padding-right: 40px;
        }
        position: relative;
        height: auto;
    }
    .right-column {
        height: 100%;
        position: relative;
        @media @mq2col {
            padding-left: 40px;
            &:before {
                content: '';
                position: absolute;
                width: 1px;
                margin-top: 50px;
                background-color: #fff;
                left: 0;
                top: 0;
                height: 508px;
            }
        }
    }

    .report-problem {
        @media @mq2col {
            .right-column {
                margin-top: 22px;
                &:before {
                    margin-top: 27px;
                    height: 743px;
                }
            }
            textarea {
                height: 218px;
            }
        }
        @media @mq3col {
            .right-column {
                &:before {
                    height: 647px;
                }
            }
        }
    }

    .contact-form {

        &[data-subject="report_problem"]{
            .right-column{
                margin-top: 24px;
            }
            .mobile-padding{
                padding-left: @gutter;
            }
            textarea {
                height: 217px;
            }
        }

        @media @mq2col {
            .right-column {
                //margin-top: 85px;
            }

            textarea {
                height: 388px;
                &#report_problem_contact{
                    height: auto;
                }
                &#report_problem_description{
                    height: 210px;
                }

            }
        }
    }

    .separation-border:after {
        content: '';
        position: absolute;
        left: 0;

        //top = padding of column
        top: 10px;
        width: 1px;
        height: 80%;
        background-color: #fff;
    }

    .slide{
        padding-top: 100px;
    }
    .faqs a{
        color: #000;
    }
}
