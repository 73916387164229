.btn {
  /*  padding: 0 30px;
    height: 35px;
    background: rgba(255, 255, 255, .15);
    border: 2px solid #949494;
    border-radius: @border-radius;
    color: #fff;
    font-size: 0.6875em;
    text-transform: uppercase;
    line-height: (35px - 4px);
    display: inline-block;

    &:hover {
        background: darken(rgba(255, 255, 255, .15), 35);
        border: 2px solid darken(#949494, 20);
        color: darken(#fff, 35);
    }

    &.btn-solid {
        background-color: #000;
        border: 0 none;
        line-height: 35px;
        font-weight: 500;

        &:hover {
            border: 0 none;
        }
    }*/
  &.btn-default, &.btn-default:hover {
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
    &:hover {
      background: #fff;
      color: #2b2b2b;
    }
  }
  &.btn-wide {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 45px;
    padding-right: 45px;
  }
}

.mini-button {
    color: #fff;
    font-size: 11px;
    text-transform: uppercase;
}
