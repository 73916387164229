[data-page="partner-detail"] {

  /*background: #fff url('/img/LLOweb_muc_1920x1113_cut.jpg') left 50px no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  //background-size: cover;
  @media @mq2col {
      background: #fff url('/img/LLOweb_muc_1920x1113_cut.jpg') left 50px no-repeat;
      -webkit-background-size: cover;
      background-size: cover;
      background-attachment: fixed;
  }*/


    .partner-details-box {
      margin-bottom: 30px;
      .dark {
        background: rgba(0,0,0,0.75);
      }
      &.full-height {
        height: 300px;
        @media(min-width: 992px) {
          position: absolute;
          left: 15px;
          right: 15px;
          top: 0;
          bottom: 0;
          height: auto;
        }
      }
      .partner-details-box-complete {
        border-radius: 6px;
        border-top-left-radius: 27px;
        border-bottom-right-radius: 27px;
        -webkit-border-radius: 6px;
        -webkit-border-top-left-radius: 27px;
        -webkit-border-bottom-right-radius: 27px;
        padding: 30px;
        position: relative;
        overflow: hidden;
        &.mheight {
          min-height: 400px;
        }
        &.chain-store-box {
          padding-top: 75px;
          .chain-store-name {
            position: absolute;
            left: 0;
            top: 0;
            background: #ccc;
            color: #2b2b2b;
            font-weight: 500;
            padding: 10px 30px;
            border-top-left-radius: 27px;
            -webkit-border-top-left-radius: 27px;
          }
        }
      }
      .partner-details-box-top {
        border-radius: 0;
        border-top-left-radius: 27px;
        border-top-right-radius: 6px;
        padding: 30px;
        position: relative;
      }
      .partner-details-box-bottom {
        border-radius: 0;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 27px;
        padding: 30px;
        position: relative;
      }
      h1 {
        font-size: 60px;
        font-weight: 200;
        margin-top: 0;
      }
      h2 {
        font-size: 22px;
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: 4.5px;
        margin-top: 0;
        margin-bottom: 21px;
      }
      &.partner-details-teaser {
        h1 {
          margin-top: 10.5px;
          margin-bottom: 0;
          padding-right: 100px;
          @media(max-width: 767px) {
            font-size: 24px;
            padding-right: 48px;
          }
        }
        h2 {
          margin-top: 0;
          margin-bottom: 0;
          padding-right: 100px;
        }
        h4 {
          font-size: 24px;
          margin-bottom: 21px;
        }
        ul {
          padding-left: 20px;
        }
        .partner-details-icon {
          position: absolute;
          right: 30px;
          top: 30px;
          font-size: 60px;
          @media(max-width: 767px) {
            font-size: 48px;
          }
        }
        .partner-details-box-middle img {
          display: block;
        }
      }
      &.partner-details-contact {
        .contact-details, .contact-details * {
          font-size: 15px;
          vertical-align: top;
        }
        .row > div:first-child {
          margin-bottom: 30px;
        }
      }
    }
    #partner-details-map {
      position: relative;
      overflow: hidden;
      height: 100%;
      padding: 0;
    }


    //background: linear-gradient(transparent 0, transparent 500px, #000 1000px), url('/img/partners_background_small.jpg') 10% top / 300% auto no-repeat;
    /*background: url('/img/partners_background_small.jpg') 10% top no-repeat #000;
    background-size: 100% auto;
    background-attachment: fixed;
    @media @mq2col {
        background: url('/img/partners_background.jpg') 10% top no-repeat #000;
        background-size: 100% auto;
        background-attachment: fixed;
    }*/

    .wrapper {
        overflow: visible;
        @media @mq2col {
            width: 730px;
        }
        @media @mq3col {
            width: 940px;
        }
    }

    .page-headline {
        font-weight: 500;
    }

    .top-buttons {
        position: relative;
        z-index: 50;
    }
    .partners-top-button {
        height: 46px;
        line-height: 46px;
        font-size: 17px;
        position: static;
        background-color: rgba(0, 0, 0, 0.7);
        padding-left: 40px;
        padding-right: 40px;

        .icon {
            margin-right: 25px;
        }
    }

    .partner-details-wrapper {
        height: auto;
        position: relative;

        .partners-tile {
            cursor: default;
        }

        .partner-share {
            z-index: 1;
            font-size: 20px;
            position: absolute;
            top: 65px;
            right: 20px;
            color: #fff;
            .partner-share-button {
                font-size: 30px;
                color: #fff;
                float: right;
                padding: 5px 10px;
                background-color: transparent;
                transition: all .3s ease;
                text-shadow: 2px 2px 4px #000;



                &:hover {
                    color: #949494;
                }

                .icon {
                    transform: rotate(0deg);
                    transition: transform .3s ease;
                }
            }
            .icon {
                margin: 0;
                display: inline-block;
            }

            &.active {
                .partner-share-button {
                    background-color: rgba(100, 100, 100, 0.6);
                    transition: background-color 0.3s ease;
                    border-top-left-radius: @border-radius;
                    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0);
                    .icon {
                        transform: rotate(-360deg);
                    }
                }
                .expand-buttons {
                    display: block;
                    opacity: 1;
                    height: auto;
                    transition: opacity 0.3s ease;
                }
            }
            .expand-buttons {
                overflow: hidden;
                position: relative;
                opacity: 0;
                transition: none;
                height: 0;
                width: 111px;
                clear: both;
                background-color: rgba(100, 100, 100, 0.6);
                padding: 5px;
                border-top-left-radius: @border-radius;
                border-bottom-right-radius: @border-radius;

                .icon {
                    display: inline-block;
                    height: 38px;
                    line-height: 38px;
                    font-size: 44px;
                    position: relative;
                    left: 1px;
                    color: #fff;
                    cursor: pointer;
                    margin: 5px;

                    transition: color .3s ease;

                    &:hover {
                        color: #949494;
                    }
                }
            }
        }

        .details.row {
            margin: 0;
            margin-bottom: @gutter;
            border: 2px solid #fff;
            border-radius: @border-radius;
            @media @mq1col {
                background-color: #fff;
                .description {
                    background-color: #fff;
                }

                .contact {
                    background-color: #000;
                }
            }
            @media @mq2col {
                background-image: url(/img/partner-details-bg-repeat.png);
                background-position: center right;
                //background-size: contain;
                background-repeat: repeat-y;
            }
            @media @mq2colOnly {
                .col-md-4 {
                    width: 58.666667%;
                }
                .col-md-2 {
                    width: 41.333333%;
                }
            }

            height: auto;
            overflow: hidden;
            .description {
                padding: 20px;
                color: #000;

                a{
                    color: #000;
                }
            }
            .contact {
                padding: 30px 15px 30px 15px;
                height: 100%;

                @media @mq2col {
                    //padding-left: 15px;
                }
                @media @mq3col {
                    padding-left: 25px;
                }
            }
            .contact-heading {
                .icon {
                    font-size: 30px;
                    position: relative;
                    top: 5px;
                    margin-right: 0;
                }
                h3 {
                    @media @mq1col {
                        font-size: 20px;
                    }
                    @media @mq2col {
                        font-size: 16px;
                    }
                    @media @mq3col {
                        font-size: 20px;
                    }
                    font-weight: 500;
                    text-transform: uppercase;
                }

            }
            .contact-details {
                padding: 10px 10px 10px 34px;
                font-size: 13px;
                td {
                    min-width: 70px;
                }
                a{
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    //max-width: 180px;
                    max-width: 100%;
                    display: inline-block;

                    position: relative;
                    top: 3px;
                }
                table{
                    border-collapse: collapse;
                }
            }
        }
        .map-events {
            width: 100%;
            height: 100%;
            .events {
                h2 {
                    @media @mq2col {
                        font-size: 1rem;
                    }
                    @media @mq3col {
                        font-size: 1.25rem;
                    }
                }
            }

        }
        .map {
            position: relative;
            float: left;
            width: 100%;
            height: 100%;
            margin-bottom: 30px;

            @media @mq2col {
                //width: 410px;
            }

            @media @mq3col {
                //width: 620px;
            }
            #partner-map {
                width: 100%;
                height: 100%;
                //height: 395px;
                //@media(max-width: 1199px) {
                  -webkit-mask: url(/img/partner-map-mask.svg);
                  -webkit-mask-size: cover;
                //}
            }
            #map-overlay {
                display: inline-block;
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                span {
                    color: #fff;
                    text-shadow: 1px 1px 2px black;
                    width: 100%;
                    text-align: center;
                    position: absolute;
                    top: 65%;
                    display: inline-block;
                }
            }
            .leaflet-container {
                //border-radius: @border-radius;
                border-radius: 6px;
                    border-top-left-radius: 27px;
                    border-bottom-right-radius: 27px;
                    -webkit-border-radius: 6px;
                    -webkit-border-top-left-radius: 27px;
                    -webkit-border-bottom-right-radius: 27px;            }
            .show-route {
                position: absolute;
                background-color: rgba(0, 0, 0, .7);
                @media @mq1col {
                    width: 200px;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: 30px;
                }
                @media @mq2col {
                    bottom: 23px;
                    right: 9px;
                    margin: 0;
                }
            }
        }
        /*.event-calendar {
            width: 300px;
            float: right;
            .teaser {
                height: 395px;
                width: 300px;
                background-color: #fff;
                .slider {
                    background-color: #fff;
                    top: -4px;
                }
            }
        }*/
    }

    .partners-tile {
        position: relative;
        width: 100%;
        background-clip: padding-box;
        overflow: hidden;
        border-radius: @border-radius;
        margin-bottom: 20px;
        background: right center no-repeat, #000;
        cursor: pointer;

        text-shadow: 2px 2px 4px #000;


        @media @mq1col {
            //height: 415px;
            background-position: center bottom;
            background-size: 100% auto;
        }
        @media @mq2col {
            height: 370px;
        }
        @media @mq3col {
            height: 370px;
        }

        .gradient {
            height: 100%;
            @media @mq1col {
                padding: 10px 20px 170px;
            }
            @media @mq2col {
                padding: 45px 30px 35px 30px;
                background: linear-gradient(90deg, black, black 120px, transparent 45%) center center no-repeat;
                &.darken {
                    background: linear-gradient(90deg, black, black 120px, rgba(0, 0, 0, 0.3)) center center no-repeat;
                }
            }
            @media @mq3col {
                padding: 55px 30px 35px 30px;
                background: linear-gradient(90deg, black, black 220px, transparent 45%) center center no-repeat;
                &.darken {
                    background: linear-gradient(90deg, black, black 220px, rgba(0, 0, 0, 0.3)) center center no-repeat;
                }
            }
        }

        .icon {
            font-size: 35px;
        }

        .text-label {
            font-size: 16px;
            line-height: 16px;
            font-weight: 500;
            border: 1px solid #fff;
            padding: 4px 10px 5px;
            top: -8px;
            margin-right: 10px;
        }

        .category {
            position: relative;
            font-size: 18px;
            line-height: 18px;
            font-weight: 500;
            font-style: italic;
            top: -6px;
        }

        .partners-heading {
            font-size: 30px;
            text-transform: uppercase;
            font-weight: 500;
            margin-bottom: 10px;
            @media @mq1col {
                line-height: 100%;
            }
        }
        .partners-subheading {
            font-size: 15px;
            text-transform: uppercase;
            font-weight: 500;
            margin-bottom: 20px;
        }

        .partners-description {
            font-size: 15px;

        }
    }

    // Carousel
    .slick-slide {
        margin: 0 30px;
    }
    .slick-list {
        margin: 0 -30px;
    }

    .leaflet-popup-content{
        color: black;
    }
    .partner-details-events {
      font-size: 15px;
    }

}
