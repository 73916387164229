html {
  font-size: 100%;
  position: relative;
  min-height: 100%;
  /*overflow-y: scroll;*/
}
html.skrollr {
  height: 100%;
}
body {
  background: #fff;
  padding: 0;
  /* bottom = footer height */
  font-size: 18px;
  font-weight: 100;
  /*line-height: 1.5;*/
  color: #fff;
}
body label {
  padding-left: 0 !important;
  font-size: 14px;
  font-weight: 300;
  padding: 0;
  text-transform: uppercase;
}
body img {
  max-width: 100%;
}
body .btn:not(.btn-ci-dynamic) {
  background: #222;
  color: #ccc;
  border: 0;
  min-width: 136px;
  width: auto;
  height: auto;
}
body .btn:not(.btn-ci-dynamic).btn-outline {
  border: 1px solid #fff;
}
body .btn:not(.btn-ci-dynamic):hover {
  background: #191919;
}
body .btn:not(.btn-ci-dynamic).btn-primary {
  background: #fff;
  color: #2b2b2b;
}
body .btn:not(.btn-ci-dynamic).btn-primary:hover {
  background: #ddd;
}
body ul {
  list-style-type: square;
}
body #bgVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}
body .html-mobile-background {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 125%;
  /* To compensate for mobile browser address bar space */
  background-repeat: no-repeat;
  background-size: cover;
}
body .partner-details-box {
  margin-bottom: 30px;
}
body .partner-details-box .dark {
  background: rgba(0, 0, 0, 0.75);
}
body .partner-details-box.full-height {
  height: 300px;
}
@media (min-width: 992px) {
  body .partner-details-box.full-height {
    position: absolute;
    left: 15px;
    right: 15px;
    top: 0;
    bottom: 0;
    height: auto;
  }
}
body .partner-details-box .partner-details-box-complete {
  border-radius: 6px;
  border-top-left-radius: 27px;
  border-bottom-right-radius: 27px;
  -webkit-border-radius: 6px;
  -webkit-border-top-left-radius: 27px;
  -webkit-border-bottom-right-radius: 27px;
  padding: 30px;
  position: relative;
  overflow: hidden;
}
body .partner-details-box .partner-details-box-top {
  border-radius: 0;
  border-top-left-radius: 27px;
  border-top-right-radius: 6px;
  padding: 30px;
  position: relative;
}
body .partner-details-box .partner-details-box-bottom {
  border-radius: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 27px;
  padding: 30px;
  position: relative;
}
body .partner-details-box h1 {
  font-size: 60px;
  font-weight: 200;
  margin-top: 0;
}
body .partner-details-box h2 {
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 4.5px;
  margin-top: 0;
  margin-bottom: 21px;
}
body .partner-details-box.partner-details-teaser h1 {
  margin-top: 10.5px;
  margin-bottom: 0;
  padding-right: 100px;
}
body .partner-details-box.partner-details-teaser h2 {
  margin-top: 0;
  margin-bottom: 0;
  padding-right: 100px;
}
body .partner-details-box.partner-details-teaser h4 {
  font-size: 24px;
  margin-bottom: 21px;
}
body .partner-details-box.partner-details-teaser ul {
  padding-left: 20px;
}
body .partner-details-box.partner-details-teaser .partner-details-icon {
  position: absolute;
  right: 30px;
  top: 30px;
  font-size: 90px;
}
body .partner-details-box.partner-details-teaser .partner-details-box-middle img {
  display: block;
}
body .partner-details-box.partner-details-contact table * {
  font-size: 14px;
  vertical-align: top;
}
body .partner-details-box.partner-details-contact .row > div:first-child {
  margin-bottom: 30px;
}
@media all and (max-width: 767px) {
  body {
    padding: 0;
  }
}
@media (max-width: 991px) {
  body {
    background-position-y: 0 !important;
  }
}
body .top-space {
  padding-top: 200px;
}
@media (min-width: 992px) {
  body.is--nav {
    padding-top: 100px;
  }
  body.is--nav.top-space {
    padding-top: 300px;
  }
}
body:before {
  /**
         * These values will not show up in content, but can be
         * queried by JavaScript to know which breakpoint is active.
         * Add or remove as many breakpoints as you like.
         */
  content: "phone";
  display: none;
}
@media all and (min-width: 768px) {
  body:before {
    content: "tablet";
  }
}
@media all and (min-width: 1200px) {
  body:before {
    content: "desktop";
  }
}
a:hover,
a:active,
a:focus,
button:hover,
button:active,
button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: 0;
  box-shadow: none;
  border: 0 none;
}
a:focus,
a:hover {
  color: inherit;
}
.copytext a {
  text-decoration: underline;
}
* {
  font-family: 'Roboto', 'Arial', sans-serif;
  user-select: none;
  font-weight: 300;
}
.flex-row-md {
  display: block;
}
@media (min-width: 992px) {
  .flex-row-md {
    display: flex;
  }
}
button {
  border: 0;
}
input,
textarea,
select {
  user-select: text;
}
p + p {
  margin-top: 1rem;
}
h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 1.75rem;
}
h3 {
  font-size: 1.5rem;
}
h4 {
  font-size: 1rem;
}
h1 [class^="icon-"],
h2 [class^="icon-"],
h3 [class^="icon-"],
h4 [class^="icon-"],
h5 [class^="icon-"],
h6 [class^="icon-"],
h1 .text-label,
h2 .text-label,
h3 .text-label,
h4 .text-label,
h5 .text-label,
h6 .text-label,
h1 .number-label,
h2 .number-label,
h3 .number-label,
h4 .number-label,
h5 .number-label,
h6 .number-label {
  margin-right: 2px;
}
hr {
  border-width: 1px;
  margin: 2rem 0;
}
a {
  color: #fff;
}
main {
  position: relative;
  z-index: 1;
}
.btn.btn-more {
  font-size: 14px;
  background: #ddd !important;
  border: 0;
  color: #2b2b2b !important;
  padding: 2px 10px;
}
.btn.btn-more:hover {
  background: #fff;
}
#top-btn {
  position: fixed;
  display: none;
  width: 50px;
  height: 50px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
  background: rgba(0, 0, 0, 0.6);
  color: #ccc;
  bottom: 15px;
  right: 15px;
  z-index: 99999;
  text-align: center;
  line-height: 46px;
  font-size: 26px;
  transition: opacity .3s;
}
#top-btn:hover {
  color: #fff;
}
.border-bottom-light {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.numbered-headline {
  font-size: 1.5rem;
  font-weight: 300;
  color: #fff;
  margin: 0 0 1.5rem;
}
.page-headline {
  font-size: 3.4rem;
  line-height: 1.3em;
  margin-top: 160px;
  margin-bottom: 80px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
}
@media all and (max-width: 767px) {
  .page-headline {
    font-size: 2rem;
    margin-top: 120px;
    margin-bottom: 30px;
  }
}
.font-light {
  font-weight: 300;
}
.font-normal {
  font-weight: 400;
}
.font-medium {
  font-weight: 500;
}
.font-bold {
  font-weight: 700;
}
.icon {
  margin-right: 0.5rem;
}
.underlined {
  text-decoration: underline;
}
.text-small {
  font-size: 14px;
  font-weight: 300;
}
@media all and (max-width: 767px) {
  .hidden-mobile {
    display: none;
  }
}
@media all and (min-width: 768px) {
  .hidden-mobile {
    display: block;
  }
}
@media all and (min-width: 768px) {
  .hidden-tablet {
    display: none;
  }
}
@media all and (min-width: 1200px) {
  .hidden-tablet {
    display: initial;
  }
}
@media all and (min-width: 1200px) {
  .hidden-desktop {
    display: none;
  }
}
@media all and (min-width: 768px) {
  .mobile-only {
    display: none !important;
  }
}
.desktop-only {
  display: none;
}
@media all and (min-width: 1200px) {
  .desktop-only {
    display: initial;
  }
}
.hidden {
  display: none ;
}
.hidden.important {
  display: none !important;
}
::selection {
  background: transparent;
}
.vspace {
  height: 42px;
}
.transparent-form input,
.transparent-form select,
.transparent-form textarea,
.transparent-form input:focus,
.transparent-form select:focus,
.transparent-form textarea:focus {
  border-radius: 0;
  background: transparent;
  border: 0;
  border-bottom: 1px solid #fff;
  padding: 6px 12px;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  font-weight: 300;
  font-size: 15px;
}
.transparent-form .input-group-addon,
.transparent-form .input-group-btn .btn,
.transparent-form .input-group-btn .btn:hover,
.transparent-form .input-group-btn .btn:focus {
  border-radius: 0;
  background: transparent;
  border: 0;
  border-bottom: 1px solid #fff;
  color: #fff;
  font-size: 15px;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-standalone {
  margin-bottom: 21px;
}
.btn-ci {
  position: relative;
  border: 1px solid #2b2b2b;
  background: transparent;
  color: #2b2b2b;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 250px;
  height: 60px;
  line-height: 60px;
  padding: 0;
  text-transform: uppercase;
  border-radius: 4px;
  border-top-left-radius: 18px;
  border-bottom-right-radius: 18px;
  text-align: left;
  padding-left: 72px;
  font-size: 17px;
}
.btn-ci:hover {
  border: 0;
  background: #2b2b2b;
  color: #fff;
}
.btn-ci .llo-icon {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -16px;
  font-size: 32px;
  width: 72px;
  text-align: center;
}
.btn-ci-dynamic {
  border: 0;
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 4px;
  border-top-left-radius: 18px;
  border-bottom-right-radius: 18px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 300;
  padding: 8px 18px;
}
.btn-ci-dynamic:hover,
.btn-ci-dynamic:active,
.btn-ci-dynamic:focus,
.btn-ci-dynamic.active:focus,
.btn-ci-dynamic:active:focus {
  border: 0;
  background: #060606;
  color: #fff;
  outline: 0;
}
.btn-ci-dynamic.light {
  background: rgba(255, 255, 255, 0.75);
  color: #2b2b2b;
}
.btn-ci-dynamic.light:hover,
.btn-ci-dynamic.light:active,
.btn-ci-dynamic.light:focus,
.btn-ci-dynamic.light.active:focus,
.btn-ci-dynamic.light:active:focus {
  background: #ffffff !important;
  color: #2b2b2b !important;
}
.panel-ci {
  position: relative;
  border: 0;
  border-radius: 4px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 18px;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent;
  color: #fff;
}
.panel-ci .panel-heading {
  position: relative;
  padding: 0 15px 0 67.5px;
  border-top-left-radius: 25px;
  border-top-right-radius: 4px;
  height: 52.5px;
  line-height: 52.5px;
  text-align: left;
  background-color: rgba(0, 0, 0, 0.75);
  margin-bottom: 2px;
  overflow: hidden;
  /*-webkit-transition: opacity .3s linear;
    transition: opacity .3s linear;*/
}
.panel-ci .panel-heading .icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 52.5px;
  height: 52.5px;
  background: #fff;
  border-top-left-radius: 18px;
  color: #2b2b2b;
  text-align: center;
  font-size: 36px;
}
.panel-ci .panel-heading .icon .fa,
.panel-ci .panel-heading .icon .lloicon {
  position: absolute;
  left: 0;
  width: 52.5px;
  height: 36px;
  top: 8.25px;
}
@media (max-width: 1199px) {
  .panel-ci .panel-heading {
    font-size: 17px;
  }
}
.panel-ci .panel-body {
  font-size: 15px;
  text-align: left;
  padding-bottom: 45px;
  background-color: rgba(0, 0, 0, 0.75);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 18px;
  /*-webkit-transition: opacity .3s linear;
    transition: opacity .3s linear;*/
}
.panel-ci .panel-body img {
  max-width: 100%;
}
.panel-ci .panel-body b {
  font-weight: 400;
}
.panel-ci .panel-overlay {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 4px;
  border-top-left-radius: 18px;
  border-bottom-right-radius: 18px;
  /*-webkit-transition: opacity .3s linear;
    transition: opacity .3s linear;*/
}
.panel-ci .panel-overlay .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 56px;
  height: 56px;
  margin-top: -28px;
  margin-left: -28px;
  font-size: 56px;
}
.panel-ci .panel-overlay .icon .fa,
.panel-ci .panel-overlay .icon .lloicon {
  display: block;
}
.panel-ci .readmore {
  position: absolute;
  bottom: 10px;
  right: 15px;
  font-size: 14px;
}
@media (min-width: 1200px) {
  .hover-hide:hover .panel-ci {
    border-top-left-radius: 18px;
  }
  .hover-hide:hover .panel-ci .panel-heading,
  .hover-hide:hover .panel-ci .panel-body {
    opacity: 0;
  }
  .hover-hide:hover .panel-ci .panel-overlay {
    opacity: 1;
  }
}
.panel-ci.panel-ci-light {
  color: #000;
}
.panel-ci.panel-ci-light a {
  color: #000;
}
.panel-ci.panel-ci-light .panel-heading {
  background-color: rgba(255, 255, 255, 0.75);
  padding: 0 15px 0 130px;
}
.panel-ci.panel-ci-light .panel-heading .icon {
  background: #000;
  color: #fff;
  font-size: 16px;
  line-height: 1.1;
  width: 105px;
  padding-top: 10px;
}
.panel-ci.panel-ci-light .panel-body {
  background-color: rgba(255, 255, 255, 0.75);
}
.panel-ci.panel-ci-light .panel-overlay {
  background-color: rgba(255, 255, 255, 0.75);
}
.panel-ci.panel-ci-blog .panel-overlay .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 72px;
  height: 72px;
  margin-top: -72px;
  margin-left: -36px;
  font-size: 72px;
}
.panel-ci.panel-ci-blog .panel-overlay .text {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  margin-top: 10px;
  font-size: 32px;
}
.section {
  position: relative;
  padding: 147px 0;
  overflow: visible;
}
.section.section-tight {
  padding: 0;
}
.section.section-default {
  background: #fff;
  color: #2b2b2b;
}
.section.section-default.section-transparent {
  background-color: transparent;
}
.section.section-default a {
  color: #2b2b2b;
}
.section.section-default .btn {
  color: #fff;
}
.section.section-default .btn-ci {
  border: 1px solid #2b2b2b;
  background: transparent;
  color: #2b2b2b;
}
.section.section-default .btn-ci:hover {
  background: #2b2b2b;
  color: #fff;
}
.section.section-default.section-marker-top:before {
  position: absolute;
  left: 50%;
  top: -17px;
  margin-left: -40px;
  height: 34px;
  background: #fff;
  content: '\6c';
  z-index: 10;
  font-size: 56px;
  color: #2b2b2b;
  padding: 6px;
  border-top-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-top-right-radius: 3px;
  font-family: "llo-logo" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.section.section-default.section-marker-bottom:after {
  position: absolute;
  left: 50%;
  bottom: -17px;
  margin-left: -40px;
  height: 34px;
  background: #fff;
  content: '\6c';
  z-index: 10;
  font-size: 56px;
  color: #2b2b2b;
  padding: 6px;
  border-top-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-top-right-radius: 3px;
  font-family: "llo-logo" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.section.section-alternative {
  background: transparent;
  color: #fff;
}
.logo-line {
  position: relative;
  text-align: center;
  font-size: 55px;
  line-height: 1;
  height: 55px;
  margin-bottom: 42px;
  color: #b6b6b6;
}
.logo-line:before {
  position: absolute;
  left: 0;
  right: 50%;
  top: 50%;
  height: 1px;
  margin-right: 60px;
  background: #b6b6b6;
  content: '';
}
.logo-line:after {
  position: absolute;
  right: 0;
  left: 50%;
  top: 50%;
  height: 1px;
  margin-left: 60px;
  background: #b6b6b6;
  content: '';
}
.logo-line.tight {
  margin-bottom: 0;
}
hr.logo-line {
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0;
  padding: 0;
  height: 63px;
  background: transparent;
  position: relative;
}
hr.logo-line:before {
  position: absolute;
  left: 50%;
  top: 20px;
  width: 120px;
  height: 100px;
  margin-top: -50px;
  margin-left: -60px;
  background: #fff;
  color: #ccc;
  font-size: 60px;
  content: '\6c';
  height: 60px;
  text-align: center;
  font-family: "llo-logo" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
hr.logo-line.dark {
  border-top: 1px solid #2b2b2b;
}
hr.logo-line.dark:before {
  background: transparent;
  color: #2b2b2b;
}
hr.logo-line.trans:before {
  background: transparent;
}
.background-layer {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #fff;
}
.headline {
  margin-bottom: 42px;
}
.headline h1 {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 4.5px;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .headline h1 {
    font-size: 26px;
  }
}
.headline h2 {
  margin-top: 5px;
  font-size: 36px;
  font-weight: 100;
}
@media (min-width: 768px) {
  .headline h2 {
    font-size: 64px;
  }
}
.vertical-wide .headline {
  margin-bottom: 126px;
}
.vertical-wide p {
  margin-bottom: 42px;
}
.partner-teaser-items {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
}
.partner-teaser-items .partner-teaser-item {
  position: relative;
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  float: left;
  /*width: 33.33333333%;*/
  margin: 0 0 1px 1px;
}
.partner-teaser-items .partner-teaser-item > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.partner-teaser-items .partner-teaser-item .partner-teaser-item-partner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.partner-teaser-items .partner-label {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 90px;
  width: 100%;
  padding: 15px;
  background: #000;
  color: #fff;
}
.partner-teaser-items .partner-label .small {
  /*font-style: italic;*/
  font-size: 17px;
  line-height: 21px;
}
.partner-teaser-items .partner-label .small .cat-icon {
  float: left;
  margin-right: 10px;
  font-size: 20px;
  height: 21px;
}
@media (max-width: 767px) {
  .partner-teaser-items .partner-label .small .cat-icon {
    font-size: 17px;
  }
}
@media (max-width: 580px) {
  .partner-teaser-items .partner-label .small .cat-icon {
    font-size: 15px;
  }
}
.partner-teaser-items .partner-label h3 {
  margin-top: 5px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 300;
  line-height: 18px;
}
@media (max-width: 1199px) {
  .partner-teaser-items .partner-label h3 {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .partner-teaser-items .partner-label h3 {
    font-size: 17px;
  }
}
@media (max-width: 580px) {
  .partner-teaser-items .partner-label h3 {
    font-size: 15px;
  }
}
.llo-icon {
  display: inline-block;
  font: normal normal normal 14px/1 "llo-logo";
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.tp-dottedoverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: auto;
  height: auto;
}
.address-line {
  position: relative;
  padding-left: 30px;
  font-size: 15px;
  line-height: 25px;
  margin-top: 10.5px;
}
.address-line .address-line-icon {
  position: absolute;
  left: 0;
  top: 0;
  line-height: 25px;
}
.address-line .address-line-icon.llo-icon {
  top: 3px;
}
.address-line .address-line-icon.fa-home {
  font-size: 19px;
}
.outline-form .form-control {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
}
.outline-form .form-control:focus {
  background: transparent;
  padding: 6px 12px;
}
.outline-form select.form-control,
.outline-form select.form-control:focus {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+DQo8c3ZnDQogICB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iDQogICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIg0KICAgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIg0KICAgeG1sbnM6c3ZnPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyINCiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyINCiAgIGlkPSJzdmcyIg0KICAgeG1sOnNwYWNlPSJwcmVzZXJ2ZSINCiAgIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDYgMyINCiAgIHZpZXdCb3g9IjAgMCA2IDMiDQogICBoZWlnaHQ9IjNweCINCiAgIHdpZHRoPSIyNHB4Ig0KICAgeT0iMHB4Ig0KICAgeD0iMTJweCINCiAgIHZlcnNpb249IjEuMSI+PG1ldGFkYXRhDQogICAgIGlkPSJtZXRhZGF0YTEwIj48cmRmOlJERj48Y2M6V29yaw0KICAgICAgICAgcmRmOmFib3V0PSIiPjxkYzpmb3JtYXQ+aW1hZ2Uvc3ZnK3htbDwvZGM6Zm9ybWF0PjxkYzp0eXBlDQogICAgICAgICAgIHJkZjpyZXNvdXJjZT0iaHR0cDovL3B1cmwub3JnL2RjL2RjbWl0eXBlL1N0aWxsSW1hZ2UiIC8+PGRjOnRpdGxlPjwvZGM6dGl0bGU+PC9jYzpXb3JrPjwvcmRmOlJERj48L21ldGFkYXRhPjxkZWZzDQogICAgIGlkPSJkZWZzOCIgLz48cG9seWdvbg0KICAgICBzdHlsZT0iZmlsbDojZmZmZmZmO2ZpbGwtb3BhY2l0eToxIg0KICAgICBpZD0icG9seWdvbjQiDQogICAgIHBvaW50cz0iNS45OTIsMCAyLjk5MiwzIC0wLjAwOCwwICIgLz48L3N2Zz4=);
  background-position: 100% center;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.panel-group .panel-dark {
  border: 0;
  background: rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 4px;
  border-top-left-radius: 18px;
  border-bottom-right-radius: 18px;
  color: #fff;
}
.panel-group .panel-dark .panel-heading {
  padding: 0;
}
.panel-group .panel-dark .panel-heading .panel-title a {
  display: block;
  font-size: 18px;
  font-weight: 400;
  padding: 15px 30px 15px 30px;
}
.panel-group .panel-dark .panel-heading .panel-title a * {
  font-weight: inherit;
}
.panel-group .panel-dark .panel-heading .panel-title a:hover,
.panel-group .panel-dark .panel-heading .panel-title a:active,
.panel-group .panel-dark .panel-heading .panel-title a:focus {
  text-decoration: none;
}
.panel-group .panel-dark .panel-heading .panel-title a .toggle-up,
.panel-group .panel-dark .panel-heading .panel-title a .toggle-down {
  margin: 0 0 15px 15px;
}
.panel-group .panel-dark .panel-heading .panel-title a .toggle-up {
  display: none;
}
.panel-group .panel-dark .panel-heading .panel-title a[aria-expanded="true"] .toggle-up {
  display: inline-block;
}
.panel-group .panel-dark .panel-heading .panel-title a[aria-expanded="true"] .toggle-down {
  display: none;
}
.panel-group .panel-dark .panel-heading + .panel-collapse > .panel-body {
  border-top: 0;
  padding: 15px 30px;
}
.panel-group .panel-dark .panel-heading + .panel-collapse > .panel-body *:last-child {
  margin-bottom: 0;
}
.panel-group .panel-dark + .panel {
  margin-top: 20px;
}
.share-widget {
  position: absolute;
  right: 0;
  bottom: 0;
}
.share-widget .share-widget-share {
  position: absolute;
  right: 0;
  bottom: 0;
  background: #ccc;
  color: #fff;
  border-radius: 0;
  border-bottom-right-radius: 18px;
  width: 50px;
  line-height: 40px;
  text-align: center;
}
.share-widget .share-widget-networks {
  position: absolute;
  display: none;
  right: 0;
  bottom: 0;
  position: relative;
  width: 101px;
  height: 81px;
}
.share-widget .share-widget-networks .share-widget-network {
  position: absolute;
  display: block;
  background: #ccc;
  color: #2b2b2b;
  width: 50px;
  font-size: 22px;
  line-height: 32px;
  text-align: center;
  padding: 7px 0 1px;
  /*&.google-plus {
        left: 0;
        bottom: 0;
        border-bottom-left-radius: 4px;
      }*/
}
.share-widget .share-widget-networks .share-widget-network:hover {
  color: #000;
}
.share-widget .share-widget-networks .share-widget-network.facebook {
  left: 0;
  top: 0;
  border-top-left-radius: 18px;
}
.share-widget .share-widget-networks .share-widget-network.twitter {
  right: 0;
  top: 0;
  border-top-right-radius: 4px;
}
.share-widget .share-widget-networks .share-widget-network.email {
  /*right: 0;
        border-bottom-right-radius: 18px;*/
  left: 0;
  bottom: 0;
  border-bottom-left-radius: 18px;
}
.share-widget .share-widget-networks .share-widget-network.whatsapp {
  display: none;
}
@media (max-width: 1199px) {
  .share-widget .share-widget-networks {
    /*width: 152px;*/
  }
  .share-widget .share-widget-networks .share-widget-network {
    /*&.twitter {
          right: 51px;
          top: 0;
          border-top-right-radius: 0;
        }
        &.email {
          right: 0;
          top: 0;
          bottom: auto;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 0;
        }
        &.whatsapp {
          display: block;
          right: 51px;
          bottom: 0;
          padding-top: 2px;
          line-height: 37px;
        }*/
  }
  .share-widget .share-widget-networks .share-widget-network.whatsapp {
    display: block;
    right: 0;
    bottom: 0;
    padding-top: 2px;
    line-height: 37px;
    border-bottom-right-radius: 0;
  }
}
.share-widget:hover,
.share-widget.active {
  /*@media(max-width: 1199px) {*/
}
.share-widget:hover .share-widget-share,
.share-widget.active .share-widget-share {
  display: none;
}
.share-widget:hover .share-widget-networks,
.share-widget.active .share-widget-networks {
  display: block;
}
@media (min-width: 1200px) {
  .share-widget:hover .share-widget-share,
  .share-widget.active .share-widget-share {
    display: block;
  }
}
.light .share-widget .share-widget-share {
  background: #2b2b2b;
  color: #ccc;
}
.light .share-widget .share-widget-networks .share-widget-network {
  background: #2b2b2b;
  color: #ccc;
}
.light .share-widget .share-widget-networks .share-widget-network:hover {
  color: #fff;
}
.form-group.error .error-label {
  display: block !important;
}
.payment-icon {
  display: inline-block;
  padding: 5px 10px;
  background: #fff;
}
.btn-outline {
  border: 1px solid #fff;
}
.partner-details-box {
  margin-bottom: 30px;
}
.partner-details-box .dark {
  background: rgba(0, 0, 0, 0.75);
}
.partner-details-box .light {
  background: rgba(255, 255, 255, 0.75);
  color: #2b2b2b;
}
.partner-details-box .light a {
  color: #2b2b2b;
}
.partner-details-box.full-height {
  height: 300px;
}
@media (min-width: 992px) {
  .partner-details-box.full-height {
    position: absolute;
    left: 15px;
    right: 15px;
    top: 0;
    bottom: 0;
    height: auto;
  }
}
.partner-details-box .partner-details-box-complete {
  border-radius: 6px;
  border-top-left-radius: 27px;
  border-bottom-right-radius: 27px;
  -webkit-border-radius: 6px;
  -webkit-border-top-left-radius: 27px;
  -webkit-border-bottom-right-radius: 27px;
  padding: 30px;
  position: relative;
  overflow: hidden;
}
.partner-details-box .partner-details-box-complete.mheight {
  min-height: 400px;
}
.partner-details-box .partner-details-box-top {
  border-radius: 0;
  border-top-left-radius: 27px;
  border-top-right-radius: 6px;
  padding: 30px;
  position: relative;
}
.partner-details-box .partner-details-box-bottom {
  border-radius: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 27px;
  padding: 30px;
  position: relative;
}
.partner-details-box h1 {
  font-size: 60px;
  font-weight: 200;
  margin-top: 0;
}
.partner-details-box h2 {
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 4.5px;
  margin-top: 0;
  margin-bottom: 21px;
}
.partner-details-box.partner-details-teaser h1 {
  margin-top: 10.5px;
  margin-bottom: 0;
  padding-right: 100px;
}
@media (max-width: 767px) {
  .partner-details-box.partner-details-teaser h1 {
    font-size: 24px;
    padding-right: 48px;
  }
}
.partner-details-box.partner-details-teaser h2 {
  margin-top: 0;
  margin-bottom: 0;
  padding-right: 100px;
}
.partner-details-box.partner-details-teaser h4 {
  font-size: 24px;
  margin-bottom: 21px;
}
.partner-details-box.partner-details-teaser ul {
  padding-left: 20px;
}
.partner-details-box.partner-details-teaser .partner-details-icon {
  position: absolute;
  right: 30px;
  top: 30px;
  font-size: 60px;
}
@media (max-width: 767px) {
  .partner-details-box.partner-details-teaser .partner-details-icon {
    font-size: 48px;
  }
}
.partner-details-box.partner-details-teaser .partner-details-box-middle img {
  display: block;
}
.partner-details-box.partner-details-contact .contact-details,
.partner-details-box.partner-details-contact .contact-details * {
  font-size: 15px;
  vertical-align: top;
}
.partner-details-box.partner-details-contact .row > div:first-child {
  margin-bottom: 30px;
}
.modal-message {
  position: fixed;
  top: 120px;
  right: 20px;
  width: 300px;
  background: #000;
  z-index: 1100;
  -webkit-border-radius: 6px;
  -webkit-border-top-left-radius: 27px;
  -webkit-border-bottom-right-radius: 27px;
  border-radius: 6px;
  border-top-left-radius: 27px;
  border-bottom-right-radius: 27px;
  padding: 20px;
  font-size: 15px;
}
.modal-message .modal-close {
  position: absolute;
  display: inline-block;
  right: 10px;
  top: 10px;
}
.modal-message button {
  background: #000;
  padding: 0;
  font-size: 17px;
  font-weight: 300;
}
@media (max-width: 991px) {
  input[type='text'],
  input[type='number'],
  textarea {
    font-size: 16px;
  }
}
.form-group.checkbox label:before {
  margin-right: 2px;
  top: 1px;
  height: 12px;
  width: 12px;
}
.product-sales-banner {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
  color: white;
  background: #d01b30;
  font-weight: bold;
  padding: 1px 5px;
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}
.slick-track:before,
.slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
.tp-dottedoverlay {
  z-index: 100;
}
.accordion {
  width: 100%;
}
.accordion.faqs .collapsible {
  position: relative;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid #fff;
  background-color: #fff;
  line-height: 1.2rem;
  margin-bottom: 20px;
  padding: 0 20px 0 20px;
  display: block;
  overflow: hidden;
}
.accordion.faqs .collapsible .question {
  padding: 15px 35px 15px 0;
  color: #000;
  font-size: 1.125rem;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
}
.accordion.faqs .collapsible .answer {
  font-weight: 200;
  color: #000;
  line-height: 1.2rem;
  max-height: 0;
  padding-bottom: 0;
  transition: max-height 0.3s cubic-bezier(0, 1.05, 0, 1);
}
.accordion.faqs .collapsible .icon {
  position: absolute;
  top: 9px;
  right: 15px;
  color: #000;
  font-size: 1.8em;
  transition: transform 0.3s ease;
}
.accordion.faqs .collapsible.open .answer {
  max-height: 1000px;
  display: inherit;
  padding-bottom: 20px;
  transition: max-height 0.3s ease-in, padding-bottom 0.3s ease-in;
}
.accordion.faqs .collapsible.open .icon {
  transform: rotateZ(-180deg);
}
.btn {
  /*  padding: 0 30px;
    height: 35px;
    background: rgba(255, 255, 255, .15);
    border: 2px solid #949494;
    border-radius: @border-radius;
    color: #fff;
    font-size: 0.6875em;
    text-transform: uppercase;
    line-height: (35px - 4px);
    display: inline-block;

    &:hover {
        background: darken(rgba(255, 255, 255, .15), 35);
        border: 2px solid darken(#949494, 20);
        color: darken(#fff, 35);
    }

    &.btn-solid {
        background-color: #000;
        border: 0 none;
        line-height: 35px;
        font-weight: 500;

        &:hover {
            border: 0 none;
        }
    }*/
}
.btn.btn-default,
.btn.btn-default:hover {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
}
.btn.btn-default:hover,
.btn.btn-default:hover:hover {
  background: #fff;
  color: #2b2b2b;
}
.btn.btn-wide {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 45px;
  padding-right: 45px;
}
.mini-button {
  color: #fff;
  font-size: 11px;
  text-transform: uppercase;
}
[data-page="form_template"] {
  background-color: #222;
}
form {
  width: 100%;
}
.error-label {
  position: relative;
  padding-top: 5px;
  color: #d2042b;
  font-size: smaller;
  line-height: 1em;
  width: 100%;
  display: block;
}
.form-group {
  position: relative;
  width: 100%;
}
.form-group .error-label {
  display: none;
}
.form-group.error .error-label {
  display: inline;
}
.form-group label .extra-text {
  font-size: 14px;
  font-weight: 300;
}
.form-group span.locked {
  display: block;
  color: #fff;
  background-color: #888;
  background-clip: padding-box;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid #000;
  padding: 0 1rem 0 1rem;
  line-height: 38px;
  font-size: 16px;
}
.combined-errors .error-label {
  position: relative;
  padding-top: 5px;
  color: #d2042b;
  font-size: smaller;
  line-height: 1em;
  width: 100%;
  display: block;
}
.description-text {
  margin-top: 15px;
}
input,
select,
textarea {
  width: 100%;
  display: block;
  appearance: none;
  background-color: #fff;
  height: 38px;
  line-height: 38px;
  font-size: 16px;
  font-weight: 500;
  background-clip: padding-box;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid #000;
  padding: 0 1rem 0 1rem;
  position: relative;
}
input::selection,
select::selection,
textarea::selection {
  background: #888;
  color: #fff;
}
input:focus,
select:focus,
textarea:focus {
  background: linear-gradient(90deg, #949494 10px, #fff 10px);
  padding-left: 1rem;
  padding-right: 1rem;
  border: 1px solid #000;
}
select {
  background-color: #fff;
  background-image: url(../img/select-dropdown-arrow-small.png);
  background-position: right;
  background-repeat: no-repeat;
  padding-right: 50px;
}
select:focus {
  background: url(../img/select-dropdown-arrow-small.png) no-repeat right, linear-gradient(90deg, #949494 10px, #fff 10px);
  padding-left: 1rem;
  padding-right: 42px;
  border: 1px solid #000;
}
@media all and (min-width: 1200px) {
  select {
    background-image: url(../img/select-dropdown-arrow.png);
    padding-right: 65px;
  }
  select:focus {
    background: url(../img/select-dropdown-arrow.png) no-repeat right, linear-gradient(90deg, #949494 10px, #fff 10px);
    padding-left: 1rem;
    padding-right: 42px;
    border: 1px solid #000;
  }
}
select option {
  background-color: #fff;
  font-weight: 500;
  color: #000;
}
select.has-placeholder {
  color: #888;
}
textarea {
  height: auto;
  line-height: 1.2rem;
  padding: 1rem;
  border-bottom-right-radius: inherit;
  text-transform: none;
}
input[type="radio"] {
  display: none;
}
input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  font-size: 1em;
  width: auto;
  float: left;
  margin-right: .75em;
  background: transparent;
}
input[type="checkbox"]:checked,
input[type="checkbox"]:not(:checked) {
  background: transparent;
  position: relative;
  display: none;
  margin: 0;
  padding: 0;
}
input[type="checkbox"]:checked + label::before,
input[type="checkbox"]:not(:checked) + label::before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  content: "\f096";
  color: #000;
  /*/            content: ' ';
            display: inline-block;
            width: 17px;
            height: 17px;
            position: relative;
            top: 4px;
            border: 2px solid #fff;
            background: #000;
            background-size: cover;*/
  margin-right: 1em;
}
input[type="checkbox"]:checked + label::before {
  content: "\f14a";
  /*box-sizing: border-box;
            content: '';
            display: inline-block;
            width: 17px;
            height: 17px;
            position: relative;
            top: 4px;
            border: 2px solid #fff;
            margin-right: 1em;
            background: #000 url(../img/checkbox-white.svg) center center no-repeat;
            background-size: cover;*/
}
input[type="checkbox"] + label {
  cursor: pointer;
  margin-left: 0;
  padding-left: 0;
  text-transform: none;
}
.inverted input[type="checkbox"]:not(:checked) + label::before {
  color: #fff;
  /*border: 2px solid #000;
                background: #fff;
                margin-right: 1em;*/
}
.inverted input[type="checkbox"]:checked + label::before {
  color: #fff;
  /*background: #fff url(../img/checkbox-black.svg) center center no-repeat;
                background-size: cover;*/
}
select::-ms-expand {
  display: none;
}
label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
}
label p {
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
}
.row label {
  padding-left: 20px;
}
.partner-events-table > tbody > tr > td,
.partner-events-table > tbody > tr > th,
.partner-events-table > tfoot > tr > td,
.partner-events-table > tfoot > tr > th,
.partner-events-table > thead > tr > td,
.partner-events-table > thead > tr > th {
  border-top: 0;
  border-bottom: 1px solid #b6b6b6;
}
.partner-events-table > tbody > tr.no-border > td,
.partner-events-table > tbody > tr.no-border > th,
.partner-events-table > tfoot > tr.no-border > td,
.partner-events-table > tfoot > tr.no-border > th,
.partner-events-table > thead > tr.no-border > td,
.partner-events-table > thead > tr.no-border > th,
.partner-events-table > tbody > tr > td.no-border,
.partner-events-table > tbody > tr > th.no-border,
.partner-events-table > tfoot > tr > td.no-border,
.partner-events-table > tfoot > tr > th.no-border,
.partner-events-table > thead > tr > td.no-border,
.partner-events-table > thead > tr > th.no-border {
  border-bottom: 0;
}
.partner-events-table > tbody > tr > td.lh,
.partner-events-table > tbody > tr > th.lh,
.partner-events-table > tfoot > tr > td.lh,
.partner-events-table > tfoot > tr > th.lh,
.partner-events-table > thead > tr > td.lh,
.partner-events-table > thead > tr > th.lh {
  line-height: 25px;
}
.partner-events-table .event-date,
.partner-events-table .event-time,
.partner-events-table .event-title {
  font-size: 15px;
  font-weight: 400;
  margin: 0;
  line-height: 1.42857143;
}
@media (max-width: 479px) {
  .partner-events-table .event-date,
  .partner-events-table .event-time,
  .partner-events-table .event-title {
    font-size: 14px;
  }
}
.partner-events-table .event-day,
.partner-events-table .event-partner {
  font-size: 15px;
  font-weight: 400;
}
.partner-events-table .event-description {
  font-size: 15px;
  padding-top: 10px;
}
@media (max-width: 991px) {
  .partner-events-table {
    margin-top: 40px;
  }
}
@media (min-width: 768px) {
  .partner-events-table tr:hover {
    background: rgba(255, 255, 255, 0.05);
  }
}
.partner-events-table tr.highlight:hover,
.partner-events-table tr.highlight {
  background: #fff;
  color: #2b2b2b;
}
.event-calendar {
  font-size: 15px;
}
.event-calendar .bootstrap-datetimepicker-widget table thead tr {
  border-bottom: 1px solid #b6b6b6;
}
.event-calendar .bootstrap-datetimepicker-widget table thead tr:first-child th:hover {
  color: #2b2b2b;
}
.event-calendar .bootstrap-datetimepicker-widget table td span:hover {
  color: #2b2b2b;
}
.event-calendar .bootstrap-datetimepicker-widget table td.day {
  /*background: #ddd;*/
  color: #2b2b2b;
  position: relative;
  z-index: 0;
}
.event-calendar .bootstrap-datetimepicker-widget table td.day:after {
  content: " ";
  position: absolute;
  left: 1px;
  top: 1px;
  right: 1px;
  bottom: 1px;
  background: #ddd;
  border-radius: 5px;
  z-index: -1;
}
.event-calendar .bootstrap-datetimepicker-widget table td.day:hover {
  background: transparent;
}
.event-calendar .bootstrap-datetimepicker-widget table td.day.disabled {
  color: #fff;
  /*background: transparent;*/
}
.event-calendar .bootstrap-datetimepicker-widget table td.day.disabled.new,
.event-calendar .bootstrap-datetimepicker-widget table td.day.disabled.old {
  color: #666;
}
.event-calendar .bootstrap-datetimepicker-widget table td.day.disabled:after {
  background: transparent;
}
.event-calendar .bootstrap-datetimepicker-widget table td.day.today:before {
  border-bottom-color: #2b2b2b;
}
.event-calendar .bootstrap-datetimepicker-widget table td.day.today.disabled:before {
  border-bottom-color: #fff;
}
.event-calendar .bootstrap-datetimepicker-widget table td span.active {
  background: #ddd;
  color: #2b2b2b;
}
.events li > p,
.teaser.events li > p {
  padding: 15px 25px;
}
.events h2,
.teaser.events h2 {
  height: 5rem;
  padding: 0 25px;
  background-color: #000;
  color: #fff;
  font-size: 1.25rem;
  line-height: 5rem;
  border-radius: 10px 10px 0 0;
}
.events h2 [class^="icon-"],
.teaser.events h2 [class^="icon-"] {
  position: relative;
  top: -3px;
  font-size: 2em;
  vertical-align: middle;
}
.events h3,
.teaser.events h3 {
  position: relative;
  padding-right: .5rem;
  padding-left: 2.5rem;
  margin-bottom: 5px;
  font-size: .9375rem;
  font-weight: 400;
  line-height: 1.25;
  text-transform: uppercase;
  height: 36px;
}
.events h3 [class^="icon-"],
.teaser.events h3 [class^="icon-"] {
  font-size: 2rem;
  position: absolute;
  left: 0;
}
.events a,
.teaser.events a {
  display: block;
  position: relative;
  padding: 15px 25px;
  background-color: #fff;
  color: #000;
}
.events a:before,
.teaser.events a:before {
  content: '';
  position: absolute;
  left: 25px;
  bottom: 0;
  width: calc(100% - 25px * 2);
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}
.events a:after,
.teaser.events a:after {
  content: "\f0da";
  font-family: 'llo' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: calc(100% - 25px);
  top: 50%;
  margin-top: -0.7rem;
  font-size: 1.5rem;
}
.events .location,
.teaser.events .location,
.events .time,
.teaser.events .time {
  font-size: .75rem;
}
.events .location,
.teaser.events .location {
  max-height: 1.3rem;
  overflow: hidden;
  display: block;
}
.events li.open .location,
.teaser.events li.open .location {
  max-height: none;
}
.events .date,
.teaser.events .date {
  margin-bottom: 15px;
  color: #404e56;
  font-size: 0.9375rem;
  font-weight: 200;
}
.events[data-height="2"] .slick-slide,
.teaser.events[data-height="2"] .slick-slide {
  height: 258px;
  overflow-y: auto;
}
.events[data-height="2"] li a:after,
.teaser.events[data-height="2"] li a:after {
  content: normal;
}
.teaser.events {
  overflow: initial;
}
#events-pagination .pagination {
  display: block;
  text-align: center;
}
#events-pagination .pagination > li {
  display: inline-block;
  margin: 0 5px;
}
#events-pagination .pagination > li > a,
#events-pagination .pagination > li > span {
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.75);
  border: 0;
  font-weight: 500;
  padding: 0;
  width: 34px;
  height: 28px;
  line-height: 28px;
  color: #fff;
}
#events-pagination .pagination > li.disabled > a,
#events-pagination .pagination > li.disabled > span {
  color: #666;
  visibility: hidden;
}
#events-pagination .pagination > li.active > a,
#events-pagination .pagination > li.active > a:focus,
#events-pagination .pagination > li.active > a:hover,
#events-pagination .pagination > li.active > span,
#events-pagination .pagination > li.active > span:focus,
#events-pagination .pagination > li.active > span:hover {
  background: #fff;
  color: #000;
}
footer {
  /*z-index: 9;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;*/
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 0.8em;
  color: #eee;
  font-weight: 300;
}
footer ul {
  margin: 0;
  text-align: center;
  line-height: 36.5px;
  padding: 0 18.25px;
}
@media (max-width: 1023px) {
  footer ul {
    padding-top: 10.5px;
    padding-bottom: 10.5px;
  }
}
footer ul li {
  text-transform: uppercase;
  display: inline-block;
  padding: 0 25px 0 0;
}
@media (max-width: 1023px) {
  footer ul li {
    display: block;
    padding: 0;
    line-height: 1.5;
  }
}
footer ul li:after {
  display: inline-block;
  content: '';
  width: 1px;
  height: 0.7em;
  position: relative;
  left: 15px;
  background-color: #eee;
}
@media (max-width: 1023px) {
  footer ul li:after {
    display: none;
  }
}
footer ul li:last-child {
  padding: 0;
}
footer ul li:last-child:after {
  content: normal;
}
footer a {
  color: #eee;
  /*font-size: @footerTextSize;*/
}
footer a:hover {
  color: #888888;
}
footer ul.payment-providers li {
  background: url(/img/payment-providers.png) center center no-repeat;
  height: 20px;
  width: 50px;
  display: inline-block;
}
footer ul.payment-providers li:after {
  content: normal;
}
footer ul.payment-providers li.visa {
  background-position: 0 center;
  width: 55px;
}
footer ul.payment-providers li.master-card {
  background-position: -63px center;
  width: 30px;
}
footer ul.payment-providers li.maestro {
  background-position: -100px center;
  width: 27px;
}
footer ul.payment-providers li.giropay {
  background-position: -134px center;
  width: 42px;
}
footer ul.payment-providers li.sofort-ueberweisung {
  background-position: -184px center;
  width: 56px;
}
footer ul.payment-providers li.paypal {
  background-position: -247px center;
  width: 71px;
}
body > header {
  position: relative;
  z-index: 1050;
  width: 100%;
  left: 0;
  top: 0;
}
body > header .logo {
  position: absolute;
  left: 35px;
  top: 35px;
}
body > header .logo img {
  display: block;
  width: 165px;
  height: auto;
}
@media all and (max-width: 767px) {
  body > header .logo img {
    left: -20px;
    top: -20px;
    position: absolute;
  }
}
@media all and (min-width: 768px) {
  body > header .logo img {
    width: 213px;
    height: 57px;
  }
}
body > header .toggle-nav {
  z-index: 2;
  width: auto;
  height: auto;
  background-color: transparent;
  position: fixed;
  top: 40px;
  right: 17px;
  color: #fff;
  font-size: 20px;
  line-height: 20px;
}
@media all and (min-width: 768px) {
  body > header .toggle-nav {
    top: 40px;
    right: 17px;
  }
}
body > header .toggle-nav .icon {
  font-size: 21px;
  margin: 0;
  padding: 0;
  /*            color: #fff;/*@navHamburgerColor;*/
  text-shadow: none;
  float: right;
  margin-left: 10px;
  position: relative;
  line-height: 17px;
  /*@media @mq2col {
                text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.75);
            }*/
}
body > header .toggle-nav.active {
  display: none;
}
body > header .sticky-header-background {
  z-index: 1;
  position: fixed;
  right: 0;
  top: 12px;
  height: 38px;
  width: 53px;
  /*background-color: #000;*/
}
@media all and (min-width: 768px) {
  body > header .sticky-header-background {
    top: 31px;
  }
}
body > header .navbar {
  border: 0;
  border-radius: 0;
  min-height: 50px;
  height: 100px;
  transition: height 200ms;
}
body > header .navbar .navbar-brand {
  position: relative;
  padding: 23px 15px;
}
body > header .navbar .navbar-brand .logo-horizontal {
  display: none;
}
body > header .navbar .navbar-brand .logo-ci {
  display: inline-block;
  position: relative;
  white-space: nowrap;
}
body > header .navbar .navbar-brand .logo-ci .lloicon-llo-logo {
  position: relative;
  display: block;
  font-size: 66px;
  margin: -19.5px 0 0;
  left: 76px;
  line-height: 27px;
}
body > header .navbar .navbar-brand .logo-ci .lloicon-llo-logo ~ .llo-text-bottom {
  position: relative;
  display: inline-block;
  white-space: nowrap;
  margin-top: 6px;
  line-height: 17px;
  text-transform: uppercase;
  font-size: 23px;
}
body > header .navbar .nav-container {
  position: relative;
}
body > header .navbar .toggle-nav {
  position: relative;
  /*top: auto;/*34px;*/
  /*right: auto;/*35px;*/
  top: 25px;
  right: 17px;
  margin-top: 15px;
  padding: 0;
  transition: top 200ms;
}
body > header .navbar .toggle-nav > span {
  display: inline-block;
}
body > header .navbar .navbar-nav {
  position: absolute;
  top: 0;
  right: 15px;
  padding-top: 25px;
  padding-bottom: 25px;
  transition: all 200ms;
}
body > header .navbar .navbar-nav > li > a {
  text-transform: uppercase;
  font-size: 20px;
  line-height: 20px;
}
body > header .navbar .navbar-nav > li:last-child > a {
  padding-right: 0;
}
body > header .navbar.navbar-default {
  background: #fff;
  color: #b0b0b0;
}
body > header .navbar.navbar-default .navbar-brand {
  color: #2b2b2b;
}
body > header .navbar.navbar-default .toggle-nav {
  color: #777;
  margin-right: 6px;
}
body > header .navbar.navbar-default .toggle-nav.active {
  display: block;
  color: #2b2b2b;
}
body > header .navbar.navbar-default.transparent {
  background: transparent;
}
body > header .navbar.navbar-default.transparent .navbar-brand,
body > header .navbar.navbar-default.transparent .navbar-nav {
  display: none;
}
body > header .navbar.navbar-default.transparent .toggle-nav {
  color: #fff;
}
body > header .navbar.navbar-default.transparent.hidden-on-transparent {
  display: none;
}
body > header .navbar.narrow {
  height: 50px;
}
body > header .navbar.narrow .navbar-brand {
  padding: 17px 15px;
}
body > header .navbar.narrow .navbar-brand .logo-horizontal {
  display: block;
}
@media (max-width: 479px) {
  body > header .navbar.narrow .navbar-brand .logo-horizontal {
    width: 230px;
  }
}
body > header .navbar.narrow .navbar-brand .logo-vertical {
  display: none;
}
body > header .navbar.narrow .navbar-brand .logo-ci .lloicon-llo-logo {
  left: 0;
  font-size: 40px;
  line-height: 23px;
  float: left;
}
body > header .navbar.narrow .navbar-brand .logo-ci .lloicon-llo-logo ~ .llo-text-bottom {
  display: inline-block;
  float: right;
  font-size: 20px;
  line-height: 20px;
  margin-left: 17px;
  margin-top: -9px;
}
body > header .navbar.narrow .toggle-nav {
  top: 0;
}
body > header .navbar.narrow .navbar-nav {
  padding-top: 0;
  padding-bottom: 0;
}
.text-label {
  display: inline-block;
  position: relative;
  padding: 2px 8px 1px;
  top: -3px;
  background-color: #000;
  color: #fff;
  font-family: 'Roboto', 'Arial', sans-serif !important;
  font-size: .75rem;
  font-weight: 300;
  line-height: inherit;
  text-transform: uppercase;
  vertical-align: middle;
}
.contrast .text-label {
  border: 1px solid #fff;
}
.number-label {
  display: inline-block;
  width: 37px;
  height: 23px;
  background-color: #fff;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid #fff;
  color: #000;
  font-weight: 500;
  line-height: 23px;
  font-size: 18px;
  text-align: center;
}
.contrast .number-label {
  color: #fff;
  background-color: #000;
  border: 1px solid #000;
}
button.close-nav {
  /*background-color: #fff;*/
  color: #fff;
}
body.nav-open {
  /*overflow: hidden;
    padding-left: 17px;*/
}
.main-nav {
  z-index: 1000;
  position: fixed;
  top: 0;
  overflow-y: auto;
  font-size: 15px;
  padding-top: 100px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  transition: transform 0.3s ease;
}
.main-nav.narrow {
  padding-top: 50px;
}
.main-nav.transparent {
  padding-top: 0;
}
@media all and (max-width: 767px) {
  .main-nav {
    right: -250px;
    width: 250px;
  }
}
@media all and (min-width: 768px) {
  .main-nav {
    right: -250px;
    width: 250px;
  }
}
@media all and (min-width: 1200px) {
  .main-nav {
    right: -280px;
    width: 280px;
  }
}
.main-nav li {
  color: #fff;
  /*&:hover {
            color: @navFontColor;
            a {
                color: @navActiveColor;
            }
        }*/
}
.main-nav li a {
  color: #fff;
  display: block;
  width: 100%;
}
.main-nav .close-nav-wrapper {
  padding-top: 10px;
  padding-right: 10px;
  text-align: right;
}
.main-nav .close-nav {
  padding: 0;
  width: auto;
  height: auto;
  background-color: transparent;
  /*position: absolute;
        right: 10px;
        top: 10px;*/
}
.main-nav .close-nav .icon {
  font-size: 25px;
  color: #fff;
  margin: 0;
}
.main-nav ul {
  padding: 0 42px;
}
@media all and (max-width: 767px) and (orientation: landscape) {
  .main-nav ul {
    padding: 20px;
  }
}
@media (min-width: 768px) {
  .main-nav ul {
    padding: 42px;
  }
}
.main-nav ul + ul {
  padding-top: 0;
}
.main-nav ul li {
  text-transform: uppercase;
  display: block;
  border-bottom: 1px solid rgba(255, 255, 255, 0.8);
  /*&.active a {
                color: @navActiveColor;
                span {
                    color: @navActiveColor;
                }
            }*/
}
.main-nav ul li a {
  line-height: 3rem;
}
.main-nav ul li a span {
  padding-right: 10px;
}
.main-nav ul li:last-child {
  border-bottom: none;
}
.main-nav ul li span {
  font-size: 20px;
  display: inline-block;
  width: 34px;
  text-align: center;
  float: left;
  margin-top: 12px;
}
.main-nav ul li.space-after {
  border-bottom: none;
  margin-bottom: 40px;
}
.main-nav ul .icon {
  font-size: 15px;
}
.main-nav ul .icon.icon-menu-partner {
  font-size: 20px;
}
.main-nav ul .icon.icon-menu-service {
  font-size: 20px;
}
.main-nav.on-screen:before {
  content: '';
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  left: -100vw;
  background-color: rgba(0, 0, 0, 0.6);
}
@media all and (max-width: 767px) {
  .main-nav.on-screen {
    transform: translate(-250px, 0);
  }
}
@media all and (min-width: 768px) {
  .main-nav.on-screen {
    transform: translate(-250px, 0);
  }
}
@media all and (min-width: 1200px) {
  .main-nav.on-screen {
    transform: translate(-280px, 0);
  }
}
.main-nav .nav-contact {
  display: inline-block;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 42px 42px 42px 42px;
}
@media all and (max-width: 767px) and (orientation: landscape) {
  .main-nav .nav-contact {
    padding: 20px;
    position: relative;
  }
}
.main-nav .nav-contact .social,
.main-nav .nav-contact .phone,
.main-nav .nav-contact .mail {
  display: block;
  padding-bottom: 5px;
  margin-bottom: 10px;
}
.main-nav .nav-contact .social a,
.main-nav .nav-contact .phone a,
.main-nav .nav-contact .mail a {
  color: #fff;
}
.main-nav .nav-contact .social a span,
.main-nav .nav-contact .phone a span,
.main-nav .nav-contact .mail a span {
  position: relative;
  top: 2px;
  color: #626263;
}
.main-nav .nav-contact .social a span.llo-icon,
.main-nav .nav-contact .phone a span.llo-icon,
.main-nav .nav-contact .mail a span.llo-icon {
  font-size: 25px;
  padding-right: 10px;
  color: #fff;
}
.main-nav .nav-contact .social a span.llo-icon.icon-telefon,
.main-nav .nav-contact .phone a span.llo-icon.icon-telefon,
.main-nav .nav-contact .mail a span.llo-icon.icon-telefon,
.main-nav .nav-contact .social a span.llo-icon.icon-mail,
.main-nav .nav-contact .phone a span.llo-icon.icon-mail,
.main-nav .nav-contact .mail a span.llo-icon.icon-mail {
  font-size: 15px;
}
.main-nav .nav-contact .social a:hover,
.main-nav .nav-contact .phone a:hover,
.main-nav .nav-contact .mail a:hover {
  text-decoration: none;
  color: #ddd;
}
.main-nav .nav-contact .social a:hover span,
.main-nav .nav-contact .phone a:hover span,
.main-nav .nav-contact .mail a:hover span {
  color: #ddd;
}
.main-nav .nav-contact .social {
  /*border-bottom: 1px solid rgba(200, 200, 200, 0.5);*/
  color: #626263;
}
.main-nav .nav-contact .social a span:hover {
  color: #ddd;
}
.main-nav .nav-contact .phone {
  font-size: 14px;
}
.main-nav .nav-contact .mail {
  font-size: 12px;
}
.teaser {
  border-radius: 10px;
  overflow: hidden;
}
.slick-dots {
  height: 71px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
  margin: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 220px;
  text-align: center;
}
.slick-dots li {
  cursor: pointer;
  display: inline-block;
  border-radius: 1rem;
  background-color: #b2b2b2;
  text-align: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 25px;
  height: 25px;
  line-height: 24px;
  margin-right: 10px;
}
.slick-dots li.hidden {
  display: none;
}
.slick-dots li button {
  color: #fff;
  font-size: 1rem;
}
.slick-dots li.slick-active {
  background-color: #000;
}
.slick-arrow {
  position: absolute;
  bottom: -45px;
  font-family: 'llo';
  font-size: 20px;
  color: #000;
}
.slick-arrow:hover {
  color: #949494;
}
.slick-arrow.slick-prev {
  left: 20px;
}
.slick-arrow.slick-prev:before {
  content: '\f053';
}
.slick-arrow.slick-next {
  right: 20px;
}
.slick-arrow.slick-next:before {
  content: '\f054';
}
.fullscreen-slider-wrapper .slider-nav,
.fullscreen-slider-wrapper .slider-scroll-down {
  display: none;
}
.fullscreen-slider-wrapper .slider-fullscreen-vertical .slide {
  margin-bottom: 70px;
}
@media all and (min-width: 1200px) {
  .fullscreen-slider-wrapper {
    /*.page-headline {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
        }*/
  }
  .fullscreen-slider-wrapper .slider-nav {
    display: block;
    position: fixed;
    right: 50px;
    top: 45%;
    z-index: 9;
  }
  .fullscreen-slider-wrapper .slider-nav li {
    text-align: right;
    margin: 10px 0;
  }
  .fullscreen-slider-wrapper .slider-nav li a {
    font-size: .875rem;
    color: #7e7e7e;
  }
  .fullscreen-slider-wrapper .slider-nav li a span {
    opacity: 0;
    -webkit-transition: opacity .2s ease;
    transition: opacity .2s ease;
  }
  .fullscreen-slider-wrapper .slider-nav li a:after {
    display: inline-block;
    content: '';
    width: 11px;
    height: 11px;
    margin-left: 25px;
    background-color: #fff;
    border-radius: 10px;
    border: 3px solid #000;
    -webkit-transition: box-shadow 0.2s ease;
    transition: box-shadow 0.2s ease;
  }
  .fullscreen-slider-wrapper .slider-nav li.active a {
    color: #fff;
    text-shadow: 1px 1px #000;
  }
  .fullscreen-slider-wrapper .slider-nav li.active a:after {
    box-shadow: 0 0 3px #fff;
    -webkit-transition: box-shadow 0.2s ease;
    transition: box-shadow 0.2s ease;
  }
  .fullscreen-slider-wrapper .slider-nav:hover li a span {
    opacity: 1;
  }
  .fullscreen-slider-wrapper .slider-scroll-down {
    display: block;
    position: fixed;
    width: 100px;
    height: 25px;
    bottom: 25px;
    left: 50%;
    margin-left: -50px;
    color: #fff;
    text-align: center;
    line-height: 25px;
    font-size: 1.5rem;
  }
  .fullscreen-slider-wrapper .slider-fullscreen-vertical .slide {
    min-height: 100vh;
    margin-bottom: 0;
  }
  .fullscreen-slider-wrapper .slider-fullscreen-vertical .slide:first-child {
    padding-top: 0;
  }
}
.teaser {
  display: block;
  border-radius: 10px;
}
.teaser h2,
.teaser h3 {
  text-transform: uppercase;
}
.teaser h2 {
  font-size: 1.4rem;
  line-height: 1.25;
  margin-bottom: 5px;
}
.teaser h3 {
  font-size: .75rem;
  font-weight: 500;
  margin-bottom: 20px;
}
.teaser p {
  font-size: .75rem;
}
.teaser.partner {
  opacity: 1;
  transition: opacity 0.3s ease-in, height 0.2s ease-in;
}
.teaser.partner.hidden {
  opacity: 0;
}
.teaser.partner p {
  max-height: 3em;
  overflow: hidden;
}
.teaser a {
  color: inherit;
  font-size: .75rem;
}
.teaser a.more-link {
  padding-right: 0.875rem;
  margin-top: 5px;
  display: inline-block;
  position: relative;
}
.teaser a.more-link:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: .325rem 0 .325rem .325rem;
  border-color: transparent transparent transparent #000;
  position: absolute;
  right: 0;
  top: .2375rem;
}
.teaser span.more-link {
  padding-right: 0.875rem;
  margin-top: 5px;
  display: inline-block;
  position: relative;
}
.teaser span.more-link:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: .325rem 0 .325rem .325rem;
  border-color: transparent transparent transparent #000;
  position: absolute;
  right: 0;
  top: .2375rem;
}
.teaser > img {
  position: absolute;
  bottom: 0;
}
.teaser .text {
  padding: 20px;
  height: 160px;
}
.teaser .text.has-company-logo {
  padding-right: 115px;
}
.teaser .text.has-company-logo .company-logo {
  display: block;
  width: 90px;
  height: 90px;
  position: absolute;
  top: 20px;
  right: 20px;
  border: 1px solid #000;
  border-radius: 10px;
  background: #fff;
  overflow: hidden;
}
.teaser .text.has-company-logo .company-logo img {
  width: 100%;
  height: 100%;
}
.order-teasers {
  margin-left: 0;
  margin-bottom: 80px;
}
@media all and (min-width: 1200px) {
  .order-teasers {
    margin-left: 6px;
  }
}
.order-teasers:after {
  content: '';
  display: block;
  clear: both;
}
.order-teaser {
  float: left;
  background: white;
  border-radius: 10px;
  width: 300px;
  margin: 6px;
  padding: 20px;
  color: black;
}
@media all and (max-width: 767px) {
  .order-teaser {
    margin: 6px 0 ;
  }
}
@media all and (min-width: 768px) {
  .order-teaser {
    margin: 5px;
  }
}
/*
.teaser-list {
  padding: 35px 0;

  h1, h2, h3, h4 {
    text-transform: uppercase;
  }

  h3 {
    margin-bottom: 5px;
    line-height: 1.25;
  }

  > ul {
    margin-top: 35px;
    margin-left: -(@gutter / 2);
    margin-right: -(@gutter / 2);

    &:after {
      content: '';
      display: block;
      clear: both;
    }

    > li {
      width: @content-width-phone;
      height: 320px;
      margin: (@gutter / 2);
      background-color: #fff;
      border-radius: @border-radius;
      display: block;
      overflow: hidden;
      float: left;

      > img {
        position: absolute;
        bottom: 0;
      }
    }

    > li:nth-child(n+6) {
      height: 380px;
    }

    > li.events {
      width: @content-width-phone;
      height: (320px * 2) + @gutter;
    }
  }

  .text {
    padding: @teaser-padding;

    > a {
      padding-right: 0.875rem;
      margin-top: 5px;
      display: inline-block;
      position: relative;

      &:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: .325rem 0 .325rem .325rem;
        border-color: transparent transparent transparent #000;
        position: absolute;
        right: 0;
        top: .2375rem;
      }
    }

    p, a {
      font-size: .75rem;
      font-weight: normal;
    }

    p + p {
      margin-top: 1rem;
    }

    .float-right {
      float: right;
    }

    &:nth-child(2n) {
      position: absolute;
      top: 50%;
      height: 50%;
      background-color: lightgrey;
    }

    &.has-company-logo {
      @company-logo-size: 90px;
      @company-logo-padding: 5px;

      padding-right: (@company-logo-size + @teaser-padding + @company-logo-padding);

      .company-logo {
        display: block;
        width: @company-logo-size;
        height: @company-logo-size;
        padding: @company-logo-padding;
        position: absolute;
        top: @teaser-padding;
        right: @teaser-padding;
        border: 1px solid #000;
        border-radius: 10px;
        background: #fff;

        line-height: (@company-logo-size - (@company-logo-padding * 2) - 2px);
        text-align: center;

        > img {
          vertical-align: baseline;
          max-height: 100%;; //~'calc(100% - 2px)';
          max-width: 100%;
        }
      }
    }
  }

  // Phone ONLY!
  //=================================================
  @media @mq1col {

  }

  // Tablet
  //=================================================
  @media @mq2col {

  }

  // Desktop and up
  //=================================================
  @media @mq3col {

  }
}*/
table.orders th {
  font-weight: bold;
  padding-right: 0.5em;
  text-align: left;
}
table.orders tr.separator td,
table.orders tr.separator th {
  padding-top: 0.5em;
}
[data-page="404"] {
  min-height: 100%;
  background: linear-gradient(transparent 0, #000 1600px), url('/img/LLOweb_muc_1920x1113_cut.jpg') top no-repeat;
  background-size: cover;
}
@media all and (min-width: 768px) {
  [data-page="404"] {
    background: url('/img/LLOweb_muc_1920x1113_cut.jpg') top no-repeat #000;
    background-size: cover;
    background-attachment: fixed;
  }
}
[data-page="404"] .center-text {
  width: 100%;
  text-align: center;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
}
[data-page="404"] .center-text h1 {
  color: #eee;
  text-transform: uppercase;
  font-weight: 500;
}
[data-page="blog"] {
  /*  background: #fff url('/img/LLOweb_muc_1920x1113_cut_white.jpg') left 50px no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  //background-size: cover;
  @media @mq2col {
      background: #fff url('/img/LLOweb_muc_1920x1113_cut_white.jpg') left 50px no-repeat;
      -webkit-background-size: cover;
      background-size: cover;
      background-attachment: fixed;
  }*/
  /*    background: url('/img/partners_background_small.jpg') 10% top no-repeat #000;
    background-size: 100% auto;
    background-attachment: fixed;
    @media @mq2col {
        background: url('/img/partners_background.jpg') 10% top no-repeat #000;
        background-size: 100% auto;
        background-attachment: fixed;
    }*/
}
[data-page="blog"] > header .toggle-nav {
  color: #2b2b2b;
}
[data-page="blog"] .headline {
  color: #2b2b2b;
}
[data-page="blog"] > header .navbar.navbar-default {
  background: #000;
}
[data-page="blog"] > header .navbar.navbar-default .navbar-nav > li > a,
[data-page="blog"] > header .navbar.navbar-default .toggle-nav,
[data-page="blog"] > header .navbar.navbar-default .navbar-brand {
  color: #fff;
}
[data-page="blog"] > header .navbar.navbar-default .toggle-nav.active,
[data-page="blog"] > header .navbar.navbar-default .navbar-nav > li > a:hover {
  color: #eee;
}
[data-page="blog"] .panel-body.copytext img {
  height: 207px;
}
@media (max-width: 1199px) {
  [data-page="blog"] .panel-body.copytext img {
    height: 167px;
  }
}
@media (max-width: 991px) {
  [data-page="blog"] .panel-body.copytext img {
    height: 272px;
  }
}
@media (max-width: 767px) {
  [data-page="blog"] .panel-body.copytext img {
    height: auto;
  }
}
[data-page="blog"] .blog-section p {
  margin: 0;
}
[data-page="blog"] .partner-details-box {
  margin-bottom: 30px;
}
[data-page="blog"] .partner-details-box .light {
  background: rgba(255, 255, 255, 0.75);
  color: #2b2b2b;
}
[data-page="blog"] .partner-details-box .light a {
  color: #2b2b2b;
}
[data-page="blog"] .partner-details-box.full-height {
  height: 300px;
}
@media (min-width: 992px) {
  [data-page="blog"] .partner-details-box.full-height {
    position: absolute;
    left: 15px;
    right: 15px;
    top: 0;
    bottom: 0;
    height: auto;
  }
}
[data-page="blog"] .partner-details-box .partner-details-box-complete {
  border-radius: 6px;
  border-top-left-radius: 27px;
  border-bottom-right-radius: 27px;
  -webkit-border-radius: 6px;
  -webkit-border-top-left-radius: 27px;
  -webkit-border-bottom-right-radius: 27px;
  padding: 30px;
  position: relative;
  overflow: hidden;
}
[data-page="blog"] .partner-details-box .partner-details-box-top {
  border-radius: 0;
  border-top-left-radius: 27px;
  border-top-right-radius: 6px;
  padding: 30px;
  position: relative;
}
[data-page="blog"] .partner-details-box .partner-details-box-bottom {
  border-radius: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 27px;
  padding: 30px;
  position: relative;
}
[data-page="blog"] .partner-details-box h1 {
  font-size: 60px;
  font-weight: 200;
  margin-top: 0;
}
[data-page="blog"] .partner-details-box h2 {
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 4.5px;
  margin-top: 0;
  margin-bottom: 21px;
}
[data-page="blog"] .partner-details-box.partner-details-teaser h1 {
  margin-top: 10.5px;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  [data-page="blog"] .partner-details-box.partner-details-teaser h1 {
    font-size: 24px;
    padding-right: 48px;
  }
}
[data-page="blog"] .partner-details-box.partner-details-teaser h2 {
  margin-top: 0;
  margin-bottom: 0;
}
[data-page="blog"] .partner-details-box.partner-details-teaser h4 {
  font-size: 24px;
  margin-bottom: 21px;
}
[data-page="blog"] .partner-details-box.partner-details-teaser ul {
  padding-left: 20px;
}
[data-page="blog"] .partner-details-box.partner-details-teaser .partner-details-icon {
  position: absolute;
  right: 30px;
  top: 30px;
  font-size: 90px;
}
[data-page="blog"] .partner-details-box.partner-details-teaser .partner-details-box-middle img {
  display: block;
}
[data-page="blog"] .partner-details-box.partner-details-teaser .partner-details-box-bottom {
  border-bottom-right-radius: 32px;
}
[data-page="blog"] .partner-details-box.partner-details-contact table * {
  font-size: 14px;
  vertical-align: top;
}
[data-page="blog"] .partner-details-box.partner-details-contact .row > div:first-child {
  margin-bottom: 30px;
}
[data-page="blog"] .blog-header {
  border-radius: 10px;
  margin-bottom: 20px;
}
@media all and (max-width: 767px) {
  [data-page="blog"] .blog-header {
    width: 300px;
    height: 118px;
  }
}
@media all and (min-width: 768px) {
  [data-page="blog"] .blog-header {
    width: 620px;
    height: 244px;
  }
}
@media all and (min-width: 1200px) {
  [data-page="blog"] .blog-header {
    width: 940px;
    height: 370px;
  }
}
[data-page="blog"] .blog-image {
  border-radius: 10px;
  float: left;
  margin: 5px 12px 7px 0;
  width: 300px;
}
[data-page="blog"] .blog-related {
  width: 250px;
  height: 99px;
  margin-bottom: 7px;
}
[data-page="blog"] h2 {
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  [data-page="blog"] .image-left {
    float: left;
    margin-right: 10px;
  }
  [data-page="blog"] .image-right {
    float: right;
    margin-left: 10px;
  }
}
.teaser.related {
  position: relative;
}
.teaser.related .slick-slider {
  height: 600px;
  background-color: white;
}
.teaser.related .slick-dots {
  top: auto;
  bottom: 0;
}
.teaser.related .slick-arrow {
  bottom: 25px;
}
.teaser.related h2 {
  height: 5rem;
  padding: 0 25px;
  background-color: #000;
  color: #fff;
  font-size: 1.25rem;
  line-height: 5rem;
  margin-bottom: 0;
}
.teaser.related h3 {
  position: relative;
  padding-right: .5rem;
  padding-left: 0;
  margin-bottom: 5px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  text-transform: uppercase;
}
.teaser.related a {
  display: block;
  position: relative;
  padding: 15px 25px 10px;
  background-color: #fff;
  color: #000;
}
.teaser.related li + li a:before {
  content: '';
  position: absolute;
  left: 25px;
  top: 0;
  width: calc(100% - 25px * 2);
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}
.teaser.related .more {
  font-size: .9375rem;
}
.teaser.related .more:after {
  content: "\f0da";
  font-family: 'llo' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  top: 1px;
  left: 4px;
}
[data-page="faq"] {
  /*background: #fff url('/img/LLOweb_muc_1920x1113_cut.jpg') left 50px no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  //background-size: cover;
  @media @mq2col {
      background: #fff url('/img/LLOweb_muc_1920x1113_cut.jpg') left 50px no-repeat;
      -webkit-background-size: cover;
      background-size: cover;
      background-attachment: fixed;
  }*/
}
[data-page="faq"] select option {
  background-color: #000;
}
[data-page="faq"] .separator {
  margin: 20px 0;
}
[data-page="faq"] .form-group {
  margin-bottom: 0;
}
[data-page="faq"] .left-column,
[data-page="faq"] .right-column {
  padding-bottom: 0;
}
[data-page="faq"] .left-column {
  position: relative;
  height: auto;
}
@media all and (min-width: 768px) {
  [data-page="faq"] .left-column {
    padding-right: 40px;
  }
}
[data-page="faq"] .right-column {
  height: 100%;
  position: relative;
}
@media all and (min-width: 768px) {
  [data-page="faq"] .right-column {
    padding-left: 40px;
  }
  [data-page="faq"] .right-column:before {
    content: '';
    position: absolute;
    width: 1px;
    margin-top: 50px;
    background-color: #fff;
    left: 0;
    top: 0;
    height: 508px;
  }
}
@media all and (min-width: 768px) {
  [data-page="faq"] .report-problem .right-column {
    margin-top: 22px;
  }
  [data-page="faq"] .report-problem .right-column:before {
    margin-top: 27px;
    height: 743px;
  }
  [data-page="faq"] .report-problem textarea {
    height: 218px;
  }
}
@media all and (min-width: 1200px) {
  [data-page="faq"] .report-problem .right-column:before {
    height: 647px;
  }
}
[data-page="faq"] .contact-form[data-subject="report_problem"] .right-column {
  margin-top: 24px;
}
[data-page="faq"] .contact-form[data-subject="report_problem"] .mobile-padding {
  padding-left: 20px;
}
[data-page="faq"] .contact-form[data-subject="report_problem"] textarea {
  height: 217px;
}
@media all and (min-width: 768px) {
  [data-page="faq"] .contact-form textarea {
    height: 388px;
  }
  [data-page="faq"] .contact-form textarea#report_problem_contact {
    height: auto;
  }
  [data-page="faq"] .contact-form textarea#report_problem_description {
    height: 210px;
  }
}
[data-page="faq"] .separation-border:after {
  content: '';
  position: absolute;
  left: 0;
  top: 10px;
  width: 1px;
  height: 80%;
  background-color: #fff;
}
[data-page="faq"] .slide {
  padding-top: 100px;
}
[data-page="faq"] .faqs a {
  color: #000;
}
[data-page="kontakt"] {
  /*background: #fff url('/img/LLOweb_muc_1920x1113_cut.jpg') left 50px no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  //background-size: cover;
  @media @mq2col {
      background: #fff url('/img/LLOweb_muc_1920x1113_cut.jpg') left 50px no-repeat;
      -webkit-background-size: cover;
      background-size: cover;
      background-attachment: fixed;
  }*/
}
[data-page="kontakt"] .partner-details-box {
  margin-bottom: 30px;
}
[data-page="kontakt"] .partner-details-box .dark {
  background: rgba(0, 0, 0, 0.75);
}
[data-page="kontakt"] .partner-details-box.full-height {
  height: 300px;
}
@media (min-width: 992px) {
  [data-page="kontakt"] .partner-details-box.full-height {
    position: absolute;
    left: 15px;
    right: 15px;
    top: 0;
    bottom: 0;
    height: auto;
  }
}
[data-page="kontakt"] .partner-details-box .partner-details-box-complete {
  border-radius: 6px;
  border-top-left-radius: 27px;
  border-bottom-right-radius: 27px;
  -webkit-border-radius: 6px;
  -webkit-border-top-left-radius: 27px;
  -webkit-border-bottom-right-radius: 27px;
  padding: 30px;
  position: relative;
  overflow: hidden;
}
[data-page="kontakt"] .partner-details-box .partner-details-box-top {
  border-radius: 0;
  border-top-left-radius: 27px;
  border-top-right-radius: 6px;
  padding: 30px;
  position: relative;
}
[data-page="kontakt"] .partner-details-box .partner-details-box-bottom {
  border-radius: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 27px;
  padding: 30px;
  position: relative;
}
[data-page="kontakt"] .partner-details-box h1 {
  font-size: 60px;
  font-weight: 200;
  margin-top: 0;
}
[data-page="kontakt"] .partner-details-box h2 {
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 4.5px;
  margin-top: 0;
  margin-bottom: 21px;
}
[data-page="kontakt"] .partner-details-box.partner-details-teaser h1 {
  margin-top: 10.5px;
  margin-bottom: 0;
  padding-right: 100px;
}
[data-page="kontakt"] .partner-details-box.partner-details-teaser h2 {
  margin-top: 0;
  margin-bottom: 0;
  padding-right: 100px;
}
[data-page="kontakt"] .partner-details-box.partner-details-teaser h4 {
  font-size: 24px;
  margin-bottom: 21px;
}
[data-page="kontakt"] .partner-details-box.partner-details-teaser ul {
  padding-left: 20px;
}
[data-page="kontakt"] .partner-details-box.partner-details-teaser .partner-details-icon {
  position: absolute;
  right: 30px;
  top: 30px;
  font-size: 90px;
}
[data-page="kontakt"] .partner-details-box.partner-details-teaser .partner-details-box-middle img {
  display: block;
}
[data-page="kontakt"] .partner-details-box.partner-details-contact table * {
  font-size: 14px;
  vertical-align: top;
}
[data-page="kontakt"] .partner-details-box.partner-details-contact .row > div:first-child {
  margin-bottom: 30px;
}
[data-page="kontakt"] .btn {
  background: #222;
  color: #ccc;
  border: 0;
  min-width: 136px;
  width: auto;
  height: auto;
}
[data-page="kontakt"] .btn:hover {
  background: #191919;
}
[data-page="kontakt"] .btn.btn-primary {
  background: #fff;
  color: #2b2b2b;
}
[data-page="kontakt"] .btn.btn-primary:hover {
  background: #ddd;
}
[data-page="kontakt"] label {
  font-size: 14px;
  font-weight: 300;
  padding: 0;
  text-transform: uppercase;
}
[data-page="howto"] {
  /*background: #fff url('/img/LLOweb_muc_1920x1113_cut.jpg') left 50px no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
    //background-size: cover;
    @media @mq2col {
        background: #fff url('/img/LLOweb_muc_1920x1113_cut.jpg') left 50px no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
        background-attachment: fixed;
    }*/
  color: #fff;
}
[data-page="howto"] h3 {
  font-size: 40px;
  font-weight: 300;
}
[data-page="howto"] .learn-more {
  display: inline-block;
  margin-top: 10px;
}
[data-page="howto"] .learn-more .icon {
  position: relative;
  top: 1px;
  margin-right: 5px;
}
[data-page="howto"] .slider {
  margin-bottom: 70px;
}
[data-page="howto"] .numbered-headline .icon-Life-less-karte:before {
  font-size: 3rem;
  position: relative;
  top: 13px;
}
[data-page="howto"] .numbered-headline .icon-Partner-auswaehlen:before {
  font-size: 2.4rem;
  position: relative;
  top: 13px;
}
[data-page="howto"] .numbered-headline .icon-vorteile:before {
  font-size: 2.2rem;
  position: relative;
  top: 5px;
}
[data-page="imprint"] {
  /*background: #fff url('/img/LLOweb_muc_1920x1113_cut.jpg') left 50px no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  //background-size: cover;
  @media @mq2col {
      background: #fff url('/img/LLOweb_muc_1920x1113_cut.jpg') left 50px no-repeat;
      -webkit-background-size: cover;
      background-size: cover;
      background-attachment: fixed;
  }*/
}
[data-page="index"] {
  background: #000;
  height: 100% !important;
  /*
  background: url('../img/background-tile.jpg') center top repeat #000;
*/
  /*main {
    background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOTIwIDEwODAiPjxkZWZzPjxyYWRpYWxHcmFkaWVudCBpZD0ibWFzay1ncmFkaWVudCI+PHN0b3Agb2Zmc2V0PSIuNSIgc3RvcC1jb2xvcj0iI2ZmZiIgc3RvcC1vcGFjaXR5PSIxIiAvPjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iI2ZmZiIgc3RvcC1vcGFjaXR5PSIwIiAvPjwvcmFkaWFsR3JhZGllbnQ+PHJhZGlhbEdyYWRpZW50IGlkPSJmaWxsIj48c3RvcCBvZmZzZXQ9IjAiIHN0b3AtY29sb3I9IiM1NjU2NTYiIC8+PHN0b3Agb2Zmc2V0PSIuNSIgc3RvcC1jb2xvcj0iIzJhMmEyYSIgLz48L3JhZGlhbEdyYWRpZW50PjxtYXNrIGlkPSJtYXNrIiBtYXNrQ29udGVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCI+PHJlY3Qgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNtYXNrLWdyYWRpZW50KSIgLz48L21hc2s+PC9kZWZzPjxyZWN0IHdpZHRoPSIxOTIwIiBoZWlnaHQ9IjEwODAiIGZpbGw9InVybCgjZmlsbCkiIG1hc2s9InVybCgjbWFzaykiIC8+PC9zdmc+') center -150px no-repeat;
    background-size: 2880px auto;
  }*/
  /*h1, .subtitle {
    text-align: center;
    color: @text-color-bright;
  }*/
  /*.index-window {
    height: 50%;
  }*/
}
[data-page="index"] .html-mobile-background {
  display: none;
}
[data-page="index"] .background-layer {
  background: #000;
}
[data-page="index"] .partner-details-box {
  margin-bottom: 30px;
}
[data-page="index"] .partner-details-box .dark {
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
}
[data-page="index"] .partner-details-box.full-height {
  height: 300px;
}
@media (min-width: 992px) {
  [data-page="index"] .partner-details-box.full-height {
    position: absolute;
    left: 15px;
    right: 15px;
    top: 0;
    bottom: 0;
    height: auto;
  }
}
[data-page="index"] .partner-details-box .partner-details-box-complete {
  border-radius: 6px;
  border-top-left-radius: 27px;
  border-bottom-right-radius: 27px;
  -webkit-border-radius: 6px;
  -webkit-border-top-left-radius: 27px;
  -webkit-border-bottom-right-radius: 27px;
  padding: 30px;
  position: relative;
  overflow: hidden;
}
[data-page="index"] .partner-details-box .partner-details-box-top {
  border-radius: 0;
  border-top-left-radius: 27px;
  border-top-right-radius: 6px;
  padding: 30px;
  position: relative;
}
[data-page="index"] .partner-details-box .partner-details-box-bottom {
  border-radius: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 27px;
  padding: 30px;
  position: relative;
}
[data-page="index"] .partner-details-box h1 {
  font-size: 60px;
  font-weight: 200;
  margin-top: 0;
}
[data-page="index"] .partner-details-box h2 {
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 4.5px;
  margin-top: 0;
  margin-bottom: 21px;
}
[data-page="index"] main {
  height: 100%;
}
[data-page="index"] #top-section {
  position: relative;
  /*@keyframes bounce {
      0%, 20%, 50%, 80%, 100% {
        transform:translateY(0);
      }
      30% {
        transform:translateY(0);
      }
      50% {
        transform:translateY(-40%);
      }
      60% {
        transform:translateY(0);
      }
      75% {
        transform:translateY(-15%);
      }
      95% {
        transform:translateY(0);
      }
    }*/
}
[data-page="index"] #top-section #logo-index-top {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 650px;
  height: 180px;
  margin-left: -325px;
  margin-top: -170px;
}
@media (max-width: 767px) {
  [data-page="index"] #top-section #logo-index-top {
    width: 80%;
    height: auto;
    left: 10%;
    margin-left: 0;
    top: auto;
    bottom: 50%;
    margin-top: -40px;
  }
}
@media (max-height: 480px) {
  [data-page="index"] #top-section #logo-index-top {
    width: auto;
    max-width: 80%;
    height: 30%;
    top: 30%;
    left: 50%;
    margin-left: 0;
    transform: translate3d(-50%, 0, 0);
  }
}
[data-page="index"] #top-section #start-index-top {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 160px;
  height: 160px;
  margin-left: -80px;
  margin-top: 208px;
  text-align: center;
  color: white;
  animation: bounce 2s infinite;
  cursor: pointer;
}
@media (max-width: 767px) {
  [data-page="index"] #top-section #start-index-top {
    top: 50%;
    margin-top: 80px;
  }
}
@media (max-height: 480px) {
  [data-page="index"] #top-section #start-index-top {
    top: 30%;
    margin-top: 100px;
  }
}
[data-page="index"] #top-section #start-index-top span {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  margin-top: -12px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  box-sizing: border-box;
}
[data-page="index"] #top-section .btn-index {
  height: 42px;
  line-height: 42px;
  padding: 0;
  background: transparent;
  border: 1px solid #fff;
}
@media (max-width: 767px) {
  [data-page="index"] #top-section .btn-index {
    /*        top: auto;
        bottom: 10%;*/
    margin-top: 120px;
  }
}
[data-page="index"] #top-section .btn-index:hover {
  background: #fff;
  color: #2b2b2b;
}
@-moz-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
[data-page="index"] #bottom-section {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
[data-page="index"] #bottom-section .headline {
  padding-top: 50%;
  /*position: absolute;
          top: 30%;
          width: 100%;
          /*      margin-top: 315px;*/
}
@media (max-width: 767px) {
  [data-page="index"] #bottom-section .headline {
    padding-top: 21px;
  }
}
[data-page="index"] #bottom-section #newsletter-signup-form {
  padding-bottom: 100vh;
}
[data-page="index"] #bottom-section #newsletter-signup-form .input-group-addon,
[data-page="index"] #bottom-section #newsletter-signup-form input,
[data-page="index"] #bottom-section #newsletter-signup-form .input-group-btn .btn,
[data-page="index"] #bottom-section #newsletter-signup-form .input-group-btn .btn:hover,
[data-page="index"] #bottom-section #newsletter-signup-form .input-group-btn .btn:focus {
  border-bottom: 0;
}
[data-page="index"] #bottom-section #newsletter-signup-form .input-group {
  border-bottom: 1px solid #fff;
}
[data-page="index"] #index-image {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000 url('/img/LLO_Lady.jpg') center center no-repeat;
  z-index: 101;
}
[data-page="index"] #section-4 {
  position: relative;
  height: 240px;
  box-sizing: border-box;
  background-position: 50% 50%;
  background-image: url('/img/LLO_Lady.jpg');
  background-size: auto 100%;
}
@media (min-width: 480px) {
  [data-page="index"] #section-4 {
    height: 160px;
  }
}
@media (min-width: 768px) {
  [data-page="index"] #section-4 {
    height: 400px;
  }
}
@media (min-width: 1024px) {
  [data-page="index"] #section-4 {
    height: 300px;
  }
}
@media (min-width: 1199px) {
  [data-page="index"] #section-4 {
    background-size: 100% auto;
    height: 25vh;
  }
}
[data-page="index"] #section-4.bg-transparent {
  background: transparent;
}
[data-page="index"] .bg-network {
  background: #fff url(/img/LLObg_white_1920x1080.jpg) top left no-repeat;
  background-size: cover;
  /*background: #fff url(/img/back_1113_white.jpg) top left no-repeat;
    background-size: cover;
    @media(max-height: 1024px) and (max-width: 1766px) {
      background-image: url(/img/back_1024_white.jpg);
    }
    @media(max-height: 768px) and (max-width: 1325px) {
      background-image: url(/img/back_768_white.jpg);
    }*/
}
[data-page="index"] .background-fixed {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url('/img/LLO_Lady.jpg');
  background-size: 100% auto;
  background-position: 0 0;
  background-repeat: no-repeat;
  z-index: 101;
}
[data-page="index"] .background-fixed.bg-transparent {
  display: none;
}
[data-page="index"] .background-fixed.bg-top {
  background-position: 0 0;
}
[data-page="index"] .background-fixed.bg-bottom {
  background-position: 0 bottom;
}
[data-page="index"] #index-video {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: -100px;
}
[data-page="index"] #index-video-media {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  width: 100%;
  height: 100%;
}
[data-page="index"] #index-video-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
[data-page="index"] #index-video-cover.desktop {
  background-position: 0 0;
  background-image: url(/img/back_1113.jpg);
}
@media (max-height: 1024px) and (max-width: 1766px) {
  [data-page="index"] #index-video-cover.desktop {
    background-image: url(/img/back_1024.jpg);
  }
}
@media (max-height: 768px) and (max-width: 1325px) {
  [data-page="index"] #index-video-cover.desktop {
    background-image: url(/img/back_768.jpg);
  }
}
[data-page="index"] #index-video-cover.mobile {
  background-image: url(/img/LLOgif.gif);
  background-position: center;
}
[data-page="index"] .welcome {
  margin-top: 36px;
  margin-bottom: 11px;
  line-height: 1em;
}
[data-page="index"] .subtitle {
  font-size: 1.125rem;
  max-width: 768px;
  margin: 0 auto;
}
[data-page="index"] .steps {
  width: 100%;
  height: 481px;
  padding-top: 384px;
  background: url('../img/steps-background.jpg') center no-repeat;
  background-size: cover;
}
[data-page="index"] .steps > div {
  width: 300px;
  height: 75px;
  margin: 0 auto;
  background-color: #fff;
  border-left: 25px solid #000;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
[data-page="index"] .steps > div:before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 37.5px 0 37.5px 35px;
  border-color: transparent transparent transparent #000000;
  position: absolute;
  left: 0;
  top: 0;
}
[data-page="index"] .steps ol {
  padding: 5px 250px 5px 60px;
  counter-reset: steps;
}
[data-page="index"] .steps ol:after {
  content: '';
  display: block;
  clear: both;
}
[data-page="index"] .steps li {
  width: auto;
  padding: 0 25px 0 35px;
  float: left;
  position: relative;
  counter-increment: steps;
  color: #000;
  font-size: .625rem;
  text-transform: uppercase;
}
[data-page="index"] .steps li:before {
  content: counter(steps);
  padding: 0 10px;
  background-color: #000;
  border-radius: 5px 0 5px 0;
  text-align: center;
  color: #fff;
  position: absolute;
  left: 0;
  top: 35px;
}
[data-page="index"] .steps li span {
  content: '';
  height: 35px;
  display: block;
  font-size: 30px;
  line-height: 30px;
  text-align: center;
}
[data-page="index"] .steps li span.icon-Life-less-karte {
  font-size: 40px;
}
[data-page="index"] .steps a {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
}
@media all and (max-width: 767px) {
  [data-page="index"] .steps ol {
    display: none;
  }
  [data-page="index"] .steps a {
    right: 35px;
  }
}
@media all and (min-width: 768px) {
  [data-page="index"] .steps {
    height: 604px;
    padding-top: 498px;
  }
  [data-page="index"] .steps > div {
    width: 730px;
  }
  [data-page="index"] .steps ol {
    padding: 5px 190px 5px 56px;
  }
  [data-page="index"] .steps li {
    width: 28.57142857%;
  }
  [data-page="index"] .steps li:first-child {
    width: 42.85714286%;
  }
}
@media all and (min-width: 1200px) {
  [data-page="index"] .steps {
    height: 604px;
    padding-top: 498px;
  }
  [data-page="index"] .steps > div {
    width: 940px;
  }
  [data-page="index"] .steps ol {
    padding: 5px 250px 5px 70px;
  }
  [data-page="index"] .steps li {
    padding: 0 35px 0 35px;
    width: auto;
  }
  [data-page="index"] .steps li:first-child {
    width: 33.33333333%;
  }
}
[data-page="index"] .teaser-list {
  margin-top: 35px;
  margin-left: -10px;
  margin-right: -10px;
}
[data-page="index"] .teaser-list:after {
  content: '';
  display: block;
  clear: both;
}
[data-page="index"] .teaser-list > li {
  width: 300px;
  height: 320px;
  margin: 10px;
  background-color: #fff;
  overflow: hidden;
  float: left;
  color: #000;
}
[data-page="index"] .teaser-list > li .text:nth-child(2n) {
  position: absolute;
  top: 50%;
  height: 50%;
  background-color: lightgrey;
}
[data-page="index"] .teaser-list > li.events {
  width: 300px;
  height: 660px;
}
[data-page="index"] .teaser-list > li.orders {
  width: 300px;
  height: auto;
  min-height: 360px;
}
@media all and (min-width: 768px) {
  [data-page="index"] .teaser-list {
    margin-bottom: 150px;
  }
}
[data-page="index"] .arrow-down {
  position: fixed;
  bottom: 0;
  left: 50%;
  margin-left: -20px;
  width: 40px;
  height: 40px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0yOTMuNzUxLDQ1NS44NjhjLTIwLjE4MSwyMC4xNzktNTMuMTY1LDE5LjkxMy03My42NzMtMC41OTVsMCwwYy0yMC41MDgtMjAuNTA4LTIwLjc3My01My40OTMtMC41OTQtNzMuNjcyICBsMTg5Ljk5OS0xOTBjMjAuMTc4LTIwLjE3OCw1My4xNjQtMTkuOTEzLDczLjY3MiwwLjU5NWwwLDBjMjAuNTA4LDIwLjUwOSwyMC43NzIsNTMuNDkyLDAuNTk1LDczLjY3MUwyOTMuNzUxLDQ1NS44Njh6Ii8+DQo8cGF0aCBmaWxsPSIjRkZGRkZGIiBkPSJNMjIwLjI0OSw0NTUuODY4YzIwLjE4LDIwLjE3OSw1My4xNjQsMTkuOTEzLDczLjY3Mi0wLjU5NWwwLDBjMjAuNTA5LTIwLjUwOCwyMC43NzQtNTMuNDkzLDAuNTk2LTczLjY3MiAgbC0xOTAtMTkwYy0yMC4xNzgtMjAuMTc4LTUzLjE2NC0xOS45MTMtNzMuNjcxLDAuNTk1bDAsMGMtMjAuNTA4LDIwLjUwOS0yMC43NzIsNTMuNDkyLTAuNTk1LDczLjY3MUwyMjAuMjQ5LDQ1NS44Njh6Ii8+DQo8L3N2Zz4=);
  background-size: contain;
}
.main-nav {
  background: rgba(0, 0, 0, 0.75);
}
[data-page="preopening"] {
  min-height: 100%;
  background: url('/img/preopening_background.jpg') center no-repeat;
  background-size: cover;
}
[data-page="preopening"] .countdown {
  position: fixed;
  right: 0;
  top: 0;
  padding: 4rem;
  text-align: right;
}
[data-page="preopening"] .countdown span {
  font-size: 2rem;
  color: #fff;
  text-transform: uppercase;
}
[data-page="preopening"] .countdown span.icon {
  padding-right: 5px;
}
@media all and (max-width: 767px) {
  [data-page="preopening"] .countdown {
    width: 100%;
    padding: 0 20px 0 0;
    top: 34px;
  }
  [data-page="preopening"] .countdown span {
    font-size: 1.5rem;
  }
}
@media all and (min-width: 768px) {
  [data-page="preopening"] .countdown {
    width: 400px;
  }
}
@media all and (min-width: 1200px) {
  [data-page="preopening"] .countdown {
    width: 400px;
  }
}
[data-page="preopening"] .newsletter {
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 4rem;
}
@media all and (max-width: 767px) {
  [data-page="preopening"] .newsletter {
    width: 100%;
    padding: 30px 10px;
  }
}
@media all and (min-width: 768px) {
  [data-page="preopening"] .newsletter {
    width: 400px;
  }
}
@media all and (min-width: 1200px) {
  [data-page="preopening"] .newsletter {
    width: 400px;
  }
}
[data-page="preopening"] .newsletter input {
  display: block;
  width: 100%;
  appearance: none;
  background-color: #fff;
  height: 46px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid #000;
  font-size: 16px;
}
[data-page="preopening"] .newsletter input[placeholder] {
  text-transform: uppercase;
  padding: 0 1rem 0 1rem;
}
[data-page="preopening"] .newsletter label {
  width: 100%;
  margin-bottom: 20px;
  float: left;
}
[data-page="preopening"] .newsletter label p {
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
}
[data-page="newsletter"] {
  /*background: url('/img/LLOweb_muc_1920x1113_cut.jpg') 10% top no-repeat #000;
    background-size: 100% auto;
    background-attachment: fixed;
    @media @mq2col {
        background: url('/img/LLOweb_muc_1920x1113_cut.jpg') 10% top no-repeat #000;
        background-size: 100% auto;
        background-attachment: fixed;
    }*/
}
[data-page="order"] {
  /*h1 {
    font-size: 48px;
    &,* {
      font-weight: 200;
    }
  }*/
  /*background: #fff url('/img/LLOweb_muc_1920x1113_cut.jpg') left 50px no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
    //background-size: cover;
    @media @mq2col {
        background: #fff url('/img/LLOweb_muc_1920x1113_cut.jpg') left 50px no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
        background-attachment: fixed;
    }*/
}
[data-page="order"] .cart-box {
  font-size: 18px;
}
[data-page="order"] .cart-box td {
  padding: 10px 0;
}
[data-page="order"] .llo-card-wrapper img {
  max-width: 100%;
}
[data-page="order"] .product-sales-banner {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
  color: white;
  background: #d01b30;
  font-weight: bold;
  padding: 1px 5px;
}
[data-page="order"] h3 {
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 4.5px;
  margin-top: 0;
  margin-bottom: 21px;
}
[data-page="order"] .card-name-wrapper {
  line-height: 1;
}
[data-page="order"] .card-name {
  font-size: 40px;
}
[data-page="order"] .cost-line {
  position: relative;
}
[data-page="order"] .card-cost {
  font-size: 40px;
  font-weight: 400;
}
[data-page="order"] .card-old-cost {
  font-weight: 400;
  font-size: 20px;
  margin: 0;
  position: absolute;
  bottom: 52px;
  right: 0;
  font-size: 24px;
  color: #d01b30;
  text-decoration: line-through;
}
[data-page="order"] .partner-details-box {
  margin-bottom: 30px;
}
[data-page="order"] .partner-details-box .dark {
  background: rgba(0, 0, 0, 0.75);
}
[data-page="order"] .partner-details-box.full-height {
  height: 300px;
}
@media (min-width: 992px) {
  [data-page="order"] .partner-details-box.full-height {
    position: absolute;
    left: 15px;
    right: 15px;
    top: 0;
    bottom: 0;
    height: auto;
  }
}
[data-page="order"] .partner-details-box .partner-details-box-complete {
  border-radius: 6px;
  border-top-left-radius: 27px;
  border-bottom-right-radius: 27px;
  -webkit-border-radius: 6px;
  -webkit-border-top-left-radius: 27px;
  -webkit-border-bottom-right-radius: 27px;
  padding: 30px;
  position: relative;
  overflow: hidden;
}
[data-page="order"] .partner-details-box .partner-details-box-top {
  border-radius: 0;
  border-top-left-radius: 27px;
  border-top-right-radius: 6px;
  padding: 30px;
  position: relative;
}
[data-page="order"] .partner-details-box .partner-details-box-bottom {
  border-radius: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 27px;
  padding: 30px;
  position: relative;
}
[data-page="order"] .partner-details-box h1 {
  font-size: 60px;
  font-weight: 200;
  margin-top: 0;
}
[data-page="order"] .partner-details-box h2 {
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 4.5px;
  margin-top: 0;
  margin-bottom: 21px;
}
[data-page="order"] .partner-details-box.partner-details-teaser h1 {
  margin-top: 10.5px;
  margin-bottom: 0;
  padding-right: 100px;
}
[data-page="order"] .partner-details-box.partner-details-teaser h2 {
  margin-top: 0;
  margin-bottom: 0;
  padding-right: 100px;
}
[data-page="order"] .partner-details-box.partner-details-teaser h4 {
  font-size: 24px;
  margin-bottom: 21px;
}
[data-page="order"] .partner-details-box.partner-details-teaser ul {
  padding-left: 20px;
}
[data-page="order"] .partner-details-box.partner-details-teaser .partner-details-icon {
  position: absolute;
  right: 30px;
  top: 30px;
  font-size: 90px;
}
[data-page="order"] .partner-details-box.partner-details-teaser .partner-details-box-middle img {
  display: block;
}
[data-page="order"] .partner-details-box.partner-details-contact table * {
  font-size: 14px;
  vertical-align: top;
}
[data-page="order"] .partner-details-box.partner-details-contact .row > div:first-child {
  margin-bottom: 30px;
}
[data-page="order"] label {
  font-size: 14px;
  font-weight: 300;
  padding: 0;
  text-transform: uppercase;
}
[data-page="order"] select option {
  background-color: #000;
  color: #fff;
}
[data-page="order"] .btn:not(.btn-ci-dynamic) {
  background: #222;
  color: #ccc;
  border: 0;
  min-width: 136px;
  width: auto;
  height: auto;
}
[data-page="order"] .btn:not(.btn-ci-dynamic).btn-outline {
  border: 1px solid #fff;
}
[data-page="order"] .btn:not(.btn-ci-dynamic):hover {
  background: #191919;
}
[data-page="order"] .btn:not(.btn-ci-dynamic).btn-primary {
  background: #fff;
  color: #2b2b2b;
}
[data-page="order"] .btn:not(.btn-ci-dynamic).btn-primary:hover {
  background: #ddd;
}
[data-page="order"] section {
  overflow: visible;
}
[data-page="order"] .entry-form .sticky-card-alert {
  z-index: 100;
  position: fixed;
  top: 50%;
  left: 0;
  width: 100vw;
  background-color: #fff;
  overflow: hidden;
  box-shadow: 0px 17px 55px -8px rgba(0, 0, 0, 0.9);
  opacity: 0;
  transform: translateX(100%);
  transition: transform 0.3s, opacity 0.3s ease-out;
}
[data-page="order"] .entry-form .sticky-card-alert.active {
  opacity: 1;
  transform: translateX(0%);
}
[data-page="order"] .entry-form .sticky-card-alert img {
  height: 50px;
  width: auto;
  padding: 10px 15px;
  float: left;
}
[data-page="order"] .entry-form .sticky-card-alert .description {
  width: 60%;
  float: left;
  font-size: 14px;
  line-height: 24px;
  display: inline-block;
  padding: 6px 0;
}
[data-page="order"] .entry-form .sticky-card-alert .description span {
  color: #000;
}
[data-page="order"] .entry-form .sticky-card-alert .description .card-name {
  font-weight: 600;
}
[data-page="order"] .entry-form .sticky-card-alert .close-icon {
  float: right;
  width: 28px;
  height: 28px;
  background-color: #000;
  position: relative;
  top: 11px;
  right: 11px;
  text-align: center;
}
[data-page="order"] .entry-form .sticky-card-alert .close-icon .icon {
  width: 100%;
  margin: 0;
  font-size: 25px;
  line-height: 25px;
  font-weight: 300;
}
[data-page="order"] .entry-form .form-group label {
  padding: 0;
}
[data-page="order"] .referred-container {
  margin-top: 10px;
}
[data-page="order"] .referred-container:hover {
  cursor: pointer;
}
[data-page="order"] .referred-dropdown-form {
  overflow: hidden;
  opacity: 1;
  transition: max-height, opacity 0.3s ease;
}
[data-page="order"] .referred-dropdown-form.hidden-animated {
  max-height: 0;
  opacity: 0;
}
[data-page="order"] .referred-dropdown-form .form-group {
  margin-bottom: 10px;
}
[data-page="order"] .referred-dropdown-header p {
  margin: 0;
}
[data-page="order"] .referred-dropdown-header .icon-right {
  display: inline-block;
}
[data-page="order"] .llo-card-wrapper {
  position: relative;
  width: 100%;
}
[data-page="order"] .llo-card-wrapper .llo-card-ribbon {
  position: relative;
}
@media all and (max-width: 767px) {
  [data-page="order"] .llo-card-wrapper .llo-card {
    width: 300px;
  }
  [data-page="order"] .llo-card-wrapper .llo-card-ribbon {
    width: 316px;
    left: -15px;
    top: -15px;
  }
}
@media all and (min-width: 768px) {
  [data-page="order"] .llo-card-wrapper .llo-card {
    width: 300px;
  }
  [data-page="order"] .llo-card-wrapper .llo-card-ribbon {
    width: 317px;
    left: -15px;
    top: -15px;
  }
}
@media all and (min-width: 1200px) {
  [data-page="order"] .llo-card-wrapper .llo-card {
    width: 401px;
  }
  [data-page="order"] .llo-card-wrapper .llo-card-ribbon {
    width: 423px;
    left: -20px;
    top: -20px;
  }
}
[data-page="order"] .back-to-card-choice > div {
  padding: 0 0 30px 0;
}
[data-page="order"] .numbered-headline {
  margin-bottom: 0.2rem;
}
[data-page="order"] .numbered-headline .number-label {
  position: relative;
  top: -4px;
}
@media all and (min-width: 768px) {
  [data-page="order"] .registration .login {
    padding: 0;
  }
  [data-page="order"] .registration .left-column {
    padding-right: 40px;
    position: relative;
    height: auto;
  }
  [data-page="order"] .registration .left-column.separation-border:after {
    content: '';
    position: absolute;
    right: 0;
    top: 10px;
    width: 1px;
    height: 80%;
    background-color: #fff;
  }
  [data-page="order"] .registration .right-column {
    padding-left: 40px;
  }
  [data-page="order"] .registration .right-column .row div {
    min-height: 116px;
  }
  [data-page="order"] .registration .right-column .row + .row {
    height: auto;
  }
}
[data-page="order"] .order-step.active .step-body {
  height: auto;
}
[data-page="order"] .order-step.active .edit-step {
  display: none !important;
}
[data-page="order"] .order-step {
  opacity: 1;
  /*border-top: 1px solid #fff;
        padding: 20px 0;*/
}
[data-page="order"] .order-step .summary {
  padding: 0;
  display: none;
}
@media all and (min-width: 768px) {
  [data-page="order"] .order-step .summary {
    padding-left: 51px;
  }
}
[data-page="order"] .order-step .summary.checked {
  display: block;
}
[data-page="order"] .order-step:first-child {
  border-top: none;
}
[data-page="order"] .order-step.blur {
  opacity: 0.5;
}
[data-page="order"] .step-header {
  padding: 0;
  position: relative;
}
[data-page="order"] .step-header .numbered-headline {
  height: 40px;
}
[data-page="order"] .step-header .numbered-headline div {
  float: left;
}
[data-page="order"] .step-header div.headline {
  padding-left: 11px;
  position: relative;
  top: -13px;
}
[data-page="order"] .step-header div.headline h2 {
  max-width: 240px;
}
@media all and (min-width: 768px) {
  [data-page="order"] .step-header div.headline h2 {
    max-width: 500px;
  }
}
[data-page="order"] .step-header div.headline.checked h2 {
  position: relative;
}
[data-page="order"] .step-header div.headline.checked h2:after {
  content: '\e90d';
  font-family: 'llo';
  font-size: 30px;
  position: absolute;
  right: -40px;
  top: 50%;
  transform: translateY(-50%);
}
[data-page="order"] .step-header .extra-text {
  font-size: 16px;
  font-weight: 300;
  line-height: 18px;
  display: block;
  float: none;
  position: relative;
  top: -1px;
}
@media all and (min-width: 768px) {
  [data-page="order"] .step-header .extra-text {
    padding-left: 51px;
  }
}
[data-page="order"] .step-header:hover {
  cursor: pointer;
}
[data-page="order"] .step-header .edit-step {
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  display: none;
}
@media all and (min-width: 768px) {
  [data-page="order"] .step-header .edit-step {
    right: 55px;
  }
}
[data-page="order"] .step-header .edit-step.checked {
  display: block;
}
[data-page="order"] .step-body {
  clear: both;
  overflow: hidden;
  height: 0;
  width: 100%;
}
@media all and (min-width: 768px) {
}
[data-page="order"] .step-body.login {
  padding: 0;
}
[data-page="order"] .step-body .prev-step {
  text-transform: uppercase;
}
[data-page="order"] .order-form-nav-buttons {
  position: relative;
  margin-top: 1rem;
  /*&:before {
            content: '';
            width: calc(~"100% - 20px");
            transform: translateX(10px);
            height: 1px;
            background-color: #fff;
            position: absolute;
            top: 0;
            left: 0;
        }*/
}
[data-page="order"] .mw1 {
  width: 100%;
  display: inline-block;
  margin-top: 10px;
}
@media all and (min-width: 768px) {
  [data-page="order"] .mw1 {
    width: 120px;
    margin-top: 0;
  }
}
[data-page="order"] .mw1 + span {
  font-size: 12px;
  display: inline-block;
}
@media all and (min-width: 768px) {
  [data-page="order"] .mw1 + span {
    font-size: 1em;
  }
}
[data-page="order"] [data-immediately-hint] {
  display: none;
}
[data-page="order"] [data-immediately-hint].show {
  display: inline;
}
[data-page="order"] .registration.order-step.active #response-user {
  display: none;
}
[data-page="order"] .advanced-form .order-step {
  margin-top: 40px;
}
[data-page="order"] .advanced-form .order-step:first-child {
  margin-top: 0;
}
[data-page="order"] .no-top {
  border-top: 0;
}
[data-page="order"] .form-group.checkbox label {
  padding-left: 1px;
}
[data-page="order"] .form-group.checkbox label:before {
  width: 12px;
  color: #fff;
  margin-right: 0.5rem;
}
[data-page="order"] #cc-info input,
[data-page="order"] #bankaccount-info input {
  background: #000;
}
[data-page="partners"] {
  /*background: #fff url('/img/LLOweb_muc_1920x1113_cut.jpg') left 50px no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
    //background-size: cover;
    @media @mq2col {
        background: #fff url('/img/LLOweb_muc_1920x1113_cut.jpg') left 50px no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
        background-attachment: fixed;
    }*/
  /*    background: #fff url('/img/LLOnew_Muc_sw_dark.png') left 100px no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    //background-size: cover;
    @media @mq2col {
        background: #fff url('/img/LLOnew_Muc_sw_dark.png') left 100px no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
        background-attachment: scroll;
    }*/
  /*#partners-filter-wrapper-section {
    position: fixed;
    left: 0;
    width: 100%;
    top: 496px;
    z-index: 100;
  }*/
}
[data-page="partners"] .partner-item {
  display: block;
  border-radius: 4px;
  border-top-left-radius: 18px;
  border-bottom-right-radius: 18px;
  margin-top: 30px;
  color: #fff;
  text-decoration: none;
}
@media (min-width: 992px) {
  [data-page="partners"] .partner-item {
    display: flex;
  }
}
[data-page="partners"] .partner-item.active,
[data-page="partners"] .partner-item:hover {
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.75);
}
[data-page="partners"] .partner-item .partner-item-image-col {
  border-radius: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 18px;
  background-size: cover;
}
@media (min-width: 992px) {
  [data-page="partners"] .partner-item .partner-item-image-col {
    width: 50%;
    height: 236px;
    flex-grow: 1;
    border-top-right-radius: 0;
    border-bottom-left-radius: 6px;
  }
}
@media (min-width: 1200px) {
  [data-page="partners"] .partner-item .partner-item-image-col {
    height: 286px;
  }
}
[data-page="partners"] .partner-item .partner-item-image-col img {
  border-radius: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 18px;
  max-width: 100%;
}
[data-page="partners"] .partner-item .partner-item-content-col {
  position: relative;
  border-radius: 0;
  border-bottom-right-radius: 18px;
  border-bottom-left-radius: 4px;
  background: rgba(0, 0, 0, 0.75);
  padding: 20px;
}
@media (min-width: 992px) {
  [data-page="partners"] .partner-item .partner-item-content-col {
    width: 50%;
    height: 236px;
    flex-grow: 1;
    border-bottom-left-radius: 0;
    border-top-right-radius: 6px;
  }
}
@media (min-width: 1200px) {
  [data-page="partners"] .partner-item .partner-item-content-col {
    height: 286px;
  }
}
[data-page="partners"] .partner-item .partner-item-content-col .partner-item-content-wrapper {
  position: relative;
}
[data-page="partners"] .partner-item .partner-item-content-col .partner-item-content-wrapper .partner-item-icon {
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 44px;
}
@media (min-width: 992px) {
  [data-page="partners"] .partner-item .partner-item-content-col .partner-item-content-wrapper .partner-item-icon {
    font-size: 36px;
  }
}
@media (min-width: 1200px) {
  [data-page="partners"] .partner-item .partner-item-content-col .partner-item-content-wrapper .partner-item-icon {
    font-size: 44px;
  }
}
[data-page="partners"] .partner-item .partner-item-content-col .partner-item-content-wrapper .partner-item-category {
  font-size: 15px;
  font-weight: 500;
}
@media (min-width: 992px) {
  [data-page="partners"] .partner-item .partner-item-content-col .partner-item-content-wrapper .partner-item-category {
    font-size: 12.5px;
  }
}
@media (min-width: 1200px) {
  [data-page="partners"] .partner-item .partner-item-content-col .partner-item-content-wrapper .partner-item-category {
    font-size: 15px;
  }
}
[data-page="partners"] .partner-item .partner-item-content-col .partner-item-content-wrapper .partner-item-title {
  font-weight: 200;
  margin-top: 5px;
  padding-right: 60px;
  font-size: 28px;
}
@media (min-width: 992px) {
  [data-page="partners"] .partner-item .partner-item-content-col .partner-item-content-wrapper .partner-item-title {
    font-size: 23px;
  }
}
@media (min-width: 1200px) {
  [data-page="partners"] .partner-item .partner-item-content-col .partner-item-content-wrapper .partner-item-title {
    font-size: 28px;
  }
}
[data-page="partners"] .partner-item .partner-item-content-col .partner-item-content-wrapper .partner-item-subtitle {
  margin-top: 31.5px;
  font-size: 18px;
}
@media (min-width: 992px) {
  [data-page="partners"] .partner-item .partner-item-content-col .partner-item-content-wrapper .partner-item-subtitle {
    font-size: 15px;
    margin-top: 10px;
  }
}
@media (min-width: 1200px) {
  [data-page="partners"] .partner-item .partner-item-content-col .partner-item-content-wrapper .partner-item-subtitle {
    font-size: 18px;
    margin-top: 31.5px;
  }
}
[data-page="partners"] .partner-item .partner-item-content-col .partner-item-content-wrapper .partner-item-description {
  margin-top: 21px;
  font-size: 14px;
}
[data-page="partners"] .partner-item .partner-item-content-col .partner-item-content-wrapper .partner-item-description ul {
  padding-left: 20px;
}
@media (min-width: 992px) {
  [data-page="partners"] .partner-item .partner-item-content-col .partner-item-content-wrapper .partner-item-description {
    margin-top: 10.5px;
    font-size: 11.5px;
  }
}
@media (min-width: 1200px) {
  [data-page="partners"] .partner-item .partner-item-content-col .partner-item-content-wrapper .partner-item-description {
    margin-top: 21px;
    font-size: 14px;
  }
}
[data-page="partners"] .partner-item .partner-item-content-col .partner-item-btn {
  display: block;
  position: absolute;
  right: 15px;
  bottom: 10px;
  border: 0;
  background: transparent;
  font-size: 14px;
  font-weight: 400;
}
[data-page="partners"] .partners-filter-wrapper .partner-filter-header {
  border-radius: 0;
  border-top-left-radius: 18px;
  border-top-right-radius: 4px;
}
[data-page="partners"] .partners-filter-wrapper .partner-filter-header #partners-filter-button {
  position: relative;
  width: 200px;
  text-align: left;
}
[data-page="partners"] .partners-filter-wrapper .partner-filter-header #partners-filter-button span {
  line-height: 25px;
}
[data-page="partners"] .partners-filter-wrapper .partner-filter-header #partners-filter-button span.llo-icon {
  line-height: 1;
  margin-top: 3px;
  margin-right: 12px;
}
[data-page="partners"] .partners-filter-wrapper .partner-filter-header #partners-filter-button span.llo-left {
  width: 30px;
  border-right: 1px solid #666;
  margin-right: 17px;
}
[data-page="partners"] .partners-filter-wrapper .partner-filter-header #partners-filter-button .icon-active {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  padding: 8px 0 8px 18px;
  background: #fff;
  color: #000;
  height: 41px;
  border-top-left-radius: 17px;
  border-bottom-left-radius: 3px;
}
[data-page="partners"] .partners-filter-wrapper .partner-filter-header #partners-filter-button .filters-inactive-icon {
  display: inline-block;
  margin-right: 8px;
}
[data-page="partners"] .partners-filter-wrapper .partner-filter-header #partners-filter-button .filters-icon-up,
[data-page="partners"] .partners-filter-wrapper .partner-filter-header #partners-filter-button .filters-active-icon {
  display: none;
}
[data-page="partners"] .partners-filter-wrapper .partner-filter-header #partners-filter-button.filters-active .icon-active {
  display: block;
}
[data-page="partners"] .partners-filter-wrapper .partner-filter-header #partners-filter-button.filters-active .filters-inactive-icon {
  display: none;
}
[data-page="partners"] .partners-filter-wrapper .partner-filter-header #partners-filter-button.filters-active .filters-active-icon {
  display: inline-block;
  margin-right: 8px;
}
[data-page="partners"] .partners-filter-wrapper .partner-filter-header .filter-close-button {
  margin-right: 18px;
  line-height: 41px;
  display: none;
  cursor: pointer;
}
[data-page="partners"] .partners-filter-wrapper.active .partner-filter-header {
  background: rgba(0, 0, 0, 0.75);
}
[data-page="partners"] .partners-filter-wrapper.active .partner-filter-header #partners-filter-button {
  background: #060606;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
[data-page="partners"] .partners-filter-wrapper.active .partner-filter-header #partners-filter-button .filters-icon-up {
  display: inline-block;
}
[data-page="partners"] .partners-filter-wrapper.active .partner-filter-header #partners-filter-button .filters-icon-down {
  display: none;
}
[data-page="partners"] .partners-filter-wrapper.active .partner-filter-header .filter-close-button {
  display: block;
}
[data-page="partners"] .wrapper {
  overflow: visible;
}
@media all and (min-width: 768px) {
  [data-page="partners"] .wrapper {
    width: 730px;
  }
}
@media all and (min-width: 1200px) {
  [data-page="partners"] .wrapper {
    width: 940px;
  }
}
[data-page="partners"] .page-headline {
  font-weight: 500;
}
[data-page="partners"] .btn.cta {
  height: 42px;
  line-height: 35px;
  position: absolute;
  right: 30px;
  bottom: 30px;
  background-color: rgba(100, 100, 100, 0.5);
  border: 2px solid #888;
  color: #fff;
}
[data-page="partners"] .btn.cta:hover {
  border: 2px solid #fff;
  color: #fff;
}
[data-page="partners"] .partners-filter-placeholder {
  height: 41px;
  /*&.is--visible {
    display: block;
  }*/
}
[data-page="partners"] #partners-filter-wrapper-section.is--fixed {
  position: fixed;
  left: 0px;
  top: 50px;
  z-index: 10;
  width: 100%;
}
[data-page="partners"] #partners-filter-wrapper-section.is--fixed .partners-filter-wrapper .partner-filter-header {
  background: rgba(0, 0, 0, 0.75);
  border-radius: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 18px;
}
[data-page="partners"] #partners-filter-wrapper-section.is--fixed .partners-filter-wrapper .partner-filter-header #partners-filter-button {
  background: transparent;
  font-size: 15px;
  padding: 4px 18px;
  line-height: 25px;
}
[data-page="partners"] #partners-filter-wrapper-section.is--fixed .partners-filter-wrapper .partner-filter-header #partners-filter-button .icon-active {
  height: 33px;
  border-top-left-radius: 0;
  padding: 4px 3px 12px 18px;
}
[data-page="partners"] #partners-filter-wrapper-section.is--fixed .partners-filter-wrapper .partner-filter-header #partners-filter-button .filters-icon-down,
[data-page="partners"] #partners-filter-wrapper-section.is--fixed .partners-filter-wrapper .partner-filter-header #partners-filter-button .filters-icon-up {
  /*line-height: 18px;*/
}
[data-page="partners"] #partners-filter-wrapper-section.is--fixed .partners-filter-wrapper .partner-filter-header #partners-filter-button.filters-active {
  width: 182px;
}
[data-page="partners"] #partners-filter-wrapper-section.is--fixed .partners-filter-wrapper .partner-filter-header .filter-close-button {
  line-height: 29px;
}
[data-page="partners"] #partners-filter-wrapper-section.is--fixed .partners-filter-wrapper.active .partner-filter-header {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
[data-page="partners"] .partners-filter-wrapper {
  z-index: 50;
}
[data-page="partners"] .partners-filter-wrapper .filter-close-button {
  /*position: absolute;
            top: 20px;
            right: 17px;*/
}
[data-page="partners"] .partners-filter-wrapper #partners-filter-button.active .filter-icon-right {
  transform: rotate(-180deg);
}
[data-page="partners"] .partners-filter-wrapper #partners-filter-button .filter-icon-right {
  transition: transform 0.3s ease;
  transform: rotate(0deg);
}
[data-page="partners"] .partners-filter-wrapper #close-partners-details {
  padding-left: 40px;
  padding-right: 40px;
}
[data-page="partners"] .partners-filter-wrapper #close-partners-details .icon {
  position: relative;
  top: 2px;
  margin-right: 20px;
}
[data-page="partners"] .partners-filter-wrapper .partners-top-button {
  height: 46px;
  line-height: 46px;
  font-size: 17px;
  position: static;
  margin-bottom: 10px;
  background-color: rgba(0, 0, 0, 0.75);
  transition: background-color 0.3s ease;
}
[data-page="partners"] .partners-filter-wrapper .partners-top-button .icon {
  display: inline-block;
}
[data-page="partners"] .partners-filter-wrapper .partners-top-button.active {
  background-color: #000000;
  height: 50px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  padding-bottom: 5px;
  margin-bottom: 6px;
}
[data-page="partners"] .partners-filter-wrapper .partners-top-button.active .filter-icon-right {
  transform: rotate(180deg);
}
[data-page="partners"] .partners-filter-wrapper .partners-top-button .filter-icon-left {
  margin-right: 25px;
  opacity: 0.3;
}
[data-page="partners"] .partners-filter-wrapper .partners-top-button .filter-icon-left.active {
  transition: all 2s ease;
  animation-name: is_active;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
[data-page="partners"] .partners-filter-wrapper .partners-top-button .filter-icon-right {
  margin-left: 25px;
}
[data-page="partners"] .partners-filter-wrapper .filter-expanded {
  display: none;
}
[data-page="partners"] .partners-filter-wrapper .filter-expanded h2 {
  font-weight: 400;
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 20px;
  margin-top: 0;
}
@media (max-width: 479px) {
  [data-page="partners"] .partners-filter-wrapper .filter-expanded h2 {
    font-size: 17px;
  }
}
@media (min-width: 992px) {
  [data-page="partners"] .partners-filter-wrapper .filter-expanded h2 {
    margin-top: 20px;
  }
}
[data-page="partners"] .partners-filter-wrapper .filter-expanded .border-wrapper {
  padding-left: 20px;
  border-left: 1px solid #fff;
}
@media (max-width: 479px) {
  [data-page="partners"] .partners-filter-wrapper .filter-expanded .border-wrapper {
    padding-left: 0;
    border-left: 0;
  }
}
[data-page="partners"] .partners-filter-wrapper .filter-expanded.active {
  display: block;
  /*@media @mq2col {
                    max-height:587px;
                }

                @media @mq3col {
                    max-height: 533px;
                }
                box-shadow: 0px 17px 55px -8px rgba(0,0,0,0.9);*/
}
[data-page="partners"] .partners-filter-wrapper .filter-expanded .top-row {
  background-color: #000;
  padding: 10px 20px 5px 30px;
}
[data-page="partners"] .partners-filter-wrapper .filter-expanded .top-row .form-group {
  margin-bottom: 0;
}
[data-page="partners"] .partners-filter-wrapper .filter-expanded .top-row .form-group label {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
}
[data-page="partners"] .partners-filter-wrapper .filter-expanded .top-row .icon {
  font-size: 24px;
  cursor: pointer;
}
@media (max-width: 767px) {
  [data-page="partners"] .partners-filter-wrapper .filter-expanded .select-heading {
    /*h2 {
                        font-size: 20px;
                        text-transform: uppercase;
                        font-weight: 500;
                        margin: 0;
                    }

                    color: #fff;
                    width: 262px;
                    position: relative;
                    left: 50%;
                    transform: translateX(-50%);

                    background-color: #fff;
                    background-image: url(../img/select-dropdown-arrow-small.png);
                    background-position: right;
                    background-repeat: no-repeat;
                    padding: 5px 50px 5px 25px;

                    border-top-left-radius: @border-radius;
                    border-bottom-right-radius: @border-radius;
                    border: 1px solid #000;

                    margin-bottom: 15px;
                    cursor: pointer;*/
  }
}
[data-page="partners"] .partners-filter-wrapper .filter-expanded .category-select {
  background-color: rgba(0, 0, 0, 0.75);
  padding: 20px 20px 0;
}
@media (max-width: 479px) {
  [data-page="partners"] .partners-filter-wrapper .filter-expanded .category-select {
    padding-top: 15px;
  }
}
[data-page="partners"] .partners-filter-wrapper .filter-expanded .category-select h2 {
  margin-bottom: 5px;
}
[data-page="partners"] .partners-filter-wrapper .filter-expanded .category-select .form-fields {
  /*.beauty {
                        label:after {
                            content: '\e914';
                        }
                    }

                    .sports {
                        label:after {
                            content: '\e91f';
                        }
                    }

                    .shopping {
                        label:after {
                            content: '\e91d';
                        }
                    }

                    .nightlife {
                        label:after {
                            content: '\e917';
                        }
                    }

                    .gastro {
                        label:after {
                            content: '\e915';
                        }
                    }

                    .special {
                        label:after {
                            content: '\e91e';
                        }
                    }

                    .culture_entertainment,
                    .culture {
                        label:after {
                            content: '\e916';
                        }
                    }*/
}
[data-page="partners"] .partners-filter-wrapper .filter-expanded .category-select .form-fields input[type="checkbox"]:checked + label::before,
[data-page="partners"] .partners-filter-wrapper .filter-expanded .category-select .form-fields input[type="checkbox"]:not(:checked) + label::before {
  content: '';
  display: none;
}
@media all and (max-width: 767px) {
  [data-page="partners"] .partners-filter-wrapper .filter-expanded .category-select .form-fields .form-group {
    /*input[type="checkbox"] {
                                &+ label{
                                    padding-left: 20px;
                                }
                              &:not(:checked) {
                                & + label::before {
                                  background-color: transparent;
                                }
                              }
                              &:checked {
                                & + label span {
                                  font-weight: 700;
                                }
                                & + label::before {
                                  background-color: #fff;
                                }
                              }
                            }*/
  }
  [data-page="partners"] .partners-filter-wrapper .filter-expanded .category-select .form-fields .form-group label {
    /*position: relative;
                              text-transform: uppercase;
                              font-size: 16px;
                              line-height: 18px;
                              height: 50px;
                              padding-left: 60px;
                              padding-top: 5px;*/
    /*.filter-icon {
                                  position: absolute;
                                  display: block;
                                  left: 0;
                                  top: 0;
                                  font-size: 30px;
                                  line-height: 50px;
                                  width: 50px;
                                  text-align: center;
                                  border: 1px solid #fff;
                                  border-radius: 4px;
                                    //font-weight: 500;
                                    span {
                                      line-height: 48px;
                                          display: block;
                                          height: 48px;
                                    }
                                  span:before {
                                    line-height: 48px;
                                  }
                                }*/
    /*&:before {
                                    position: absolute;
                                    top: -2px;
                                    left: 32px;
                                    font-family: 'llo' !important;
                                    float: left;
                                    content: '';
                                    width: 28px;
                                    height: 28px;
                                    //background-color: #b2b2b2;
                                    border-radius: @border-radius;
                                    border: 1px solid #fff;
                                }
                                &:after {
                                    font-family: 'llo' !important;
                                    color: #fff;
                                    font-weight: 500;
                                    //content: '';
                                    line-height: 57px;
                                    position: absolute;
                                    font-size: 20px;
                                    top: -16px;
                                    left: 37px;
                                    clear: both;
                                }*/
  }
}
[data-page="partners"] .partners-filter-wrapper .filter-expanded .category-select .form-fields .form-group {
  color: #fff;
  width: auto;
  height: 55px;
  margin: 20px 0 0;
}
@media (max-width: 479px) {
  [data-page="partners"] .partners-filter-wrapper .filter-expanded .category-select .form-fields .form-group {
    height: 35px;
    margin-top: 10px;
  }
}
[data-page="partners"] .partners-filter-wrapper .filter-expanded .category-select .form-fields .form-group label {
  position: relative;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 18px;
  height: 50px;
  padding-left: 60px !important;
  padding-top: 5px;
  /*&:before {
                                    position: absolute;
                                    top: 7px;
                                    left: -47px;
                                    font-family: 'llo' !important;
                                    float: left;
                                    content: '';
                                    width: 40px;
                                    height: 40px;
                                    //background-color: #b2b2b2;
                                    border-radius: @border-radius;
                                    border: 1px solid #fff;
                                }
                                &:after {
                                    font-family: 'llo' !important;
                                    color: #fff;
                                    font-weight: 500;
                                    //content: '';
                                    line-height: 57px;
                                    position: absolute;
                                    font-size: 25px;
                                    top: -1px;
                                    left: -40px;
                                    clear: both;
                                }*/
}
[data-page="partners"] .partners-filter-wrapper .filter-expanded .category-select .form-fields .form-group label .filter-icon {
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  font-size: 30px;
  line-height: 50px;
  width: 50px;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 4px;
  /*font-weight: 500;
                                    position: absolute;
                                    top: 50%;
                                    transform: translateY(-50%);*/
}
@media (max-width: 479px) {
  [data-page="partners"] .partners-filter-wrapper .filter-expanded .category-select .form-fields .form-group label .filter-icon {
    font-size: 18px;
    width: 30px;
    line-height: 30px;
  }
}
[data-page="partners"] .partners-filter-wrapper .filter-expanded .category-select .form-fields .form-group label .filter-icon span {
  line-height: 48px;
  display: block;
  height: 48px;
}
@media (max-width: 479px) {
  [data-page="partners"] .partners-filter-wrapper .filter-expanded .category-select .form-fields .form-group label .filter-icon span {
    height: 28px;
    line-height: 28px;
  }
}
[data-page="partners"] .partners-filter-wrapper .filter-expanded .category-select .form-fields .form-group label .filter-icon span:before {
  line-height: 48px;
}
@media (max-width: 479px) {
  [data-page="partners"] .partners-filter-wrapper .filter-expanded .category-select .form-fields .form-group label .filter-icon span:before {
    line-height: 28px;
  }
}
[data-page="partners"] .partners-filter-wrapper .filter-expanded .category-select .form-fields .form-group input[type="checkbox"]:checked {
  /*                                    & + label::before {
                                        background-color: #fff;
                                    }*/
}
[data-page="partners"] .partners-filter-wrapper .filter-expanded .category-select .form-fields .form-group input[type="checkbox"]:checked + label .filter-icon {
  background: #fff;
  color: #2b2b2b;
}
@media all and (min-width: 1200px) {
  [data-page="partners"] .partners-filter-wrapper .filter-expanded .category-select .form-fields .form-group label {
    /*&:before {
                                    top: 3px;
                                    left: -60px;
                                    width: 50px;
                                    height: 50px;
                                }
                                &:after {
                                    font-size: 35px;
                                    top: 0;
                                    left: -52px;
                                }*/
  }
}
@media (max-width: 767px) {
  [data-page="partners"] .partners-filter-wrapper .filter-expanded .category-select .form-fields .row {
    margin: 0;
    display: flex;
    justify-content: space-between;
  }
  [data-page="partners"] .partners-filter-wrapper .filter-expanded .category-select .form-fields .row .col-xs-3 {
    padding: 0;
    width: 14%;
    text-align: center;
  }
}
[data-page="partners"] .partners-filter-wrapper .filter-expanded .details-select {
  background-color: rgba(0, 0, 0, 0.75);
  padding: 0 20px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 18px;
}
[data-page="partners"] .partners-filter-wrapper .filter-expanded .details-select .form-fields .form-group {
  color: #fff;
  display: inline-block;
  margin: 5px 0 0;
  height: 25px;
  /*@media @mq1col {
                            padding-left: 20px;
                            label {
                                font-weight: 300;
                                font-size: 15px;
                                &:before {
                                    margin-right: 10px;
                                    height: 20px;
                                    width: 20px;
                                }
                            }
                        }
                        @media @mq2col {
                            //padding-left: 0;
                            //width: 50%;
                            label {
                                &:before {
                                    height: 12px;
                                    width: 12px;
                                }
                            }
                        }*/
}
@media (max-width: 479px) {
  [data-page="partners"] .partners-filter-wrapper .filter-expanded .details-select .form-fields .form-group {
    display: block;
    height: 19px;
  }
}
[data-page="partners"] .partners-filter-wrapper .filter-expanded .details-select .form-fields .form-group label {
  font-weight: 300;
  text-transform: uppercase;
  font-size: 80%;
}
@media (max-width: 479px) {
  [data-page="partners"] .partners-filter-wrapper .filter-expanded .details-select .form-fields .form-group label {
    font-size: 65%;
    margin-bottom: 0;
    display: block;
  }
}
[data-page="partners"] .partners-filter-wrapper .filter-expanded .details-select .form-fields .form-group label:before {
  margin-right: 2px;
  top: 1px;
  height: 12px;
  width: 12px;
}
@media (max-width: 479px) {
  [data-page="partners"] .partners-filter-wrapper .filter-expanded .details-select .form-fields .form-group label:before {
    width: 10px;
    float: left;
    margin-top: 3px;
  }
}
@media all and (min-width: 1200px) {
}
[data-page="partners"] .partners-filter-wrapper .filter-expanded .details-select .form-fields h2 {
  margin-bottom: 15px;
  /*color: #fff;
                        text-transform: uppercase;
                        font-size: 20px;
                        font-weight: 700;
                        position: relative;
                        @media @mq1col {
                            margin: 0;
                        }
                        @media @mq2col {
                            margin-bottom: 20px;
                        }

                        @media @mq2col {
                            padding-left: 20px;
                            &:before {
                                content: '';
                                position: absolute;
                                left: 0;
                                top: -2px;
                                width: 2px;
                                height: 25px;
                                background-color: #000;
                            }
                        }*/
}
@media (max-width: 479px) {
  [data-page="partners"] .partners-filter-wrapper .filter-expanded .details-select .form-fields h2 {
    margin-bottom: 5px;
    font-size: 17px;
  }
}
[data-page="partners"] .partners-filter-wrapper .filter-expanded .details-select .form-fields .days,
[data-page="partners"] .partners-filter-wrapper .filter-expanded .details-select .form-fields .time,
[data-page="partners"] .partners-filter-wrapper .filter-expanded .details-select .form-fields .direction,
[data-page="partners"] .partners-filter-wrapper .filter-expanded .details-select .form-fields .fulltext {
  padding-top: 20px;
}
@media (max-width: 479px) {
  [data-page="partners"] .partners-filter-wrapper .filter-expanded .details-select .form-fields .days,
  [data-page="partners"] .partners-filter-wrapper .filter-expanded .details-select .form-fields .time,
  [data-page="partners"] .partners-filter-wrapper .filter-expanded .details-select .form-fields .direction,
  [data-page="partners"] .partners-filter-wrapper .filter-expanded .details-select .form-fields .fulltext {
    padding-top: 10px;
  }
}
@media (min-width: 992px) {
  [data-page="partners"] .partners-filter-wrapper .filter-expanded .details-select .form-fields .days,
  [data-page="partners"] .partners-filter-wrapper .filter-expanded .details-select .form-fields .time,
  [data-page="partners"] .partners-filter-wrapper .filter-expanded .details-select .form-fields .direction,
  [data-page="partners"] .partners-filter-wrapper .filter-expanded .details-select .form-fields .fulltext {
    padding-top: 20px;
  }
}
@media all and (min-width: 768px) {
}
[data-page="partners"] .partners-filter-wrapper .filter-expanded .details-select .form-fields .days {
  /*@media @mq2col {
                            width: 220px;
                        }
                        @media @mq3col {
                            width: 290px;
                        }*/
}
@media all and (max-width: 767px) {
}
[data-page="partners"] .partners-filter-wrapper .filter-expanded .details-select .form-fields .time {
  /*@media @mq2col {
                            width: 280px;
                        }
                        @media @mq3col {
                            width: 365px;
                        }*/
}
@media all and (max-width: 767px) {
}
@media all and (min-width: 768px) {
}
@media all and (min-width: 1200px) {
}
@media all and (min-width: 1200px) {
}
@media all and (max-width: 767px) {
}
@media all and (min-width: 768px) {
}
@media all and (min-width: 1200px) {
}
[data-page="partners"] .partners-filter-wrapper .filter-expanded .details-select .button-bottom {
  padding: 60px 20px 20px;
}
@media (max-width: 479px) {
  [data-page="partners"] .partners-filter-wrapper .filter-expanded .details-select .button-bottom {
    padding-top: 20px;
  }
}
[data-page="partners"] .partners-filter-wrapper .filter-expanded .details-select .button-bottom .btn {
  background: #222;
  color: #ccc;
  border: 1px solid #fff;
  min-width: 136px;
  width: auto;
  height: auto;
}
@media (max-width: 479px) {
  [data-page="partners"] .partners-filter-wrapper .filter-expanded .details-select .button-bottom .btn {
    min-width: 0;
  }
}
[data-page="partners"] .partners-filter-wrapper .filter-expanded .details-select .button-bottom .btn:hover {
  background: #191919;
}
[data-page="partners"] .partners-filter-wrapper .filter-expanded .details-select .button-bottom .btn.btn-primary {
  background: #fff;
  color: #2b2b2b;
}
[data-page="partners"] .partners-filter-wrapper .filter-expanded .details-select .button-bottom .btn.btn-primary:hover {
  background: #ddd;
}
@media all and (max-width: 767px) {
}
[data-page="partners"] .no-results {
  margin-top: 30px;
  display: none;
}
[data-page="partners"] .partners-tile {
  display: block;
  position: relative;
  width: 100%;
  background-clip: padding-box;
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 20px;
  background: right center no-repeat, #000;
  cursor: pointer;
  text-shadow: 2px 2px 4px #000;
}
@media all and (max-width: 767px) {
  [data-page="partners"] .partners-tile {
    background-position: center bottom;
    background-size: 100% auto;
  }
}
@media all and (min-width: 768px) {
  [data-page="partners"] .partners-tile {
    height: 370px;
  }
}
@media all and (min-width: 1200px) {
  [data-page="partners"] .partners-tile {
    height: 370px;
  }
}
[data-page="partners"] .partners-tile .gradient {
  height: 100%;
}
@media all and (max-width: 767px) {
  [data-page="partners"] .partners-tile .gradient {
    padding: 10px 20px 170px;
  }
}
@media all and (min-width: 768px) {
  [data-page="partners"] .partners-tile .gradient {
    padding: 45px 30px 35px 30px;
    background: linear-gradient(90deg, black, black 120px, transparent 45%) center center no-repeat;
  }
  [data-page="partners"] .partners-tile .gradient.darken {
    background: linear-gradient(90deg, black, black 120px, rgba(0, 0, 0, 0.3)) center center no-repeat;
  }
}
@media all and (min-width: 1200px) {
  [data-page="partners"] .partners-tile .gradient {
    padding: 55px 30px 35px 30px;
    background: linear-gradient(90deg, black, black 220px, transparent 45%) center center no-repeat;
  }
  [data-page="partners"] .partners-tile .gradient.darken {
    background: linear-gradient(90deg, black, black 220px, rgba(0, 0, 0, 0.3)) center center no-repeat;
  }
}
[data-page="partners"] .partners-tile .icon {
  font-size: 35px;
}
[data-page="partners"] .partners-tile .text-label {
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  border: 1px solid #fff;
  padding: 4px 10px 5px;
  top: -8px;
  margin-right: 10px;
}
[data-page="partners"] .partners-tile .category {
  position: relative;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  font-style: italic;
  top: -6px;
}
[data-page="partners"] .partners-tile .partners-heading {
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 10px;
}
@media all and (max-width: 767px) {
  [data-page="partners"] .partners-tile .partners-heading {
    line-height: 100%;
  }
}
[data-page="partners"] .partners-tile .partners-subheading {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 20px;
}
[data-page="partners"] .partners-tile .partners-description {
  font-size: 15px;
}
[data-page="partners"] .slick-slide {
  margin: 0 30px;
}
[data-page="partners"] .slick-list {
  margin: 0 -30px;
}
[data-page="partners"] .partners-tile-pagination {
  position: relative;
  height: 40px;
  margin-top: 20px;
}
[data-page="partners"] .partners-tile-pagination .icon {
  font-size: 25px;
  line-height: 25px;
  cursor: pointer;
  color: #4e4e4e;
}
[data-page="partners"] .partners-tile-pagination .icon:hover {
  color: #fff;
}
[data-page="partners"] .partners-tile-pagination .pagination-left {
  position: absolute;
  padding-left: 10px;
  left: 0;
  top: 13px;
  bottom: inherit;
}
[data-page="partners"] .partners-tile-pagination .pagination-right {
  position: absolute;
  right: 0;
  top: 13px;
  bottom: inherit;
}
[data-page="partners"] .partners-tile-pagination .pagination-item-wrapper {
  position: absolute;
  width: auto;
  height: 30px;
  left: 50%;
  transform: translateX(-50%);
}
[data-page="partners"] .partners-tile-pagination .pagination-item {
  display: inline-block;
  text-align: center;
  font-size: 1rem;
  line-height: 24px;
  width: 25px;
  height: 25px;
  background-color: #505050;
  color: #858585;
  cursor: pointer;
  border-radius: 50%;
  position: relative;
  top: 2px;
  margin: 0 3px;
}
[data-page="partners"] .partners-tile-pagination .pagination-item:hover {
  color: #fff;
}
[data-page="partners"] .partners-tile-pagination .pagination-item.active {
  background-color: #fff;
  color: #333;
}
[data-page="partners"] .partners-tile-pagination .pagination-item.hidden {
  display: none;
}
@keyframes is_active {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
[data-page="partner-detail"] {
  /*background: #fff url('/img/LLOweb_muc_1920x1113_cut.jpg') left 50px no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  //background-size: cover;
  @media @mq2col {
      background: #fff url('/img/LLOweb_muc_1920x1113_cut.jpg') left 50px no-repeat;
      -webkit-background-size: cover;
      background-size: cover;
      background-attachment: fixed;
  }*/
  /*background: url('/img/partners_background_small.jpg') 10% top no-repeat #000;
    background-size: 100% auto;
    background-attachment: fixed;
    @media @mq2col {
        background: url('/img/partners_background.jpg') 10% top no-repeat #000;
        background-size: 100% auto;
        background-attachment: fixed;
    }*/
}
[data-page="partner-detail"] .partner-details-box {
  margin-bottom: 30px;
}
[data-page="partner-detail"] .partner-details-box .dark {
  background: rgba(0, 0, 0, 0.75);
}
[data-page="partner-detail"] .partner-details-box.full-height {
  height: 300px;
}
@media (min-width: 992px) {
  [data-page="partner-detail"] .partner-details-box.full-height {
    position: absolute;
    left: 15px;
    right: 15px;
    top: 0;
    bottom: 0;
    height: auto;
  }
}
[data-page="partner-detail"] .partner-details-box .partner-details-box-complete {
  border-radius: 6px;
  border-top-left-radius: 27px;
  border-bottom-right-radius: 27px;
  -webkit-border-radius: 6px;
  -webkit-border-top-left-radius: 27px;
  -webkit-border-bottom-right-radius: 27px;
  padding: 30px;
  position: relative;
  overflow: hidden;
}
[data-page="partner-detail"] .partner-details-box .partner-details-box-complete.mheight {
  min-height: 400px;
}
[data-page="partner-detail"] .partner-details-box .partner-details-box-complete.chain-store-box {
  padding-top: 75px;
}
[data-page="partner-detail"] .partner-details-box .partner-details-box-complete.chain-store-box .chain-store-name {
  position: absolute;
  left: 0;
  top: 0;
  background: #ccc;
  color: #2b2b2b;
  font-weight: 500;
  padding: 10px 30px;
  border-top-left-radius: 27px;
  -webkit-border-top-left-radius: 27px;
}
[data-page="partner-detail"] .partner-details-box .partner-details-box-top {
  border-radius: 0;
  border-top-left-radius: 27px;
  border-top-right-radius: 6px;
  padding: 30px;
  position: relative;
}
[data-page="partner-detail"] .partner-details-box .partner-details-box-bottom {
  border-radius: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 27px;
  padding: 30px;
  position: relative;
}
[data-page="partner-detail"] .partner-details-box h1 {
  font-size: 60px;
  font-weight: 200;
  margin-top: 0;
}
[data-page="partner-detail"] .partner-details-box h2 {
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 4.5px;
  margin-top: 0;
  margin-bottom: 21px;
}
[data-page="partner-detail"] .partner-details-box.partner-details-teaser h1 {
  margin-top: 10.5px;
  margin-bottom: 0;
  padding-right: 100px;
}
@media (max-width: 767px) {
  [data-page="partner-detail"] .partner-details-box.partner-details-teaser h1 {
    font-size: 24px;
    padding-right: 48px;
  }
}
[data-page="partner-detail"] .partner-details-box.partner-details-teaser h2 {
  margin-top: 0;
  margin-bottom: 0;
  padding-right: 100px;
}
[data-page="partner-detail"] .partner-details-box.partner-details-teaser h4 {
  font-size: 24px;
  margin-bottom: 21px;
}
[data-page="partner-detail"] .partner-details-box.partner-details-teaser ul {
  padding-left: 20px;
}
[data-page="partner-detail"] .partner-details-box.partner-details-teaser .partner-details-icon {
  position: absolute;
  right: 30px;
  top: 30px;
  font-size: 60px;
}
@media (max-width: 767px) {
  [data-page="partner-detail"] .partner-details-box.partner-details-teaser .partner-details-icon {
    font-size: 48px;
  }
}
[data-page="partner-detail"] .partner-details-box.partner-details-teaser .partner-details-box-middle img {
  display: block;
}
[data-page="partner-detail"] .partner-details-box.partner-details-contact .contact-details,
[data-page="partner-detail"] .partner-details-box.partner-details-contact .contact-details * {
  font-size: 15px;
  vertical-align: top;
}
[data-page="partner-detail"] .partner-details-box.partner-details-contact .row > div:first-child {
  margin-bottom: 30px;
}
[data-page="partner-detail"] #partner-details-map {
  position: relative;
  overflow: hidden;
  height: 100%;
  padding: 0;
}
[data-page="partner-detail"] .wrapper {
  overflow: visible;
}
@media all and (min-width: 768px) {
  [data-page="partner-detail"] .wrapper {
    width: 730px;
  }
}
@media all and (min-width: 1200px) {
  [data-page="partner-detail"] .wrapper {
    width: 940px;
  }
}
[data-page="partner-detail"] .page-headline {
  font-weight: 500;
}
[data-page="partner-detail"] .top-buttons {
  position: relative;
  z-index: 50;
}
[data-page="partner-detail"] .partners-top-button {
  height: 46px;
  line-height: 46px;
  font-size: 17px;
  position: static;
  background-color: rgba(0, 0, 0, 0.7);
  padding-left: 40px;
  padding-right: 40px;
}
[data-page="partner-detail"] .partners-top-button .icon {
  margin-right: 25px;
}
[data-page="partner-detail"] .partner-details-wrapper {
  height: auto;
  position: relative;
  /*.event-calendar {
            width: 300px;
            float: right;
            .teaser {
                height: 395px;
                width: 300px;
                background-color: #fff;
                .slider {
                    background-color: #fff;
                    top: -4px;
                }
            }
        }*/
}
[data-page="partner-detail"] .partner-details-wrapper .partners-tile {
  cursor: default;
}
[data-page="partner-detail"] .partner-details-wrapper .partner-share {
  z-index: 1;
  font-size: 20px;
  position: absolute;
  top: 65px;
  right: 20px;
  color: #fff;
}
[data-page="partner-detail"] .partner-details-wrapper .partner-share .partner-share-button {
  font-size: 30px;
  color: #fff;
  float: right;
  padding: 5px 10px;
  background-color: transparent;
  transition: all .3s ease;
  text-shadow: 2px 2px 4px #000;
}
[data-page="partner-detail"] .partner-details-wrapper .partner-share .partner-share-button:hover {
  color: #949494;
}
[data-page="partner-detail"] .partner-details-wrapper .partner-share .partner-share-button .icon {
  transform: rotate(0deg);
  transition: transform .3s ease;
}
[data-page="partner-detail"] .partner-details-wrapper .partner-share .icon {
  margin: 0;
  display: inline-block;
}
[data-page="partner-detail"] .partner-details-wrapper .partner-share.active .partner-share-button {
  background-color: rgba(100, 100, 100, 0.6);
  transition: background-color 0.3s ease;
  border-top-left-radius: 10px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0);
}
[data-page="partner-detail"] .partner-details-wrapper .partner-share.active .partner-share-button .icon {
  transform: rotate(-360deg);
}
[data-page="partner-detail"] .partner-details-wrapper .partner-share.active .expand-buttons {
  display: block;
  opacity: 1;
  height: auto;
  transition: opacity 0.3s ease;
}
[data-page="partner-detail"] .partner-details-wrapper .partner-share .expand-buttons {
  overflow: hidden;
  position: relative;
  opacity: 0;
  transition: none;
  height: 0;
  width: 111px;
  clear: both;
  background-color: rgba(100, 100, 100, 0.6);
  padding: 5px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
[data-page="partner-detail"] .partner-details-wrapper .partner-share .expand-buttons .icon {
  display: inline-block;
  height: 38px;
  line-height: 38px;
  font-size: 44px;
  position: relative;
  left: 1px;
  color: #fff;
  cursor: pointer;
  margin: 5px;
  transition: color .3s ease;
}
[data-page="partner-detail"] .partner-details-wrapper .partner-share .expand-buttons .icon:hover {
  color: #949494;
}
[data-page="partner-detail"] .partner-details-wrapper .details.row {
  margin: 0;
  margin-bottom: 20px;
  border: 2px solid #fff;
  border-radius: 10px;
  height: auto;
  overflow: hidden;
}
@media all and (max-width: 767px) {
  [data-page="partner-detail"] .partner-details-wrapper .details.row {
    background-color: #fff;
  }
  [data-page="partner-detail"] .partner-details-wrapper .details.row .description {
    background-color: #fff;
  }
  [data-page="partner-detail"] .partner-details-wrapper .details.row .contact {
    background-color: #000;
  }
}
@media all and (min-width: 768px) {
  [data-page="partner-detail"] .partner-details-wrapper .details.row {
    background-image: url(/img/partner-details-bg-repeat.png);
    background-position: center right;
    background-repeat: repeat-y;
  }
}
@media all and (min-width: 768px) and (max-width: 1199px) {
  [data-page="partner-detail"] .partner-details-wrapper .details.row .col-md-4 {
    width: 58.666667%;
  }
  [data-page="partner-detail"] .partner-details-wrapper .details.row .col-md-2 {
    width: 41.333333%;
  }
}
[data-page="partner-detail"] .partner-details-wrapper .details.row .description {
  padding: 20px;
  color: #000;
}
[data-page="partner-detail"] .partner-details-wrapper .details.row .description a {
  color: #000;
}
[data-page="partner-detail"] .partner-details-wrapper .details.row .contact {
  padding: 30px 15px 30px 15px;
  height: 100%;
}
@media all and (min-width: 768px) {
}
@media all and (min-width: 1200px) {
  [data-page="partner-detail"] .partner-details-wrapper .details.row .contact {
    padding-left: 25px;
  }
}
[data-page="partner-detail"] .partner-details-wrapper .details.row .contact-heading .icon {
  font-size: 30px;
  position: relative;
  top: 5px;
  margin-right: 0;
}
[data-page="partner-detail"] .partner-details-wrapper .details.row .contact-heading h3 {
  font-weight: 500;
  text-transform: uppercase;
}
@media all and (max-width: 767px) {
  [data-page="partner-detail"] .partner-details-wrapper .details.row .contact-heading h3 {
    font-size: 20px;
  }
}
@media all and (min-width: 768px) {
  [data-page="partner-detail"] .partner-details-wrapper .details.row .contact-heading h3 {
    font-size: 16px;
  }
}
@media all and (min-width: 1200px) {
  [data-page="partner-detail"] .partner-details-wrapper .details.row .contact-heading h3 {
    font-size: 20px;
  }
}
[data-page="partner-detail"] .partner-details-wrapper .details.row .contact-details {
  padding: 10px 10px 10px 34px;
  font-size: 13px;
}
[data-page="partner-detail"] .partner-details-wrapper .details.row .contact-details td {
  min-width: 70px;
}
[data-page="partner-detail"] .partner-details-wrapper .details.row .contact-details a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: inline-block;
  position: relative;
  top: 3px;
}
[data-page="partner-detail"] .partner-details-wrapper .details.row .contact-details table {
  border-collapse: collapse;
}
[data-page="partner-detail"] .partner-details-wrapper .map-events {
  width: 100%;
  height: 100%;
}
@media all and (min-width: 768px) {
  [data-page="partner-detail"] .partner-details-wrapper .map-events .events h2 {
    font-size: 1rem;
  }
}
@media all and (min-width: 1200px) {
  [data-page="partner-detail"] .partner-details-wrapper .map-events .events h2 {
    font-size: 1.25rem;
  }
}
[data-page="partner-detail"] .partner-details-wrapper .map {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  margin-bottom: 30px;
}
@media all and (min-width: 768px) {
}
@media all and (min-width: 1200px) {
}
[data-page="partner-detail"] .partner-details-wrapper .map #partner-map {
  width: 100%;
  height: 100%;
  -webkit-mask: url(/img/partner-map-mask.svg);
  -webkit-mask-size: cover;
}
[data-page="partner-detail"] .partner-details-wrapper .map #map-overlay {
  display: inline-block;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
[data-page="partner-detail"] .partner-details-wrapper .map #map-overlay span {
  color: #fff;
  text-shadow: 1px 1px 2px black;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 65%;
  display: inline-block;
}
[data-page="partner-detail"] .partner-details-wrapper .map .leaflet-container {
  border-radius: 6px;
  border-top-left-radius: 27px;
  border-bottom-right-radius: 27px;
  -webkit-border-radius: 6px;
  -webkit-border-top-left-radius: 27px;
  -webkit-border-bottom-right-radius: 27px;
}
[data-page="partner-detail"] .partner-details-wrapper .map .show-route {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
}
@media all and (max-width: 767px) {
  [data-page="partner-detail"] .partner-details-wrapper .map .show-route {
    width: 200px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 30px;
  }
}
@media all and (min-width: 768px) {
  [data-page="partner-detail"] .partner-details-wrapper .map .show-route {
    bottom: 23px;
    right: 9px;
    margin: 0;
  }
}
[data-page="partner-detail"] .partners-tile {
  position: relative;
  width: 100%;
  background-clip: padding-box;
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 20px;
  background: right center no-repeat, #000;
  cursor: pointer;
  text-shadow: 2px 2px 4px #000;
}
@media all and (max-width: 767px) {
  [data-page="partner-detail"] .partners-tile {
    background-position: center bottom;
    background-size: 100% auto;
  }
}
@media all and (min-width: 768px) {
  [data-page="partner-detail"] .partners-tile {
    height: 370px;
  }
}
@media all and (min-width: 1200px) {
  [data-page="partner-detail"] .partners-tile {
    height: 370px;
  }
}
[data-page="partner-detail"] .partners-tile .gradient {
  height: 100%;
}
@media all and (max-width: 767px) {
  [data-page="partner-detail"] .partners-tile .gradient {
    padding: 10px 20px 170px;
  }
}
@media all and (min-width: 768px) {
  [data-page="partner-detail"] .partners-tile .gradient {
    padding: 45px 30px 35px 30px;
    background: linear-gradient(90deg, black, black 120px, transparent 45%) center center no-repeat;
  }
  [data-page="partner-detail"] .partners-tile .gradient.darken {
    background: linear-gradient(90deg, black, black 120px, rgba(0, 0, 0, 0.3)) center center no-repeat;
  }
}
@media all and (min-width: 1200px) {
  [data-page="partner-detail"] .partners-tile .gradient {
    padding: 55px 30px 35px 30px;
    background: linear-gradient(90deg, black, black 220px, transparent 45%) center center no-repeat;
  }
  [data-page="partner-detail"] .partners-tile .gradient.darken {
    background: linear-gradient(90deg, black, black 220px, rgba(0, 0, 0, 0.3)) center center no-repeat;
  }
}
[data-page="partner-detail"] .partners-tile .icon {
  font-size: 35px;
}
[data-page="partner-detail"] .partners-tile .text-label {
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  border: 1px solid #fff;
  padding: 4px 10px 5px;
  top: -8px;
  margin-right: 10px;
}
[data-page="partner-detail"] .partners-tile .category {
  position: relative;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  font-style: italic;
  top: -6px;
}
[data-page="partner-detail"] .partners-tile .partners-heading {
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 10px;
}
@media all and (max-width: 767px) {
  [data-page="partner-detail"] .partners-tile .partners-heading {
    line-height: 100%;
  }
}
[data-page="partner-detail"] .partners-tile .partners-subheading {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 20px;
}
[data-page="partner-detail"] .partners-tile .partners-description {
  font-size: 15px;
}
[data-page="partner-detail"] .slick-slide {
  margin: 0 30px;
}
[data-page="partner-detail"] .slick-list {
  margin: 0 -30px;
}
[data-page="partner-detail"] .leaflet-popup-content {
  color: black;
}
[data-page="partner-detail"] .partner-details-events {
  font-size: 15px;
}
[data-page="terms"] {
  /*background: #fff url('/img/LLOweb_muc_1920x1113_cut.jpg') left 50px no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
    //background-size: cover;
    @media @mq2col {
        background: #fff url('/img/LLOweb_muc_1920x1113_cut.jpg') left 50px no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
        background-attachment: fixed;
    }*/
}
[data-page="terms"] section {
  overflow: visible;
}
[data-page="payments"] {
  /*background: url('/img/LLOweb_muc_1920x1113_cut.jpg') center top no-repeat #000;
    background-size: cover;
    background-attachment: fixed;*/
}
[data-page="shop"] .product-item {
  display: block;
  border-radius: 4px;
  border-top-left-radius: 18px;
  border-bottom-right-radius: 18px;
  margin-top: 30px;
  color: #fff;
  text-decoration: none;
}
[data-page="shop"] .product-item:hover {
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.75);
}
[data-page="shop"] .product-item .product-item-image {
  position: relative;
  border-radius: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 18px;
  background: rgba(0, 0, 0, 0.75);
}
[data-page="shop"] .product-item .product-item-image img {
  border-radius: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 18px;
  max-width: 100%;
}
[data-page="shop"] .product-item .product-item-image .product-sales-banner {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
  color: white;
  background: #d01b30;
  font-weight: bold;
  padding: 1px 5px;
}
[data-page="shop"] .product-item .product-item-content {
  position: relative;
  border-radius: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 18px;
  background: rgba(0, 0, 0, 0.75);
  padding: 20px;
}
[data-page="shop"] .product-item .product-item-content .product-item-content-wrapper .product-item-title {
  font-weight: 200;
  margin-top: 5px;
  font-size: 28px;
  height: 60px;
  overflow: hidden;
}
@media (min-width: 992px) {
  [data-page="shop"] .product-item .product-item-content .product-item-content-wrapper .product-item-title {
    font-size: 23px;
    height: 50px;
  }
}
@media (min-width: 1200px) {
  [data-page="shop"] .product-item .product-item-content .product-item-content-wrapper .product-item-title {
    font-size: 28px;
    height: 60px;
  }
}
[data-page="shop"] .product-item .product-item-content .product-item-content-wrapper .product-item-price {
  font-weight: 200;
  font-size: 20px;
}
[data-page="shop"] .product-item .product-item-content .product-item-content-wrapper .product-item-old-price {
  font-weight: 300;
  font-size: 20px;
  margin: 0;
  position: absolute;
  bottom: 52px;
  font-size: 17px;
  color: #d01b30;
  text-decoration: line-through;
}
[data-page="shop"] .product-item .product-item-content .product-item-btn {
  display: block;
  position: absolute;
  right: 15px;
  bottom: 10px;
  border: 0;
  background: transparent;
  font-size: 14px;
  font-weight: 400;
}
[data-page="shop"] #main-image-wrapper {
  margin-bottom: 30px;
  position: relative;
}
[data-page="shop"] #main-image-wrapper a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
[data-page="shop"] #thumbnail-images-wrapper .row > div {
  /*@media(min-width: 768px) {
          height: 95px;
        }
        @media(min-width: 992px) {
          height: 131.656px;
        }
        @media(min-width: 1200px) {
          height: 99.984px;
        }*/
  margin-bottom: 30px;
}
[data-page="shop"] #thumbnail-images-wrapper .row > div a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
[data-page="shop"] .partner-details-box {
  margin-bottom: 30px;
}
[data-page="shop"] .partner-details-box .dark {
  background: rgba(0, 0, 0, 0.75);
}
[data-page="shop"] .partner-details-box.full-height {
  height: 300px;
}
@media (min-width: 992px) {
  [data-page="shop"] .partner-details-box.full-height {
    position: absolute;
    left: 15px;
    right: 15px;
    top: 0;
    bottom: 0;
    height: auto;
  }
}
[data-page="shop"] .partner-details-box .partner-details-box-complete {
  border-radius: 6px;
  border-top-left-radius: 27px;
  border-bottom-right-radius: 27px;
  -webkit-border-radius: 6px;
  -webkit-border-top-left-radius: 27px;
  -webkit-border-bottom-right-radius: 27px;
  padding: 30px;
  position: relative;
  overflow: hidden;
}
[data-page="shop"] .partner-details-box .partner-details-box-top {
  border-radius: 0;
  border-top-left-radius: 27px;
  border-top-right-radius: 6px;
  padding: 30px;
  position: relative;
}
[data-page="shop"] .partner-details-box .partner-details-box-bottom {
  border-radius: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 27px;
  padding: 30px;
  position: relative;
}
[data-page="shop"] .partner-details-box h1 {
  font-size: 60px;
  font-weight: 200;
  margin-top: 0;
}
[data-page="shop"] .partner-details-box h2 {
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 4.5px;
  margin-top: 0;
  margin-bottom: 21px;
}
[data-page="shop"] .partner-details-box.partner-details-teaser h1 {
  margin-top: 10.5px;
  margin-bottom: 0;
  padding-right: 100px;
}
[data-page="shop"] .partner-details-box.partner-details-teaser h2 {
  margin-top: 0;
  margin-bottom: 0;
  padding-right: 100px;
}
[data-page="shop"] .partner-details-box.partner-details-teaser h4 {
  font-size: 24px;
  margin-bottom: 21px;
}
[data-page="shop"] .partner-details-box.partner-details-teaser ul {
  padding-left: 20px;
}
[data-page="shop"] .partner-details-box.partner-details-teaser .partner-details-icon {
  position: absolute;
  right: 30px;
  top: 30px;
  font-size: 90px;
}
[data-page="shop"] .partner-details-box.partner-details-teaser .partner-details-box-middle img {
  display: block;
}
[data-page="shop"] .partner-details-box.partner-details-contact table * {
  font-size: 14px;
  vertical-align: top;
}
[data-page="shop"] .partner-details-box.partner-details-contact .row > div:first-child {
  margin-bottom: 30px;
}
[data-page="shop"] .product-details .product-title {
  margin-top: 0;
  font-size: 40px;
  font-weight: 300;
}
[data-page="shop"] .product-details .cost-line {
  position: relative;
}
[data-page="shop"] .product-details .product-old-cost {
  font-weight: 400;
  font-size: 20px;
  margin: 0;
  position: absolute;
  bottom: 52px;
  right: 0;
  font-size: 24px;
  color: #d01b30;
  text-decoration: line-through;
}
[data-page="shop"] .product-details .product-cost {
  font-size: 40px;
  font-weight: 400;
}
[data-page="shop"] h3 {
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 4.5px;
  margin-top: 0;
  margin-bottom: 21px;
}
[data-page="shop"] label {
  font-size: 14px;
  font-weight: 300;
  padding: 0;
  text-transform: uppercase;
}
[data-page="shop"] .btn:not(.btn-ci-dynamic) {
  background: #222;
  color: #ccc;
  border: 0;
  min-width: 136px;
  width: auto;
  height: auto;
}
[data-page="shop"] .btn:not(.btn-ci-dynamic):hover {
  background: #191919;
}
[data-page="shop"] .btn:not(.btn-ci-dynamic).btn-primary {
  background: #fff;
  color: #2b2b2b;
}
[data-page="shop"] .btn:not(.btn-ci-dynamic).btn-primary:hover {
  background: #ddd;
}
[data-page="shop"] .cart-box {
  font-size: 18px;
}
[data-page="shop"] .cart-box td {
  padding: 10px 0;
}
[data-page="user"] .table {
  font-size: 15px;
}
[data-page="user"] .table > thead > tr > th {
  border-bottom: 1px solid rgba(255, 255, 255, 0.75);
}
[data-page="user"] .table > tbody > tr > th,
[data-page="user"] .table > thead > tr > th,
[data-page="user"] .table > tfoot > tr > th,
[data-page="user"] .table > tbody > tr > td,
[data-page="user"] .table > thead > tr > td,
[data-page="user"] .table > tfoot > tr > td {
  border-top: 1px solid rgba(255, 255, 255, 0.25);
}
[data-page="user"] .table:last-child {
  margin-bottom: 0;
}
[data-page="user"] #cards-navigation .pagination,
[data-page="user"] #orders-navigation .pagination {
  display: block;
  text-align: center;
}
[data-page="user"] #cards-navigation .pagination > li,
[data-page="user"] #orders-navigation .pagination > li {
  display: inline-block;
  margin: 0 5px;
}
[data-page="user"] #cards-navigation .pagination > li > a,
[data-page="user"] #orders-navigation .pagination > li > a,
[data-page="user"] #cards-navigation .pagination > li > span,
[data-page="user"] #orders-navigation .pagination > li > span {
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.75);
  border: 0;
  font-weight: 500;
  padding: 0;
  width: 34px;
  height: 28px;
  line-height: 28px;
  color: #fff;
}
[data-page="user"] #cards-navigation .pagination > li.active > a,
[data-page="user"] #orders-navigation .pagination > li.active > a,
[data-page="user"] #cards-navigation .pagination > li.active > a:focus,
[data-page="user"] #orders-navigation .pagination > li.active > a:focus,
[data-page="user"] #cards-navigation .pagination > li.active > a:hover,
[data-page="user"] #orders-navigation .pagination > li.active > a:hover,
[data-page="user"] #cards-navigation .pagination > li.active > span,
[data-page="user"] #orders-navigation .pagination > li.active > span,
[data-page="user"] #cards-navigation .pagination > li.active > span:focus,
[data-page="user"] #orders-navigation .pagination > li.active > span:focus,
[data-page="user"] #cards-navigation .pagination > li.active > span:hover,
[data-page="user"] #orders-navigation .pagination > li.active > span:hover {
  background: #fff;
  color: #000;
}
[data-page="user"] #cards-navigation .pagination > li.disabled > a,
[data-page="user"] #orders-navigation .pagination > li.disabled > a,
[data-page="user"] #cards-navigation .pagination > li.disabled > span,
[data-page="user"] #orders-navigation .pagination > li.disabled > span {
  color: #666;
  visibility: hidden;
}
[data-page="regions"] .region-item {
  display: block;
  border-radius: 4px;
  border-top-left-radius: 18px;
  border-bottom-right-radius: 18px;
  margin-top: 30px;
  color: #fff;
  text-decoration: none;
}
[data-page="regions"] .region-item:hover {
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.75);
}
[data-page="regions"] .region-item .region-item-image {
  position: relative;
  border-radius: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 18px;
  background: rgba(0, 0, 0, 0.75);
}
[data-page="regions"] .region-item .region-item-image img {
  border-radius: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 18px;
  max-width: 100%;
}
[data-page="regions"] .region-item .region-item-content {
  position: relative;
  border-radius: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 18px;
  background: rgba(0, 0, 0, 0.75);
  padding: 20px;
}
[data-page="regions"] .region-item .region-item-content .region-item-content-wrapper .region-item-title {
  font-weight: 200;
  margin-top: 5px;
  font-size: 28px;
  height: 60px;
  overflow: hidden;
}
@media (min-width: 992px) {
  [data-page="regions"] .region-item .region-item-content .region-item-content-wrapper .region-item-title {
    font-size: 23px;
    height: 50px;
  }
}
@media (min-width: 1200px) {
  [data-page="regions"] .region-item .region-item-content .region-item-content-wrapper .region-item-title {
    font-size: 28px;
    height: 60px;
  }
}
[data-page="regions"] .region-item .region-item-content .region-item-btn {
  display: block;
  position: absolute;
  right: 15px;
  bottom: 10px;
  border: 0;
  background: transparent;
  font-size: 14px;
  font-weight: 400;
}
