@navBGColor: rgba(255,255,255,0.5);
@navWidthMobile: 250px;
@navWidthTablet: 250px;
@navWidthDesktop: 280px;
@navPadding: 42px 42px 42px 42px;
@navFontColor: #fff;
@navActiveColor: rgba(98, 98, 99, 1);
@navHamburgerColor: #eee;
@navCloseColor: #fff;

button.close-nav {
    /*background-color: #fff;*/
    color: #fff;
}

body.nav-open {
    //TODO: fix position shift of background when opening nav
    /*overflow: hidden;
    padding-left: 17px;*/
}

.main-nav {
    //TODO: fix z-index stacking with filter
    z-index: 1000;
    position: fixed;
    top: 0;
    overflow-y: auto;
    font-size: 15px;
    padding-top: 100px;
    &.narrow {
      padding-top: 50px;
    }
    &.transparent {
      padding-top: 0;
    }
    @media @mq1col {
        right: -@navWidthMobile;
        width: @navWidthMobile;
    }
    @media @mq2col {
        right: -@navWidthTablet;
        width: @navWidthTablet;
    }
    @media @mq3col {
        right: -@navWidthDesktop;
        width: @navWidthDesktop;
    }
    height: 100%;
    background-color: @navBGColor;
    transition: transform 0.3s ease;
    li {
        color: @navFontColor;
        a {
            color: @navFontColor;
            display:block;
            width:100%;
        }
        /*&:hover {
            color: @navFontColor;
            a {
                color: @navActiveColor;
            }
        }*/
    }

    .close-nav-wrapper {
      padding-top: 10px;
      padding-right: 10px;
      text-align: right;
    }

    // NAV BUTTON
    .close-nav {
      padding: 0;
        width: auto;
        height: auto;
        background-color: transparent;
        /*position: absolute;
        right: 10px;
        top: 10px;*/

        .icon {
            font-size: 25px;
            color: @navCloseColor;
            margin: 0;
        }
    }

    ul {
        @media @mq1col and (orientation: landscape) {
            padding: 20px;
        }
        padding: 0 42px;
        @media(min-width: 768px) {
          padding: 42px;
        }
        & + ul {
          padding-top: 0;
        }

        li {
            text-transform: uppercase;
            display: block;
            border-bottom: 1px solid rgba(255,255,255,0.8);

            a {
                line-height: 3rem;
                span {
                    padding-right: 10px;
                }
            }
            &:last-child {
                border-bottom: none;
            }
            /*&.active a {
                color: @navActiveColor;
                span {
                    color: @navActiveColor;
                }
            }*/
            span {
              font-size: 20px;
                display: inline-block;
                width: 34px;
                text-align: center;
                float: left;
                margin-top: 12px;
            }

            &.space-after{
                border-bottom:none;
                margin-bottom: 40px;
            }
        }
        .icon {
            font-size: 15px;
            &.icon-menu-partner {
                font-size: 20px;
            }
            &.icon-menu-service {
                font-size: 20px;
            }
        }
    }

    &.on-screen {
        &:before {
            content: '';
            position: fixed;
            top: 0;
            width: 100vw;
            height: 100vh;
            left: -100vw;
            background-color: rgba(0, 0, 0, .6);
        }
        @media @mq1col {
            transform: translate(-@navWidthMobile, 0);
        }
        @media @mq2col {
            transform: translate(-@navWidthTablet, 0);
        }
        @media @mq3col {
            transform: translate(-@navWidthDesktop, 0);
        }
    }

    .nav-contact {
        display: inline-block;
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: @navPadding;

        @media @mq1col and (orientation: landscape) {
            padding: 20px;
            position: relative;
        }

        .social, .phone, .mail {
            display: block;
            padding-bottom: 5px;
            margin-bottom: 10px;
            a {
                color: #fff;
                span {
                    position: relative;
                    top: 2px;
                    color: @navActiveColor;
                    &.llo-icon {
                        font-size: 25px;
                        padding-right: 10px;
                        color: #fff;
                        &.icon-telefon, &.icon-mail {
                            font-size: 15px;
                        }
                    }
                }
                &:hover {
                  text-decoration: none;
                  color: #ddd;
                  span {
                    color: #ddd;
                  }
                }
            }
        }

        .social {
            /*border-bottom: 1px solid rgba(200, 200, 200, 0.5);*/
            color: @navActiveColor;
            a {
                span {
                    &:hover {
                        color: #ddd;
                    }
                }
            }
        }
        .phone {
            font-size: 14px;
        }

        .mail {
            font-size: 12px;
        }
    }
}
