@footerFontColor: #eee;
@footerHeight: 73px;
@footerBGColor: rgba(0, 0, 0, .5);
@footerTextSize: 0.8em;
@footerFontWeight: 300;

footer {
    /*z-index: 9;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;*/
    background-color: @footerBGColor;
    font-size: @footerTextSize;
    color: @footerFontColor;
    font-weight: @footerFontWeight;
    ul {
      margin: 0;
        text-align: center;
        line-height: @footerHeight/2;
        padding: 0 @footerHeight/4;
        @media(max-width: 1023px) {
          padding-top: 10.5px;
          padding-bottom: 10.5px;
        }
        li {
            text-transform: uppercase;
            display: inline-block;
            padding: 0 25px 0 0;
            @media(max-width: 1023px) {
              display: block;
              padding: 0;
              line-height: 1.5;
            }
            &:after {
                display: inline-block;
                content: '';
                width: 1px;
                height: @footerTextSize - 0.1rem;
                position: relative;
                left: 15px;
                background-color: @footerFontColor;
                @media(max-width: 1023px) {
                  display: none;
                }
            }
            &:last-child {
                padding: 0;
                &:after {
                    content: normal;
                }
            }
        }
    }
    a {
        color: @footerFontColor;
        /*font-size: @footerTextSize;*/
        &:hover {
            color: darken(@footerFontColor, 40%);
        }
    }

    ul.payment-providers{
        li{
            background: url(/img/payment-providers.png) center center no-repeat;
            height: 20px;
            width: 50px;
            display: inline-block;
            &:after{
                content: normal;
            }

            &.visa{
                background-position: 0 center;
                width: 55px;
            }
            &.master-card{
                background-position: -63px center;
                width: 30px;
            }
            &.maestro{
                background-position: -100px center;
                width: 27px;
            }
            &.giropay{
                background-position: -134px center;
                width: 42px;
            }
            &.sofort-ueberweisung{
                background-position: -184px center;
                width: 56px;
            }
            &.paypal{
                background-position: -247px center;
                width: 71px;
            }

        }
    }
}
