.accordion {
    width: 100%;

    &.faqs {

        .collapsible {
            position: relative;
            border-top-left-radius: @border-radius;
            border-bottom-right-radius: @border-radius;
            border: 1px solid #fff;
            background-color: #fff;
            line-height: 1.2rem;
            margin-bottom: 20px;
            padding: 0 20px 0 20px;
            display: block;
            overflow: hidden;

            .question {
                //padding: 15px 0;
                padding: 15px 35px 15px 0;
                color: #000;
                font-size: 1.125rem;
                font-weight: @font-medium;
                text-transform: uppercase;
                cursor: pointer;
            }

            .answer {
                font-weight: 200;
                color: #000;
                line-height: 1.2rem;
                max-height: 0;
                padding-bottom: 0;
                transition: max-height 0.3s cubic-bezier(0, 1.05, 0, 1);
            }

            .icon {
                position: absolute;
                top: 9px;
                right: 15px;
                color: #000;
                font-size: 1.8em;
                transition: transform 0.3s ease;
            }

            &.open {

                .answer {
                    max-height: 1000px;
                    display: inherit;
                    padding-bottom: 20px;
                    transition: max-height 0.3s ease-in, padding-bottom 0.3s ease-in;
                }

                .icon {
                    transform: rotateZ(-180deg);
                }
            }
        }
    }
}